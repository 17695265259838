import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { SiteSummaryDataResponse } from "types/DetailedSummary";
import { createSiteSummary, SiteSummary } from "models/siteSummary";

export const useFetchSiteSummary = (siteId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [site, setSite] = React.useState<SiteSummary>();
  React.useEffect(() => {
    if (siteId && siteId !== "") {
      const loadSite = ({ data }: { data: SiteSummaryDataResponse }) => {
        setLoading(false);
        data && setSite(createSiteSummary(data.site_summary));
      };
      const getSite = async () => {
        setLoading(true);
        getResource<{ data: SiteSummaryDataResponse }>(
          `${window.APP_CONFIG.API_HOST}/site_summaries/${siteId}`,
          auth
        )
          .then(loadSite)
          .catch((error) => {
            enqueueSnackbar(
              `There was an error retrieving site data: ${error}`,
              {
                variant: "error",
              }
            );
          });
      };
      getSite();
    }
  }, [enqueueSnackbar, siteId, auth]);
  return {
    loading,
    site,
    setSite,
  };
};
