import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { AccessoryLineItemRow } from "types/accessoryLineItemRow";

export const useFetchAccessoryLineItems = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingAlis, setLoading] = React.useState(true);
  const [alis, setAlis] = React.useState<AccessoryLineItemRow[]>([]);
  React.useEffect(() => {
    const getAccessories = async () => {
      setLoading(true);
      getResource<{ data: AccessoryLineItemRow[] }>(
        `${window.APP_CONFIG.API_HOST}/accessory_line_items?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          setAlis(data);
          //setAccessories(prev => ({...prev, data}));
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving accessories. ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getAccessories();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loadingAlis,
    alis,
  };
};
