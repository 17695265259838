import { ProjectProps } from "types/ProjectProps";
import _ from "lodash";

export class Project {
  id = "";
  name = "";
  owner = "";
  createdAt = new Date();
  updatedAt = new Date();
  status = "";
  connectivity = "None";
  extendedWarranty = 0;
  fdd = false;
  mAndV = false;
  currency = "";
  locale = "";
  currencySymbol = "";
  projectManagement = "";
  motorDiscount = 0;
  pmDiscount = 0;
  accessoryDiscount = 0;
  archived = false;
  organizationId = "";
  editable = false;
  exchangeRate = 1;
  motorMaterialMarkup = 0;
  motorLaborMarkup = 0;
  accessoryMaterialMarkup = 0;
  accessoryLaborMarkup = 0;

  setProjectProps(project: ProjectProps) {
    this.id = project.id;
    this.name = project.name;
    this.owner = project.owner;
    this.createdAt = project.created_at;
    this.updatedAt = project.updated_at;
    this.status = project.status;
    this.connectivity = _.capitalize(project.connectivity);
    this.extendedWarranty = project.extended_warranty;
    this.fdd = project.fdd;
    this.mAndV = project.m_and_v;
    this.currency = project.currency;
    this.currencySymbol = project.currency_symbol;
    this.locale = project.locale;
    this.projectManagement = project.project_management;
    this.motorDiscount = project.motor_discount;
    this.motorMaterialMarkup = project.motor_material_markup;
    this.motorLaborMarkup = project.motor_labor_markup;
    this.motorDiscount = project.motor_discount;
    this.pmDiscount = project.pm_discount;
    this.accessoryDiscount = project.accessory_discount;
    this.accessoryMaterialMarkup = project.accessory_material_markup;
    this.accessoryLaborMarkup = project.accessory_labor_markup;
    this.archived = project.archived;
    this.organizationId = project.organization_id;
    this.editable = project.editable;
    this.exchangeRate = project.exchange_rate;
  }

  isProjectUpdated(mAndV: boolean, connectivity: string, currency: string) {
    return (
      mAndV !== this.mAndV ||
      connectivity !== this.connectivity ||
      currency !== this.currency
    );
  }

  formatMoney(value: number, maxFractionDigits = 0, minFractionDigits = 0) {
    if (!value) {
      const v = 0;
      return v.toLocaleString(this.locale, {
        style: "currency",
        currency: this.currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
    return value.toLocaleString(this.locale, {
      style: "currency",
      currency: this.currency,
      maximumFractionDigits: maxFractionDigits,
      minimumFractionDigits: minFractionDigits,
    });
  }

  getDemandChargeUnits() {
    return this.currencySymbol + "/kW";
  }

  getUtilityRateUnits() {
    return this.currencySymbol + "/kWh";
  }
}
