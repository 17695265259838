import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DialogTitle } from "./DialogTitle";
import { tableStyles5 } from "modules/common/components/table/tableStyles5";
import { Savings } from "modules/project-stepper/icons";
import { PriceAdjustments } from "models/priceAdjustments";
import { NumericEdit } from "modules/common/components/NumericEdit";

type PriceAdjustmentsModalProps = {
  defaultValue: 0;
  onSave: (p: PriceAdjustments) => void;
  priceAdjustments: PriceAdjustments;
};

type InputCellProps = {
  id: string;
  label: string;
  field: string;
  value: number;
  defaultValue: number;
  autoFocus?: boolean;
  callback: (
    prop: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

const isErrorValue = (key: string, value: any) => {
  return (
    isNaN(value) ||
    +value < 0 ||
    (key.toLowerCase().indexOf("discount") > -1 && value > 100)
  );
};

const InputCell = ({
  id,
  label,
  field,
  value,
  defaultValue = 0,
  autoFocus = false,
  callback,
}: InputCellProps) => (
  <NumericEdit
    id={id}
    value={value || defaultValue}
    callback={(event) => callback(field, event)}
    label={label}
    autoFocus={autoFocus}
    units="%"
    isError={isErrorValue(field, value)}
  />
);

export function PriceAdjustmentsModal({
  defaultValue,
  onSave,
  priceAdjustments,
}: PriceAdjustmentsModalProps) {
  const [open, setOpen] = React.useState(false);
  const [pricing, setPricing] =
    React.useState<PriceAdjustments>(priceAdjustments);
  const [isSaveDisabled, setSaveDisabled] = React.useState(true);
  const theme = useTheme();
  const classes = tableStyles5(theme);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPricing({ ...priceAdjustments }); // revert any unsaved values
    setSaveDisabled(true);
  };
  const handleSave = () => {
    onSave(pricing);
    setOpen(false);
    setSaveDisabled(true);
  };

  const hasErrorValue = (updatedPricing: any) => {
    for (const key of Object.keys(updatedPricing)) {
      const value = updatedPricing[key];
      if (value !== undefined && isErrorValue(key, value)) {
        return true;
      }
    }
    return false;
  };

  const handleTextFieldChange = (
    prop: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event?.target?.value;
    const updatedPricing = { ...pricing } as any;
    updatedPricing[prop] = +value || 0;
    if (updatedPricing[prop] !== (pricing as any)[prop]) {
      setPricing(updatedPricing);
      const hasErrors = hasErrorValue(updatedPricing);
      setSaveDisabled(hasErrors);
    }
  };

  const buttonStyle = {
    marginBottom: 12,
    marginRight: 24,
    marginTop: 12,
    width: 151,
  };

  return (
    <div>
      <Button sx={classes.button} variant="outlined" onClick={handleClickOpen}>
        Price Adjustments
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            backgroundColor: "#F1F1F2",
          },
        }}
      >
        <DialogTitle onClose={handleClose}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            justifyContent="center"
            textAlign="center"
            mb={2}
            pt={3}
            pr={6}
            sx={{ fontFamily: "Barlow" }}
            maxWidth="lg"
          >
            <Savings style={{ marginRight: 8, height: "42px" }} />
            Price Adjustments
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-evenly"
            alignItems="center"
            alignSelf="center"
            pl={4}
          >
            <Grid item xs={2}>
              <Typography color="textSecondary" variant="h6">
                Motors
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              justifyContent="flex-end"
              sx={{ textAlign: "right" }}
            >
              <Divider sx={{ mr: 4 }} />
            </Grid>

            <InputCell
              id="motor-discount"
              label="% Discount for Motor Pricing"
              field="motorDiscount"
              value={pricing.motorDiscount || 0}
              autoFocus={true}
              defaultValue={defaultValue}
              callback={handleTextFieldChange}
            />
            <InputCell
              id="motor-material-markup"
              label="% Markup on Motor Pricing"
              field="motorMaterialMarkup"
              value={pricing.motorMaterialMarkup || 0}
              defaultValue={defaultValue}
              callback={handleTextFieldChange}
            />
            <Grid item xs={6} />
            <InputCell
              id="motor-labor-markup"
              label="% Markup on Motor Labor"
              field="motorLaborMarkup"
              value={pricing.motorLaborMarkup || 0}
              defaultValue={defaultValue}
              callback={handleTextFieldChange}
            />

            <Grid item xs={3}>
              <Typography color="textSecondary" variant="h6">
                Accessories
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ textAlign: "right" }}>
              <Divider sx={{ mr: 4 }} />
            </Grid>
            <InputCell
              id="accessory-discount"
              label="% Discount for Accessory Pricing"
              field="accessoryDiscount"
              value={pricing.accessoryDiscount || 0}
              defaultValue={defaultValue}
              callback={handleTextFieldChange}
            />
            <InputCell
              id="accessory-material-markup"
              label="% Markup on Accessory Pricing"
              field="accessoryMaterialMarkup"
              value={pricing.accessoryMaterialMarkup || 0}
              defaultValue={defaultValue}
              callback={handleTextFieldChange}
            />
            <Grid item xs={6} />
            <InputCell
              id="accessory-labor-markup"
              label="% Markup on Accessory Labor"
              field="accessoryLaborMarkup"
              value={pricing.accessoryLaborMarkup || 0}
              defaultValue={defaultValue}
              callback={handleTextFieldChange}
            />
            <Grid item xs={6} textAlign="center">
              <Button
                style={buttonStyle}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems="center"
              alignSelf="center"
              justifyContent="stretch"
              textAlign="center"
            >
              <Button
                style={buttonStyle}
                variant="contained"
                onClick={handleSave}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
