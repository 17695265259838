import * as React from "react";
import {
  getResource,
  getTextFieldChangeValue,
  handleTextFieldChange,
  postRequest,
} from "utils/helpers";
import { AccessoryLineItemRow } from "types/accessoryLineItemRow";
import { Box, Card } from "@material-ui/core";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { tableStyles } from "modules/common/components/table/tableStyles";
import EditableTableCell from "modules/common/components/table/EditableTableCell";
import { RowUpdate } from "types/FieldUpdateTypes";
import Button from "@material-ui/core/Button";
import { SimpleTable } from "modules/common/components/table/SimpleTable";
import { SiteDataRow } from "types/SiteDataRow";
import { useAuth } from "modules/common/auth";
import { useSnackbar } from "notistack";
import { AdHocAccessoryPricingRow } from "./AdHocAccessoryPricingRow";
import { Project } from "models/project";
import {
  PriceAdjustments,
  ProjectPricingUpdate,
} from "models/priceAdjustments";
import { PriceAdjustmentsModal } from "modules/common/components/dialog/PriceAdjustmentsModal";
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SubscriptionLineItemRow } from "types/SubscriptionLineItemRow";

export type AccessoriesProps = {
  accessories: AccessoryLineItemRow[];
  project: Project;
  updateAccessoryLineItem: (item: RowUpdate) => void;
  updateAccessories: (accessory: AccessoryLineItemRow) => void;
  updateSubscriptionLineItems: (
    subscriptionLineItems: SubscriptionLineItemRow[]
  ) => void;
  updateProject: ProjectPricingUpdate;
  setExtendedWarrantyOption: (option: number) => void;
  accessoryDiscount: number;
  accessorySkus: string[];
  readOnly: boolean;
  subscriptionLineItems: SubscriptionLineItemRow[];
};

export interface Accessory {
  sku: string;
  site_id: string;
  quantity: string;
  site_name: string;
  list_price: number;
}

export function Accessories(props: AccessoriesProps) {
  const classes = tableStyles();
  const {
    accessories,
    project,
    updateAccessoryLineItem,
    updateAccessories,
    updateSubscriptionLineItems,
    updateProject,
    setExtendedWarrantyOption,
    accessorySkus,
    readOnly,
    subscriptionLineItems,
  } = props;
  const [rowUpdates, setRowUpdates] = React.useState<RowUpdate[]>([]);
  const [sites, setSites] = React.useState<SiteDataRow[]>([]);
  const [adhocAccessoryRows, setAccessoryRows] = React.useState<Accessory[]>(
    []
  );
  const [extendedWarrantyYears, setExtendedWarrantyYears] =
    React.useState<number>(project.extendedWarranty);

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lineItemId: string
  ) => {
    const changesInLineItem = rowUpdates.find(
      (c) => c.lineItemId === lineItemId
    );
    if (changesInLineItem) {
      updateAccessoryLineItem(changesInLineItem);
    }
  };

  const setPricing = (p: PriceAdjustments) => {
    updateProject(p, () => {});
  };

  const onSaveAccessoryPricing = (index: number) => {
    const acc = adhocAccessoryRows[index];
    const siteId = sites.find((s) => s.name === acc.site_name)?.id;
    if (!siteId) {
      return;
    }
    acc.site_id = siteId;
    acc.quantity = acc.quantity || "0";
    const body = JSON.stringify(acc);
    postRequest<{ data: { accessory_line_item: AccessoryLineItemRow } }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/accessory_line_items`,
      auth,
      body
    )
      .then(({ data }) => {
        updateAccessories(data.accessory_line_item);
        setAccessoryRows((acc: Accessory[]) =>
          acc.filter((item, i) => i !== index)
        );
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save accessory: ${error}`, {
          variant: "error",
        });
      });
  };

  const onChangeAccessoryPricing = (acc: Accessory, index: number) => {
    if (acc.sku) {
      const list_price = accessories.find((a) => a.sku === acc.sku)?.list_price;
      acc.list_price = list_price ? list_price : 0;
    }
    setAccessoryRows([
      ...adhocAccessoryRows.slice(0, index),
      acc,
      ...adhocAccessoryRows.slice(index + 1),
    ]);
  };

  React.useEffect(() => {
    getResource<{ data: SiteDataRow[] }>(
      `${window.APP_CONFIG.API_HOST}/sites?project_id=${project.id}`,
      auth
    )
      .then(({ data }) => {
        setSites(data);
      })
      .catch((error) => {
        enqueueSnackbar(`There was an error retrieving sites. ${error}`, {
          variant: "error",
        });
      });
  }, [auth, enqueueSnackbar, project.id]);

  // Discount applied first, markup second
  const computeTotalMaterialPrice = (ali: AccessoryLineItemRow) => {
    const localOverridePrice = getTextFieldChangeValue(
      "override_price",
      rowUpdates
    );
    const materialPrice =
      localOverridePrice !== undefined
        ? +localOverridePrice
        : ali.override_price ?? ali.list_price;
    const totalMaterialPrice = materialPrice * ali.quantity;
    const discount = project.accessoryDiscount || 0;
    const discountedMaterialPrice =
      totalMaterialPrice - (totalMaterialPrice * discount) / 100;
    const materialMarkup = project.accessoryMaterialMarkup || 0;
    const markedUpMaterialPrice =
      discountedMaterialPrice +
      (discountedMaterialPrice * materialMarkup) / 100;
    return markedUpMaterialPrice;
  };

  const yearsLabel = (years: number) =>
    `${years} year${years === 1 ? "" : "s"}`;

  const onChangeExtendedWarranty = (event: SelectChangeEvent<number>) => {
    const warrantyYears = event.target.value;
    const body = { years: warrantyYears };
    postRequest<{
      data: SubscriptionLineItemRow[];
    }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/extended_warranties?project_id=${project.id}`,
      auth,
      JSON.stringify(body)
    )
      .then(({ data }) => {
        setExtendedWarrantyYears(+warrantyYears);
        setExtendedWarrantyOption(+warrantyYears);
        updateSubscriptionLineItems(data);
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to change extended warranty: ${error}`, {
          variant: "error",
        });
      });
  };

  return (
    <Box mt={2}>
      <Box mb={1} display="flex">
        <Box mr={2}>
          <Typography variant="h6">Accessory Pricing</Typography>
        </Box>
        <Box
          justifyContent="center"
          display="flex"
          flexDirection="column"
          onClick={() =>
            setAccessoryRows([...adhocAccessoryRows, {} as Accessory])
          }
          style={{ flex: 1 }}
        >
          <span className="fa-stack fa-1x">
            <i
              className="fas fa-circle fa-stack-2x"
              style={{ color: "#006BA6" }}
            />
            <i
              className="fa fa-plus fa-stack-1x fa-inverse"
              style={{ cursor: "pointer" }}
              aria-hidden="true"
            />
          </span>
        </Box>
        <PriceAdjustmentsModal
          onSave={setPricing}
          defaultValue={0}
          priceAdjustments={{
            motorDiscount: project.motorDiscount,
            motorMaterialMarkup: project.motorMaterialMarkup,
            motorLaborMarkup: project.motorLaborMarkup,
            accessoryDiscount: project.accessoryDiscount,
            accessoryMaterialMarkup: project.accessoryMaterialMarkup,
            accessoryLaborMarkup: project.accessoryLaborMarkup,
          }}
        />
      </Box>

      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "Accessory",
              "Quantity",
              "List Price",
              "Override Price",
              "Total Price",
              "Tax Percentage",
              { value: "", align: "center" },
            ]}
            emptyMessage="No Accessory Line Items"
          >
            {accessories.map((ali: AccessoryLineItemRow) => (
              <TableRow key={ali.id}>
                <TableCell width="10%">{ali.site_name}</TableCell>
                <TableCell width="8%">{ali.site_identifier}</TableCell>
                <TableCell width="10%">{ali.sku}</TableCell>
                <EditableTableCell
                  defaultValue={ali.quantity}
                  fieldName={"quantity"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      ali.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <TableCell width="10%">
                  {project.formatMoney(ali.list_price)}
                </TableCell>
                <EditableTableCell
                  defaultValue={ali.override_price ?? ""}
                  fieldName={"override_price"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      ali.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <TableCell>
                  {project.formatMoney(computeTotalMaterialPrice(ali), 2, 2)}
                </TableCell>
                <EditableTableCell
                  defaultValue={ali.tax_rate_percent}
                  fieldName={"tax_rate_percent"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      ali.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSave(event, ali.id)}
                    variant="outlined"
                    className={readOnly ? "" : classes.button}
                    disabled={readOnly}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {adhocAccessoryRows.map((acc: Accessory, index: number) => (
              <AdHocAccessoryPricingRow
                key={index}
                onSave={() => onSaveAccessoryPricing(index)}
                onChange={(acc: Accessory) =>
                  onChangeAccessoryPricing(acc, index)
                }
                sites={sites}
                skus={accessorySkus}
                accessory={acc}
                project={project}
              />
            ))}
          </SimpleTable>
        </Card>
      </Box>

      <Box mb={1} mt={6}>
        <Typography variant="h6">Accessory Labor</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "Accessory",
              "Quantity",
              "Labor Hours Per Accessory",
              "Labor Rate",
              { value: "", align: "center" },
            ]}
            emptyMessage="No Accessory Line Items"
          >
            {accessories.map((ali: AccessoryLineItemRow) => (
              <TableRow key={ali.id}>
                <TableCell width="10%">{ali.site_name}</TableCell>
                <TableCell width="8%">{ali.site_identifier}</TableCell>
                <TableCell width="10%">{ali.sku}</TableCell>
                <TableCell width="8%">{ali.quantity}</TableCell>
                <EditableTableCell
                  defaultValue={ali.labor_hours_per_unit || 0}
                  fieldName={"labor_hours_per_unit"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      ali.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <EditableTableCell
                  defaultValue={ali.labor_rate_hr || 0}
                  fieldName={"labor_rate_hr"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      ali.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSave(event, ali.id)}
                    variant="outlined"
                    className={readOnly ? "" : classes.button}
                    disabled={readOnly}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>

      <Grid
        container
        justifyContent="space-between"
        justifyItems="center"
        alignItems={"center"}
        mt={6}
      >
        <Grid item mr={2} mb={1} xs="auto">
          <Typography variant="h6">
            Subscriptions & Extended Warranties
          </Typography>
        </Grid>
        <Grid item xs="auto" mb={1}>
          <Typography variant="button" style={{ marginRight: "8px" }}>
            Extended Warranty
          </Typography>
          <Select
            value={extendedWarrantyYears}
            onChange={onChangeExtendedWarranty}
            variant="outlined"
            className="input"
            disabled={readOnly}
            id="extended-warranty"
            size="small"
          >
            {[0, 1, 2, 3, 4].map((option) => (
              <MenuItem
                key={option}
                value={option}
                selected={option === extendedWarrantyYears}
              >
                {option === 0 ? "None" : yearsLabel(option)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <SimpleTable
              headers={[
                "Site Name",
                "Site Id",
                "SKU",
                "Description",
                "Quantity",
                "Annual List Price",
                "Total Price",
                "Tax %",
              ]}
              emptyMessage="No Subscriptions"
            >
              {subscriptionLineItems.map((sli: SubscriptionLineItemRow) => (
                <TableRow key={sli.id}>
                  <TableCell width="12%">{sli.site_name}</TableCell>
                  <TableCell width="10%">{sli.site_identifier}</TableCell>
                  <TableCell width="18%">{`${sli.sku} (${yearsLabel(
                    extendedWarrantyYears
                  )})`}</TableCell>
                  <TableCell width="20%">{sli.description}</TableCell>
                  <TableCell width="8%">{sli.quantity}</TableCell>
                  <TableCell width="10%">
                    {project.formatMoney(sli.list_price, 2, 2)}
                  </TableCell>
                  <TableCell width="10%">
                    {project.formatMoney(
                      sli.list_price * sli.years * sli.quantity,
                      2,
                      2
                    )}
                  </TableCell>
                  <TableCell width="10%">{sli.tax_rate_percent}</TableCell>
                </TableRow>
              ))}
            </SimpleTable>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
