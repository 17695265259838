import React from "react";
import { getResource } from "utils/helpers";
import { Membership } from "types/Membership";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";

type MembershipInfo = {
  current_user_memberships: Membership[];
  is_super_admin: boolean;
  memberships: Membership[];
};
export const useFetchMemberships = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingMemberships, setLoading] = React.useState(true);
  const [membershipsData, setData] = React.useState<MembershipInfo>();

  React.useEffect(() => {
    const getMemberships = () => {
      setLoading(true);
      getResource<{ data: MembershipInfo }>(
        `${window.APP_CONFIG.API_HOST}/memberships`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          const m = data.memberships.map((membership: Membership, index) => {
            return {
              ...membership,
              id: index,
              role: membership.is_super_admin
                ? "System Administrator"
                : membership.is_org_admin
                ? "Administrator"
                : "User",
            };
          });
          setData({
            current_user_memberships: data.current_user_memberships,
            is_super_admin: data.is_super_admin,
            memberships: m,
          });
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving memberships: ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getMemberships();
  }, [enqueueSnackbar, auth]);
  return {
    loadingMemberships,
    membershipsData,
  };
};
