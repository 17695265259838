import { ExchangeRate } from "types/ExchangeRate";

export class Currency {
  currency = "USD";
  exchangeRate = 1;

  setExchangeRate = (exchangeRate: ExchangeRate) => {
    this.currency = exchangeRate.currency;
    this.exchangeRate = exchangeRate.rate;
  };
}
