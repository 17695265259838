import { createStyles, Theme } from "@material-ui/core/styles";

export const UserFormStyles = (theme: Theme) => {
  return createStyles({
    root: {
      "& .input": {
        backgroundColor: "white",
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "solid 1px #BCBCBC !important",
      },
      "& .MuiFormLabel-root": {
        color: "black",
      },
    },
  });
};
