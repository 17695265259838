import * as React from "react";
import TableCell from "@material-ui/core/TableCell";
import { NameValuePair } from "types/FieldUpdateTypes";
import { NumberFormatCustom } from "./NumberFormatCustom";
import { StyledTextField } from "../StyledTextField";

type EditableCellProps = {
  fieldName: string;
  defaultValue: string | number;
  onCellValueChange: ({ fieldName, fieldValue }: NameValuePair) => void;
  isCurrency?: boolean;
  currencySymbol?: string;
  locale?: string;
};

function EditableTableCell({
  fieldName,
  defaultValue,
  onCellValueChange,
  currencySymbol = "$",
  locale = "en-US",
  isCurrency = true,
}: EditableCellProps) {
  const [inputValue, setInputValue] = React.useState(null);
  const handleTextFieldChange = (e: any) => {
    // prevent blank currency values leaking to the client
    const effectiveValue = isCurrency
      ? e.target.value ?? defaultValue
      : e.target.value;

    setInputValue(effectiveValue);
    onCellValueChange({
      fieldValue: effectiveValue,
      fieldName: fieldName,
    });
  };
  React.useEffect(() => {
    setInputValue(null);
  }, [defaultValue]);

  return (
    <TableCell>
      {isCurrency ? (
        <StyledTextField
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: { currencySymbol: currencySymbol, locale: locale },
          }}
          onChange={handleTextFieldChange}
          defaultValue={defaultValue}
        />
      ) : (
        <StyledTextField
          onChange={handleTextFieldChange}
          value={inputValue !== null ? inputValue : defaultValue}
        />
      )}
    </TableCell>
  );
}

export default EditableTableCell;
