import { AuthService } from "modules/common/auth";
import { NameValuePair, RowUpdate } from "types/FieldUpdateTypes";
import { capitalize, startCase, snakeCase } from "lodash";

export async function getResource<T>(
  url: string,
  auth: AuthService
): Promise<T> {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth.getToken()}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

export async function postRequest<T>(
  method: string,
  url: string,
  auth: AuthService,
  body: string | FormData,
  contentType = "application/json",
  detailedError = false
): Promise<T> {
  let requestHeaders = {};
  requestHeaders =
    contentType !== ""
      ? {
          Authorization: `Bearer ${await auth.getToken()}`,
          "Content-Type": contentType,
        }
      : {
          Authorization: `Bearer ${await auth.getToken()}`,
        };

  const options = {
    method: method,
    headers: requestHeaders,
    body: body,
  };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      if (detailedError) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((error) => {
            throw error;
          });
        }
      }
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

export async function deleteRequest<T>(
  method: string,
  url: string,
  auth: AuthService,
  contentType = "application/json"
): Promise<T> {
  const requestHeaders = {
    Authorization: `Bearer ${await auth.getToken()}`,
    "Content-Type": contentType,
  };

  const options = {
    method: method,
    headers: requestHeaders,
  };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

export async function uploadFile<T>(
  url: string,
  auth: AuthService,
  body: FormData
): Promise<T> {
  const requestHeaders = {
    Authorization: `Bearer ${await auth.getToken()}`,
  };

  const options = {
    method: "POST",
    headers: requestHeaders,
    body: body,
  };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw error;
      });
    }
    return response.json() as Promise<T>;
  });
}

export function formatNumber(value: number, maxFractionDigits = 3) {
  if (value === null || value === undefined) {
    return "";
  }
  return value.toLocaleString("en-US", {
    maximumFractionDigits: maxFractionDigits,
  });
}

export function handleTextFieldChange(
  change: NameValuePair,
  id: string,
  rowUpdates: RowUpdate[],
  setRowUpdates: (rows: RowUpdate[]) => void
) {
  let existingUpdates = rowUpdates;
  const fieldUpdate: NameValuePair = {
    fieldName: change.fieldName,
    fieldValue: change.fieldValue,
  };
  let existingChangesForItem = existingUpdates.find(
    (item) => item.lineItemId === id
  );
  if (existingChangesForItem) {
    let changes = existingChangesForItem.changes;
    // Look for update(s) to the field. For example, look for updates to "tax_rate_percent" in the changes for 'lineItemId'
    let existingChange = changes.find(
      (e: NameValuePair) => e.fieldName === change.fieldName
    );
    // If the user had already made updates to this field, then replace it with the new change.
    if (existingChange) {
      changes = changes.map((e: NameValuePair) => {
        return e.fieldName === change.fieldName
          ? {
              fieldName: e.fieldName,
              fieldValue: change.fieldValue,
            }
          : { fieldName: e.fieldName, fieldValue: e.fieldValue };
      });
      // Update state variable with the new changes
      let updatedChanges = existingUpdates.map((c: RowUpdate) => {
        return c.lineItemId === id
          ? {
              lineItemId: c.lineItemId,
              changes: changes,
            }
          : { lineItemId: c.lineItemId, changes: c.changes };
      });
      setRowUpdates(updatedChanges);
    } else {
      changes = changes.concat(fieldUpdate);
      let updatedChanges = existingUpdates.map((c: RowUpdate) => {
        return c.lineItemId === id
          ? {
              lineItemId: c.lineItemId,
              changes: changes,
            }
          : { lineItemId: c.lineItemId, changes: c.changes };
      });
      setRowUpdates(updatedChanges);
    }
  } else {
    existingUpdates.push({ lineItemId: id, changes: [fieldUpdate] });
    setRowUpdates(existingUpdates);
  }
}

export function getTextFieldChangeValue(id: string, rowUpdates: RowUpdate[]) {
  const existingChangesForItem = rowUpdates.find(
    (item) => item.lineItemId === id
  );
  if (existingChangesForItem) {
    // Look for update(s) to the field. For example, look for updates to "override_price" in the changes for 'lineItemId'
    const existingChange = existingChangesForItem.changes.find(
      (e: NameValuePair) => e.fieldName === id
    );
    // If the user had already made updates to this field, return the updated value.
    if (existingChange) {
      return existingChange.fieldValue;
    }
  }
  return undefined;
}

export const isInValidation = (skuStatus: string) => {
  return skuStatus === "IN_VALIDATION";
};

export const formatSkuStatus = (skuStatus: string) => {
  if (skuStatus === "GA") {
    return skuStatus;
  } else {
    return startCase(capitalize(skuStatus));
  }
};

export const formatStatus = (status: string) => {
  return startCase(capitalize(status));
};

export const isTrue = (stringValue: string) => {
  return stringValue === "Yes";
};

export const boolToString = (value: boolean) => {
  return value ? "Yes" : "No";
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Map a server type containing snake_case props to a client type containing camelCase props
// See https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case
export type SnakeToCamelCase<S extends string> =
  S extends `${infer T}_${infer U}`
    ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
    : S;

// Simplified camel case conversion that matches the above type mapping better than lodash.camelCase
export function snakeCaseToCamelCase(s: string): string {
  return s
    .split("_")
    .map((value, index) =>
      index === 0 ? value.toLocaleLowerCase() : capitalize(value)
    )
    .join("");
}

// Recursively build an alternate-cased instance of a given object.
// Adapted from https://stackoverflow.com/questions/44437953/angular-typescript-converting-from-snake-case-to-camel-case-in-interfaces
export function transformCasing(o: any, f: (o: any) => any): any {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== "function") {
    const n: { [key: string]: any } = {};
    Object.keys(o).forEach((k) => {
      n[f(k)] = transformCasing(o[k], f);
    });
    return n;
  } else if (Array.isArray(o)) {
    return o.map((i) => {
      return transformCasing(i, f);
    });
  }
  return o;
}

// Recursively build a camelCased instance of a given snake_cased object.
// Adapted from https://stackoverflow.com/questions/44437953/angular-typescript-converting-from-snake-case-to-camel-case-in-interfaces
export function keysToCamel(o: any): any {
  return transformCasing(o, snakeCaseToCamelCase);
}

// Recursively build a snake_cased instance of a given camelCased object.
// Adapted from https://stackoverflow.com/questions/44437953/angular-typescript-converting-from-snake-case-to-camel-case-in-interfaces
export function keysToSnake(o: any): any {
  return transformCasing(o, snakeCase);
}
