import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { DetailedSummary } from "types/DetailedSummary";
import { ExecutiveSummary } from "types/ExecutiveSummary";
import { Project } from "models/project";
import { formatNumber } from "utils/helpers";
import {
  styles,
  TableHeaderProps,
  LogoHeader,
  KpiSection,
  TableRowLine,
  MOTOR_QTY_COLUMN_WIDTH,
  detailValueSize,
} from "./PDFReportUtil";

interface ReportSummary extends DetailedSummary {
  streetAddr?: string;
  city?: string;
  state?: string;
  country?: string;
}

export type SummaryPDFProps = {
  project: Project;
  summary: ExecutiveSummary;
  detailedSummary: ReportSummary[];
};

const TableHeader = ({ siteIdStyle }: TableHeaderProps) => (
  <View style={styles.detailedSummaryHeaderRow} debug={false}>
    <Text style={siteIdStyle} debug={false}>
      SITE ID
    </Text>
    <Text style={styles.motorQuantityHeaderCell}>MOTOR QTY</Text>
    <Text style={styles.detailedSummaryHeaderCell}>ENERGY SAVINGS (kWh)</Text>
    <Text style={styles.detailedSummaryHeaderCell}>UTILITY RATE</Text>
    <Text style={styles.detailedSummaryHeaderCell}>
      PEAK DEMAND REDUCTION (kW)
    </Text>
    <Text style={styles.detailedSummaryHeaderCell}>PEAK DEMAND SAVINGS</Text>
    <Text style={styles.detailedSummaryHeaderCell}>ENERGY COST SAVINGS</Text>
    <Text style={styles.detailedSummaryHeaderCell}>REBATES</Text>
    <Text style={styles.detailedSummaryHeaderCell}>PRICE AFTER REBATES</Text>
    <Text style={styles.detailedSummaryHeaderCell}>SIMPLE PAYBACK</Text>
  </View>
);

// Create Document Component
export function SummaryPDF({
  project,
  summary,
  detailedSummary,
}: SummaryPDFProps) {
  const sorted = detailedSummary
    .filter((site) => site.motor_quantity)
    .sort((a, b) => a.simple_payback - b.simple_payback);

  return (
    <Document
      title={project.name}
      author={project.owner}
      subject="ROI Calculator Motor Retrofit Summary"
      creator={project.organizationId}
    >
      <Page size="LETTER" style={styles.page}>
        <LogoHeader project={project} />

        <Text
          style={styles.title}
        >{`${project.name} Motor Retrofit Summary`}</Text>

        <KpiSection project={project} summary={summary} />

        <View style={[styles.table, styles.execSummarySection]}>
          <View style={[styles.tr, styles.kpiRow]}>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Motor Quantity</Text>
              <Text style={styles.kpiValue}>
                {formatNumber(summary.motor_quantity)}
              </Text>
            </View>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Baseline Energy Usage (kWh)</Text>
              <Text style={styles.kpiValue}>
                {formatNumber(summary.base_energy_usage)}
              </Text>
            </View>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Annual Energy Cost Savings</Text>
              <Text style={styles.kpiValue}>
                {project.formatMoney(summary.energy_cost_savings)}
              </Text>
            </View>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Peak Demand Savings</Text>
              <Text style={styles.kpiValue}>
                {project.formatMoney(summary.demand_charge_reduction)}
              </Text>
            </View>
          </View>
          <View style={[styles.tr, styles.kpiRow]}>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Total Rebates</Text>
              <Text style={styles.kpiValue}>
                {project.formatMoney(summary.total_rebates)}
              </Text>
            </View>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Turntide Energy Usage (kWh)</Text>
              <Text style={styles.kpiValue}>
                {formatNumber(summary.turntide_energy_usage)}
              </Text>
            </View>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Annual Energy Savings (kWh)</Text>
              <Text style={styles.kpiValue}>
                {formatNumber(
                  summary.base_energy_usage - summary.turntide_energy_usage
                )}
              </Text>
            </View>
            <View style={[styles.td, styles.kpiCell]}>
              <Text style={styles.kpiLabel}>Peak Demand Reduction (kW)</Text>
              <Text style={styles.kpiValue}>
                {formatNumber(summary.peak_kw_reduction, 2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.detailedSummarySection}>
          <Text style={styles.subtitle}>DETAILED SUMMARY</Text>
          <View style={[styles.table, styles.detailedSummaryTable]}>
            <TableHeader siteIdStyle={styles.siteIdHeaderCell} />
            <View
              style={[styles.table, styles.detailedSummaryTable]}
              debug={false}
              fixed
              render={({ pageNumber }) =>
                pageNumber > 1 && (
                  <TableHeader siteIdStyle={styles.siteIdHeaderCellFixed} />
                )
              }
            />
            {sorted.map((row) => (
              <View
                key={row.identifier}
                wrap={false}
                style={[
                  styles.tr,
                  styles.detailedSummaryRow,
                  { fontSize: detailValueSize },
                  { flexDirection: "column" },
                ]}
              >
                <TableRowLine />
                <View style={[{ flexDirection: "row" }]}>
                  <View
                    style={[styles.td, styles.trh, { flexDirection: "column" }]}
                  >
                    <Text>{row.identifier}</Text>
                    <Text>{row.streetAddr || ""}</Text>
                    <Text>
                      {row.city || ""} {row.state || ""} {row.country || ""}
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.td,
                      styles.detailedSummaryCell,
                      { flexGrow: MOTOR_QTY_COLUMN_WIDTH },
                    ]}
                  >
                    {formatNumber(row.motor_quantity)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {formatNumber(
                      row.base_energy_usage - row.turntide_energy_usage
                    )}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {project.formatMoney(row.utility_rate, 2, 2)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {formatNumber(row.peak_kw_reduction)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {project.formatMoney(row.demand_charge_reduction)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {project.formatMoney(row.energy_cost_savings)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {project.formatMoney(row.total_rebates)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {project.formatMoney(row.total_price - row.total_rebates)}
                  </Text>
                  <Text style={[styles.td, styles.detailedSummaryCell]}>
                    {formatNumber(row.simple_payback)} years
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}
