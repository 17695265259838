import * as React from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";
import { Projects } from "modules/project-stepper/components/Projects";
import { ProjectStepper } from "modules/project-stepper/components/ProjectStepper";
import { Box, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Header } from "modules/common/components/site-header/Header";
import { Members } from "modules/users/components/Members";

const useRootStyles = makeStyles(() =>
  createStyles({
    root: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& a": {
        textDecoration: "none",
      },
    },
  })
);

const Root = () => {
  const styles = useRootStyles();
  // window.APP_CONFIG.BUILD_ID stems from tycho.build_id value in Pulumi.<environment>.yaml, and may be over-written
  // with CI ID info by a script in ci/write_pulumi_ci_build_id.sh
  window.APP_CONFIG?.BUILD_ID && console.log(window.APP_CONFIG.BUILD_ID);
  return (
    <Grid container className={styles.root} justifyContent="center">
      <Box display="flex" flexDirection="row-reverse">
        <Header />
      </Box>
      <Box>
        <Switch>
          <Route exact path="/projects/new" component={ProjectStepper} />
          <Route
            exact
            path="/projects/:project_id"
            component={ProjectStepper}
          />
          <Route exact path="/users" component={Members} />
          <Route exact path="/projects" component={Projects} />
          <Route path="/" component={Projects} />
          <Redirect to={"/projects"} />
        </Switch>
      </Box>
    </Grid>
  );
};

export default Root;
