import React from "react";
import { getResource } from "utils/helpers";
import { Organization } from "types/Organization";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";

export const useFetchUserOrgs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<Organization[]>();
  React.useEffect(() => {
    let unmounted = false;
    const getUserOrgs = () => {
      setLoading(true);
      getResource<{ data: Organization[] }>(
        `${window.APP_CONFIG.API_HOST}/organizations`,
        auth
      )
        .then(({ data }) => {
          if (!unmounted) {
            setLoading(false);
            setData(data);
          }
        })
        .catch((error) => {
          if (!unmounted) {
            enqueueSnackbar(
              `There was an error retrieving organizations: ${error}`,
              {
                variant: "error",
              }
            );
          }
          setLoading(false);
        });
    };
    getUserOrgs();
    return () => {
      unmounted = true;
    };
  }, [enqueueSnackbar, auth]);
  return {
    loading,
    data,
  };
};

export const TURNTIDE_ORGANIZATION_ID = "15813564-a390-0e10-992e-cd64f1e61178";
