import * as React from "react";
import {
  formatSkuStatus,
  getTextFieldChangeValue,
  handleTextFieldChange,
  isInValidation,
} from "utils/helpers";
import { MotorLineItemRow } from "types/MotorLineItemRow";
import { Box, Card, TableCell, Typography } from "@material-ui/core";
import { tableStyles } from "modules/common/components/table/tableStyles";
import EditableTableCell from "modules/common/components/table/EditableTableCell";
import Button from "@material-ui/core/Button";
import { RowUpdate } from "types/FieldUpdateTypes";
import {
  SimpleTable,
  StyledTableRow,
} from "modules/common/components/table/SimpleTable";
import { Project } from "models/project";
import {
  PriceAdjustments,
  ProjectPricingUpdate,
} from "models/priceAdjustments";
import { PriceAdjustmentsModal } from "modules/common/components/dialog/PriceAdjustmentsModal";

type MotorTableProps = {
  motorLineItems: MotorLineItemRow[];
  updateMotorLineItem: (item: RowUpdate) => void;
  updateProject: ProjectPricingUpdate;
  readOnly: boolean;
  project: Project;
};

export function MotorPricing({
  motorLineItems,
  updateMotorLineItem,
  updateProject,
  readOnly,
  project,
}: MotorTableProps) {
  const classes = tableStyles();
  const [rowUpdates, setRowUpdates] = React.useState<RowUpdate[]>([]);

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lineItemId: string
  ) => {
    const changesInLineItem = rowUpdates.find(
      (c) => c.lineItemId === lineItemId
    );
    if (changesInLineItem) {
      updateMotorLineItem(changesInLineItem);
    }
  };

  const setPricing = (p: PriceAdjustments) => {
    updateProject(p, () => {});
  };

  // Discount applied first, markup second
  const computeTotalMaterialPrice = (mli: MotorLineItemRow) => {
    const localOverridePrice = getTextFieldChangeValue(
      "override_price",
      rowUpdates
    );
    const materialPrice =
      localOverridePrice !== undefined
        ? +localOverridePrice
        : mli.override_price ?? mli.list_price;
    const totalMaterialPrice = materialPrice * mli.quantity;
    const discount = project.motorDiscount || 0;
    const discountedMaterialPrice =
      totalMaterialPrice - (totalMaterialPrice * discount) / 100;
    const materialMarkup = project.motorMaterialMarkup || 0;
    const markedUpMaterialPrice =
      discountedMaterialPrice +
      (discountedMaterialPrice * materialMarkup) / 100;
    return markedUpMaterialPrice;
  };

  return (
    <Box mt={6}>
      <Box mb={1} display="flex">
        <Typography variant="h6" style={{ flex: 1 }}>
          Motor Pricing
        </Typography>
        <PriceAdjustmentsModal
          onSave={setPricing}
          defaultValue={0}
          priceAdjustments={{
            motorDiscount: project.motorDiscount,
            motorMaterialMarkup: project.motorMaterialMarkup,
            motorLaborMarkup: project.motorLaborMarkup,
            accessoryDiscount: project.accessoryDiscount,
            accessoryMaterialMarkup: project.accessoryMaterialMarkup,
            accessoryLaborMarkup: project.accessoryLaborMarkup,
          }}
        />
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "SKU",
              "SKU Status",
              "HP",
              "Quantity",
              "List Price",
              "Override Price",
              "Total Price",
              "Tax Percentage",
              { value: "", align: "center" },
            ]}
            emptyMessage="No motor line items."
          >
            {motorLineItems.map((mli: MotorLineItemRow) => (
              <StyledTableRow
                isSkuInValidation={isInValidation(mli.availability)}
                key={mli.id}
              >
                <TableCell>{mli.site_name}</TableCell>
                <TableCell>{mli.site_identifier}</TableCell>
                <TableCell>{mli.sku}</TableCell>
                <TableCell>{formatSkuStatus(mli.availability)}</TableCell>
                <TableCell>{mli.hp && `${mli.hp}hp`}</TableCell>
                <TableCell>{mli.quantity}</TableCell>
                <TableCell>
                  {project.formatMoney(mli.list_price, 2, 2)}
                </TableCell>
                <EditableTableCell
                  defaultValue={mli.override_price ?? ""}
                  fieldName={"override_price"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      mli.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <TableCell>
                  {project.formatMoney(computeTotalMaterialPrice(mli), 2, 2)}
                </TableCell>
                <EditableTableCell
                  defaultValue={mli.tax_rate_percent}
                  fieldName={"tax_rate_percent"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      mli.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSave(event, mli.id)}
                    variant="outlined"
                    className={readOnly ? "" : classes.button}
                    disabled={readOnly}
                  >
                    Save
                  </Button>
                </TableCell>
              </StyledTableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>

      <Box mb={1} mt={6}>
        <Typography variant="h6">Motor Labor</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "SKU",
              "SKU Status",
              "HP",
              "Quantity",
              "Labor Hours Per Motor",
              "Labor Rate",
              "Misc. Expense",
              { value: "", align: "center" },
            ]}
            emptyMessage="No Projects."
          >
            {motorLineItems.map((mli: MotorLineItemRow) => (
              <StyledTableRow
                key={mli.id}
                isSkuInValidation={isInValidation(mli.availability)}
              >
                <TableCell component="th" scope="row">
                  {mli.site_name}
                </TableCell>
                <TableCell>{mli.site_identifier}</TableCell>
                <TableCell>{mli.sku}</TableCell>
                <TableCell>{formatSkuStatus(mli.availability)}</TableCell>
                <TableCell>{mli.hp}</TableCell>
                <TableCell>{mli.quantity}</TableCell>
                <EditableTableCell
                  defaultValue={mli.labor_hours_per_unit || 0}
                  fieldName={"labor_hours_per_unit"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      mli.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <EditableTableCell
                  defaultValue={mli.labor_rate_hr}
                  fieldName={"labor_rate_hr"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      mli.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={mli.misc_expense}
                  fieldName={"misc_expense"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      mli.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSave(event, mli.id)}
                    variant="outlined"
                    className={readOnly ? "" : classes.button}
                    disabled={readOnly}
                  >
                    Save
                  </Button>
                </TableCell>
              </StyledTableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
    </Box>
  );
}
