import React from "react";
import { getResource } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import { SearchResultAddress } from "types/SearchResultAddress";

/** Effect for consuming the single-line address search API `/api/addresses` */
export const useSearchAddress = (
  address: string | undefined,
  currentOption: SearchResultAddress
) => {
  const auth = useAuth();
  const [loadingAddresses, setLoading] = React.useState(true);
  const [addresses, setAddresses] = React.useState<SearchResultAddress[]>([
    { freeformAddress: address, isApiResult: false },
    currentOption,
  ]);
  React.useEffect(() => {
    if (address && address !== "") {
      const searchAddresses = async () => {
        setLoading(true);
        getResource<SearchResultAddress[]>(
          `${window.APP_CONFIG.API_HOST}/addresses?query="${address}"`,
          auth
        )
          .then((response) => {
            if (response.length) {
              setAddresses([
                currentOption,
                ...response.map((r) => ({
                  isApiResult: true,
                  ...r,
                })),
              ]);
            } else {
              setAddresses([currentOption]);
            }
            setLoading(false);
          })
          .catch((error) => {
            // Since the user can still manually enter addresses, just silently log the error;
            console.warn(
              `There was an error retrieving addresses for ${address}: ${error}`
            );
            setLoading(false);
          });
      };
      searchAddresses();
    }
  }, [currentOption, address, auth]);
  return {
    loadingAddresses,
    addresses,
    setAddresses,
  };
};
