import React from "react";
import { getResource } from "utils/helpers";
import { ExecutiveSummary } from "types/ExecutiveSummary";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";

export const useFetchExecutiveSummary = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [summary, setSummary] = React.useState({} as ExecutiveSummary);
  React.useEffect(() => {
    const getSummary = () => {
      setLoading(true);
      getResource<{ data: ExecutiveSummary }>(
        `${window.APP_CONFIG.API_HOST}/summary?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          setSummary(data);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving project summary ${projectId}: ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getSummary();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    summary,
    loading,
  };
};
