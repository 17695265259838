import React from "react";
import { getResource } from "utils/helpers";
import { User } from "types/User";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";

export const useFetchUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<User[]>();
  React.useEffect(() => {
    const getUsers = () => {
      setLoading(true);
      getResource<{ data: User[] }>(`${window.APP_CONFIG.API_HOST}/users`, auth)
        .then(({ data }) => {
          setLoading(false);
          setData(data);
        })
        .catch((error) => {
          enqueueSnackbar(`There was an error retrieving users: ${error}`, {
            variant: "error",
          });
          setLoading(false);
        });
    };
    getUsers();
  }, [enqueueSnackbar, auth]);
  return {
    loading,
    data,
  };
};
