import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Stepper, Step, StepButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ProjectOptions } from "modules/project-stepper/components/ProjectOptions";
import { SiteDataUpload } from "modules/project-stepper/components/SiteDataUpload";
import { EquipmentDataUpload } from "modules/project-stepper/components/EquipmentDataUpload";
import { Pricing } from "modules/project-stepper/components/Pricing";
import { useSnackbar } from "notistack";
import { Project } from "models/project";
import { RouteComponentProps } from "react-router-dom";
import { useFetchProject } from "modules/hooks/useFetchProject";
import { postRequest } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ProjectProps } from "types/ProjectProps";
import { ProjectUpdateProps } from "types/ProjectUpdateProps";
import { FinalStep } from "modules/project-stepper/components/FinalStep";
import { Stack } from "@mui/material";
import {
  RequestSatelliteEstimateModal,
  SatelliteEstimateRequestProps,
} from "./dialog/RequestSatelliteEstimateModal";
import { useFeature } from "flagged";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgb(244, 244, 244)",
      width: "100%",
      "& #button-div": {
        padding: `${theme.spacing(0)}px ${theme.spacing(12)}px ${theme.spacing(
          12
        )}px`,
        "& #right-buttons": {
          float: "right",
          "&.MuiButton-containedPrimary": {
            "&.Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      "& .step": {
        margin: `${theme.spacing(4)}px ${theme.spacing(12)}px`,
      },
      "& .MuiStepIcon-active, .MuiStepIcon-completed": {
        color: theme.palette.primary.main,
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    wizard: {
      padding: `${theme.spacing(8)}px ${theme.spacing(20)}px ${theme.spacing(
        4
      )}px ${theme.spacing(20)}px`,
    },
  })
);
function getSteps() {
  return [
    "Properties",
    "Site Data",
    "Equipment List",
    "Pricing and Rebates",
    "Summary",
  ];
}

enum ProjectStatus {
  PROJECT_CREATED = 0,
  SATELLITE_ESTIMATES_PENDING = 0,
  SITE_DATA_UPLOADED = 1,
  EQUIPMENT_LIST_UPLOADED = 2,
  PRICING_CALCULATED = 3,
  SUMMARY_CALCULATED = 4,
  SUMMARY_EXPORTED = 4,
}

export function ProjectStepper(
  props: RouteComponentProps<{ project_id?: string }>
) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [nextDisabled, setNextButtonDisabled] = useState(true);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [projectId, setProjectId] = useState(
    props.match.params.project_id || ""
  );
  const [projectName, setProjectName] = useState("");
  const [connectivityOption, setConnectivityOption] = useState("None");
  const [mAndVOption, setMAndVOption] = useState(false);
  const [open, setConfirmationDialogOpen] = useState(false);
  const [
    requestSatelliteEstimateDialogOpen,
    setRequestSatelliteEstimateDialogOpen,
  ] = useState(false);
  const [currencyOption, setCurrencyOption] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(1);
  const [projectOrgOption, setProjectOrgOption] = useState("");
  const [project, setProject] = useState(new Project());
  const [equipmentStatus, setEquipmentStatus] = useState<{
    activeMotorIds: string[];
    inactiveMotorIds: string[];
  }>({ activeMotorIds: [], inactiveMotorIds: [] });
  const hasHi4ai = useFeature("hi4ai");

  const handleUpdateConfirmationOpen = () => {
    setConfirmationDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setConfirmationDialogOpen(false);
  };
  const handleRequestSatelliteEstimate = () => {
    setRequestSatelliteEstimateDialogOpen(true);
  };
  const handleCloseRequestSatelliteEstimateDialog = () => {
    setRequestSatelliteEstimateDialogOpen(false);
  };
  const handleUpdateProject = (body: ProjectUpdateProps) => {
    upsertProject(
      "PUT",
      `projects/${projectId}`,
      "Project successfully updated.",
      body,
      onProjectUpserted
    );
  };

  const handleEquipmentStatusUpdate = (
    activeMotors: string[],
    inactiveMotors: string[]
  ) => {
    // Merge newly selected/unselected motors and deduplicate (in case they were previously selected/unselected).
    const activeMotorIds = equipmentStatus.activeMotorIds
      .filter(
        (id) => !activeMotors.includes(id) && !inactiveMotors.includes(id)
      )
      .concat(activeMotors);
    const inactiveMotorIds = equipmentStatus.inactiveMotorIds
      .filter(
        (id) => !inactiveMotors.includes(id) && !activeMotors.includes(id)
      )
      .concat(inactiveMotors);
    setEquipmentStatus({
      activeMotorIds: activeMotorIds,
      inactiveMotorIds: inactiveMotorIds,
    });
  };

  const updateEquipmentStatus = () => {
    let body: { id: string; status: string }[];
    body = equipmentStatus.activeMotorIds.map((id) => {
      return { id: id, status: "ACTIVE" };
    });
    body = body.concat(
      equipmentStatus.inactiveMotorIds.map((id) => {
        return { id: id, status: "INACTIVE" };
      })
    );
    postRequest(
      "PATCH",
      `${window.APP_CONFIG.API_HOST}/equipment_motors?project_id=${projectId}`,
      auth,
      JSON.stringify(body)
    )
      .then(() => {
        enqueueSnackbar("Equipments successfully updated.", {
          variant: "success",
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNextButtonDisabled(true);
        setEquipmentStatus({ activeMotorIds: [], inactiveMotorIds: [] });
      })
      .catch((error) => {
        enqueueSnackbar(`Equipment status update failed: ${error}`, {
          variant: "error",
        });
        setNextButtonDisabled(false);
      });
  };
  const steps = getSteps();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const setProjectValues = (p: ProjectProps) => {
    project.setProjectProps(p);
    setProject(project);
    if (projectName === p.name) {
      if (projectId !== p.id) {
        setProjectId(p.id);
      }
      return;
    }
    setProjectId(project.id);
    setProjectName(project.name);
    setConnectivityOption(project.connectivity);
    setMAndVOption(project.mAndV);
    setCurrencyOption(project.currency);
    setExchangeRate(project.exchangeRate);
    setProjectOrgOption(project.organizationId);
    setNextButtonDisabled(completedSteps() < steps.length);
    assignCompletedSteps(project.status);
  };
  const assignCompletedSteps = (status: string) => {
    const completedSteps = {} as { [k: number]: boolean };
    const enumStatus: ProjectStatus =
      ProjectStatus[status as keyof typeof ProjectStatus];
    for (let i = 0; i <= enumStatus; i++) {
      completedSteps[i] = true;
    }
    setCompleted(completedSteps);
    setActiveStep(enumStatus < 4 ? enumStatus + 1 : enumStatus);
  };
  const { loading, pr } = useFetchProject(projectId);
  if (loading && projectId !== "") {
    return (
      <div className={classes.root}>
        <div className="step">Loading...</div>
      </div>
    );
  }
  if (pr.project.id && !projectName) {
    setProjectValues(pr.project);
  }
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ProjectOptions
            projectName={projectName}
            connectivityOption={connectivityOption}
            mAndVOption={mAndVOption}
            currencyOption={currencyOption}
            setProjectName={handleProjectNameInput}
            setConnectivityOption={handleConnectivityOptionInput}
            setMAndVOption={handleMAndVOptionInput}
            setCurrency={handleCurrencyOptionInput}
            setExchangeRate={handleExchangeRateInput}
            readOnly={!project.editable && !isNewProject()}
            setProjectOrganization={handleProjectOrganizationSelect}
            projectOrgId={projectOrgOption}
            exchangeRate={exchangeRate}
          />
        );
      case 1:
        if (completedSteps() === 0) {
          assignCompletedSteps(ProjectStatus[0]);
        }
        return (
          <SiteDataUpload
            disableEquipmentDataButton={(disabled) =>
              requestAnimationFrame(() => setNextButtonDisabled(disabled))
            }
            project={project}
            readOnly={!project.editable && !isNewProject()}
          />
        );
      case 2:
        if (completedSteps() === 1) {
          assignCompletedSteps(ProjectStatus[1]);
        }
        return (
          <EquipmentDataUpload
            project={project}
            disablePricingButton={setNextButtonDisabled}
            readOnly={!project.editable && !isNewProject()}
            onEquipmentStatusUpdate={handleEquipmentStatusUpdate}
          />
        );
      case 3:
        if (completedSteps() === 2) {
          assignCompletedSteps(ProjectStatus[2]);
        }
        return (
          <Pricing
            project={project}
            motorDiscount={project.motorDiscount}
            accessoryDiscount={project.accessoryDiscount}
            pmDiscount={project.pmDiscount}
            onDiscountUpdate={setProject}
            readOnly={!project.editable && !isNewProject()}
            setExtendedWarrantyOption={handleExtendedWarrantyOptionInput}
          />
        );
      case 4:
        if (completedSteps() === 3) {
          assignCompletedSteps(ProjectStatus[3]);
        }
        return <FinalStep project={project} />;
      default:
        return "Unknown step";
    }
  };
  const isNewProject = () => {
    return activeStep === 0 && projectId === "";
  };
  const handleNext = (index: number) => {
    closeSnackbar();
    const updateProject = project.isProjectUpdated(
      mAndVOption,
      connectivityOption,
      currencyOption
    );
    if (isNewProject()) {
      let body = {
        name: projectName,
        connectivity: connectivityOption.toUpperCase(),
        m_and_v: mAndVOption,
        currency: currencyOption,
        organization_id: projectOrgOption,
      } as ProjectUpdateProps;
      upsertProject(
        "POST",
        "projects",
        "Project successfully created.",
        body,
        onProjectUpserted
      );
    } else if (updateProject) {
      handleUpdateConfirmationOpen();
    } else if (project.name !== projectName) {
      handleUpdateProject({ name: projectName });
    } else if (
      projectOrgOption &&
      project.organizationId !== projectOrgOption
    ) {
      handleUpdateProject({ organization_id: projectOrgOption });
    } else if (
      activeStep === 2 &&
      (equipmentStatus.activeMotorIds.length > 0 ||
        equipmentStatus.inactiveMotorIds.length > 0)
    ) {
      updateEquipmentStatus();
    } else {
      setActiveStep(index);
      setNextButtonDisabled(true);
    }
  };

  const handleUpdateConfirm = () => {
    let body = {};
    if (mAndVOption !== project.mAndV) {
      Object.assign(body, { m_and_v: mAndVOption });
    }
    if (connectivityOption !== project.connectivity) {
      Object.assign(body, { connectivity: connectivityOption });
    }
    if (currencyOption !== project.currency) {
      Object.assign(body, {
        currency: currencyOption,
      });
    }
    handleUpdateProject(body);
  };

  const handleBack = () => {
    closeSnackbar();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextButtonDisabled(false);
  };
  const handleStep = (step: number) => () => {
    closeSnackbar();
    setActiveStep(step);
    setNextButtonDisabled(false);
  };

  const onProjectUpserted = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setNextButtonDisabled(true);
    handleCloseDialog();
    assignCompletedSteps(ProjectStatus[0]);
  };

  const upsertProject = (
    method: string,
    endpoint: string,
    successMessage: string,
    requestBody: ProjectUpdateProps,
    onUpsertSuccess: () => void
  ) => {
    setNextButtonDisabled(true);

    postRequest<{ data: { project: ProjectProps } }>(
      method,
      `${window.APP_CONFIG.API_HOST}/${endpoint}`,
      auth,
      JSON.stringify(requestBody)
    )
      .then(({ data }) => {
        setProjectValues(data.project);
        project.editable = true;
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        onUpsertSuccess();
      })
      .catch((error) => {
        enqueueSnackbar(`Project update failed: ${error}`, {
          variant: "error",
        });
        setNextButtonDisabled(false);
      });
  };
  const handleProjectNameInput = (name: string) => {
    setNextButtonDisabled(name.length < 3);
    setProjectName(name);
  };
  const handleConnectivityOptionInput = (option: string) => {
    setConnectivityOption(option);
  };
  const handleMAndVOptionInput = (option: boolean) => {
    setMAndVOption(option);
  };
  const handleExtendedWarrantyOptionInput = (option: number) => {
    upsertProject(
      "PUT",
      `projects/${projectId}`,
      "Project Extended Warranty successfully updated.",
      { extended_warranty: option },
      () => {}
    );
  };
  const handleCurrencyOptionInput = (option: string) => {
    setCurrencyOption(option);
  };
  const handleExchangeRateInput = (rate: number) => {
    setExchangeRate(rate);
  };
  const handleProjectOrganizationSelect = (option: string) => {
    setProjectOrgOption(option);
  };
  const requestSatelliteEstimate = (
    estimateProps: SatelliteEstimateRequestProps,
    closeModal: () => void,
    setModalErrors: (arg: Array<any>) => void
  ) => {
    let body: { sites: string[] } = {
      sites: estimateProps.sites.map((site) => site.id),
    };

    postRequest<{ data: { status: string; site_id: string }[] }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/satellite/requests?project_id=${projectId}`,
      auth,
      JSON.stringify(body)
    )
      .then(({ data }) => {
        // TODO: detailed results per site
        const numSuccess = data.reduce(
          (prev, current) =>
            current.status.toLowerCase().indexOf("success") === 0
              ? prev + 1
              : prev,
          0
        );
        numSuccess &&
          enqueueSnackbar(
            `Successfully requested ${numSuccess} satellite estimate${
              numSuccess === 1 ? "" : "s"
            }.`,
            {
              variant: "success",
            }
          );
        numSuccess < estimateProps.sites.length &&
          enqueueSnackbar(
            `${estimateProps.sites.length - numSuccess} request${
              numSuccess === 1 ? " was" : "s were"
            } rejected.`,
            {
              variant: "success",
            }
          );
        closeModal();
        setRequestSatelliteEstimateDialogOpen(false);

        // Disable the Next button
        project.setProjectProps({
          ...pr.project,
          status: "SATELLITE_ESTIMATES_PENDING",
        });
        // Force a reload of the site status
        setProjectId("");
        requestAnimationFrame(() => {
          setProjectId(project.id);
        });
      })
      .catch((errors) => {
        setModalErrors(errors);
      });
  };

  return (
    <div className={classes.root}>
      <Box bgcolor="background.paper" pt={4}>
        {isNewProject() ? (
          <Typography align="center" variant="h4">
            New Project
          </Typography>
        ) : (
          <Typography align="center" variant="h4">
            {`${projectName} (${currencyOption})`}
          </Typography>
        )}
      </Box>
      <Stepper
        nonLinear={!isNewProject()}
        className={classes.wizard}
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton
                disabled={index > completedSteps() - 1}
                onClick={handleStep(index)}
                completed={completed[index]}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <div className="step">{getStepContent(activeStep)}</div>
          <Box
            id="button-div"
            ml={activeStep === 0 ? 8 : 0}
            mr={activeStep === 0 ? 8 : 0}
          >
            {activeStep === 0 ? (
              <Button variant="outlined" href="/">
                Prev
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleBack}>
                Prev
              </Button>
            )}
            <Stack id="right-buttons" direction="row" spacing={2}>
              {hasHi4ai && activeStep === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRequestSatelliteEstimate()}
                >
                  Request Satellite Estimate
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext(activeStep + 1)}
                  disabled={
                    project.status === "SATELLITE_ESTIMATES_PENDING" ||
                    (activeStep < steps.length - 2 ? nextDisabled : false)
                  }
                >
                  Next
                </Button>
              )}
            </Stack>
          </Box>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will have to re-upload Site and Equipment files. Do you want
              to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleUpdateConfirm} color="primary" autoFocus>
              Update Project
            </Button>
          </DialogActions>
        </Dialog>
        {requestSatelliteEstimateDialogOpen && (
          <RequestSatelliteEstimateModal
            projectId={projectId}
            requestSatelliteEstimate={requestSatelliteEstimate}
            onCancel={handleCloseRequestSatelliteEstimateDialog}
          />
        )}
      </div>
    </div>
  );
}
