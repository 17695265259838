import NumberFormat from "react-number-format";
import * as React from "react";

export interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  currencySymbol: string;
  locale: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, currencySymbol, locale, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={Number(10000).toLocaleString(locale).charAt(2)}
      decimalSeparator={Number(1.1).toLocaleString(locale).charAt(1)}
      isNumericString
      prefix={currencySymbol}
    />
  );
}

export const RefNumberFormatCustom = React.forwardRef((props, ref) =>
  NumberFormatCustom(props as NumberFormatCustomProps)
);
