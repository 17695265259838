import * as React from "react";

import { BlobProvider } from "@react-pdf/renderer";
import { SitePDF } from "modules/project-stepper/components/SitePDF";
import { PDFButton } from "./PDFButton";
import { Project } from "models/project";
import { SiteSummary } from "models/siteSummary";

export type SiteReportButtonProps = {
  project: Project;
  site?: SiteSummary;
  disabled: boolean;
};

export function SiteReportButton({
  project,
  site,
  disabled,
}: SiteReportButtonProps) {
  const handleClick = () => {};

  return (
    <BlobProvider document={<SitePDF project={project} site={site} />}>
      {({ url }) => (
        <PDFButton url={url} disabled={disabled} onClick={handleClick} />
      )}
    </BlobProvider>
  );
}
