import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { ProjectManagementFees } from "types/ProjectManagementFees";

export const useFetchProjManagementFees = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingFees, setLoading] = React.useState(true);
  const [fees, setFees] = React.useState<ProjectManagementFees[]>([]);
  React.useEffect(() => {
    const getProjManagementFees = async () => {
      setLoading(true);
      getResource<{ data: ProjectManagementFees[] }>(
        `${window.APP_CONFIG.API_HOST}/project_management_fees?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          setFees(data);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving project management fees. ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getProjManagementFees();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loadingFees,
    fees,
    setFees,
  };
};
