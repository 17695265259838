import React from "react";
import { getResource } from "utils/helpers";
import { DetailedSummary } from "types/DetailedSummary";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";

export const useFetchDetailedSummary = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingDetailedSummary, setLoading] = React.useState(true);
  const [detailedSummary, setSummary] = React.useState<DetailedSummary[]>([]);
  React.useEffect(() => {
    const getSummary = () => {
      setLoading(true);
      getResource<{ data: DetailedSummary[] }>(
        `${window.APP_CONFIG.API_HOST}/detailed_summary?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          setSummary(data);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving project summary ${projectId}: ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getSummary();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    detailedSummary,
    loadingDetailedSummary,
  };
};
