import * as React from "react";
import { MenuItem, TableRow, TableCell, TextField } from "@material-ui/core";
import { SiteDataRow } from "types/SiteDataRow";
import Button from "@material-ui/core/Button";
import { tableStyles } from "modules/common/components/table/tableStyles";
import { Accessory } from "./index";
import { StyledTextField } from "modules/common/components/StyledTextField";
import { Project } from "models/project";

type AccessoryPricingRowProps = {
  sites: SiteDataRow[];
  skus: string[];
  onSave: () => void;
  accessory: Accessory;
  onChange: (a: Accessory) => void;
  project: Project;
};

export function AdHocAccessoryPricingRow({
  sites,
  skus,
  onSave,
  accessory,
  onChange,
  project,
}: AccessoryPricingRowProps) {
  const { sku, site_id, quantity, list_price } = accessory;

  const classes = tableStyles();
  const findSite = (id: string) => {
    return sites.find((s) => s.id === id);
  };
  const siteId = () => {
    return findSite(site_id)?.identifier || "";
  };
  const taxPercentage = () => {
    return findSite(site_id)?.tax_rate_percent;
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          fullWidth
          select
          value={site_id || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const site = findSite(event.target.value);
            onChange({
              ...accessory,
              site_id: event.target.value,
              site_name: site ? site.name : "",
            });
          }}
        >
          {sites.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              selected={option.id === site_id}
            >
              {`${option.name} - ${option.identifier}`}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <TextField value={siteId()} className="input" disabled />
      </TableCell>
      <TableCell>
        <TextField
          required
          fullWidth
          select
          value={sku || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...accessory, sku: event.target.value })
          }
        >
          {skus.map((option) => (
            <MenuItem key={option} value={option} selected={option === sku}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <StyledTextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...accessory, quantity: event.target.value })
          }
          type="number"
          value={quantity}
        />
      </TableCell>
      <TableCell>{project.formatMoney(list_price)}</TableCell>
      <TableCell>
        <TextField disabled />
      </TableCell>
      <TableCell>
        <TextField value={taxPercentage() || 0} disabled />
      </TableCell>
      <TableCell align="center">
        <Button onClick={onSave} variant="outlined" className={classes.button}>
          Save
        </Button>
      </TableCell>
    </TableRow>
  );
}
