import * as React from "react";
import { Summary } from "modules/project-stepper/components/Summary";
import { BillOfMaterials } from "modules/project-stepper/components/BillOfMaterials";
import { CustomTabs } from "modules/common/components/CustomTabs";
import { Box } from "@material-ui/core";
import { Project } from "models/project";

type FinalStepProps = {
  project: Project;
};

export function FinalStep({ project }: FinalStepProps) {
  return (
    <CustomTabs
      tabNames={["Summary", "Bill of Materials"]}
      tabPanels={[
        <Box>
          <Summary project={project} />
        </Box>,
        <Box>
          <BillOfMaterials project={project} />
        </Box>,
      ]}
    />
  );
}
