import * as React from "react";
import { Button, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { WarningBanner } from "./WarningBanner";
import { styled } from "@mui/material/styles";

type FileInputProps = {
  value: File | null;
  onChange: (f: File | null) => void;
  name: string;
  sx: SxProps;
  disabled: boolean;
  title?: string;
};
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    maxWidth: 300,
    fontFamily: "Barlow",
    fontSize: 15,
    border: `1px solid ${theme.palette.warning.dark}`,
  },
}));

export function FileUpload({ onChange, sx, disabled, title }: FileInputProps) {
  return (
    <>
      <input
        hidden
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        id="button-file"
        type="file"
        onChange={(e) => {
          onChange(e.target.files![0]);
        }}
        disabled={disabled}
      />
      <HtmlTooltip enterDelay={title ? 0 : 100} title={title || ""}>
        <label htmlFor="button-file" style={{ marginRight: "24px" }}>
          <Button
            variant="outlined"
            color="primary"
            component="span"
            sx={sx}
            disabled={disabled}
          >
            Upload .XLSX
          </Button>
        </label>
      </HtmlTooltip>
    </>
  );
}

export function FormsChangedBanner() {
  return (
    <WarningBanner label="Excel Forms have changed on 4/19/2023! Please download the latest version." />
  );
}
