import React from "react";
import { getResource } from "utils/helpers";
import { ProjectProps } from "types/ProjectProps";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { ProjectShare } from "../../types/ProjectShare";

export const useFetchProject = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [pr, setProject] = React.useState({
    project: {} as ProjectProps,
    project_share: {} as ProjectShare,
  });
  React.useEffect(() => {
    if (projectId && projectId !== "") {
      const getProject = () => {
        setLoading(true);
        getResource<{
          data: { project: ProjectProps; project_share: ProjectShare };
        }>(`${window.APP_CONFIG.API_HOST}/projects/${projectId}`, auth)
          .then(({ data }) => {
            setLoading(false);
            setProject({
              project: data.project,
              project_share: data.project_share,
            });
          })
          .catch((error) => {
            enqueueSnackbar(
              `There was an error retrieving project ${projectId}: ${error}`,
              {
                variant: "error",
              }
            );
            setLoading(false);
          });
      };
      getProject();
    }
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loading,
    pr,
  };
};
