import React, { ReactNode } from "react";
import {
  Box,
  IconButton,
  Typography,
  Link,
  Grid,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import { useFetchExecutiveSummary } from "modules/hooks/useFetchExecutiveSummary";
import { useFetchDetailedSummary } from "modules/hooks/useFetchDetailedSummary";
import { formatNumber } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import { Project } from "models/project";
import { useState } from "react";
import {
  DataTable,
  ValueFormatter,
  createColumn,
  DEFAULT_COLUMN_WIDTH,
  percentValueFormatter,
} from "modules/common/components/table/DataTable";
import {
  GridCellValue,
  GridColumns,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { ReportButton } from "./ReportButton";
import { SummaryCards } from "./SummaryCards";
import { SiteReportModal } from "./dialog/SiteReportModal";
import { DetailedSummary } from "types/DetailedSummary";

type SummaryProps = {
  project: Project;
};

export function Summary({ project }: SummaryProps) {
  const { summary, loading } = useFetchExecutiveSummary(project.id);
  const { detailedSummary, loadingDetailedSummary } = useFetchDetailedSummary(
    project.id
  );
  const [showSummary, setShowSummary] = useState(false);
  const [site, setSite] = useState<DetailedSummary>();
  const [siteReportOpen, setSiteReportOpen] = useState(false);
  type SummaryProps = {
    header: string;
    value: number;
    formatCurrency?: boolean;
    link?: string;
    footer?: string;
  };
  const SummaryItem = ({
    header,
    value,
    formatCurrency = false,
    link = "",
    footer,
  }: SummaryProps) => {
    let formattedValue = formatCurrency
      ? project.formatMoney(value)
      : formatNumber(value, 0);
    return (
      <Grid item xs={2}>
        <Box pr={3}>
          <Typography color="textSecondary" variant="caption">
            {header}
          </Typography>
        </Box>
        {link === "" ? (
          <Typography variant="h5">{formattedValue}</Typography>
        ) : (
          <Link href={link} underline="none">
            <Typography variant="h5">{formattedValue}</Typography>
          </Link>
        )}
        {footer && (
          <Typography color="textSecondary" variant="caption">
            {footer}
          </Typography>
        )}
      </Grid>
    );
  };

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  let filename = "";

  async function downloadFile(type: string) {
    if (type === "pdf") {
      console.error("PDF download is no longer available");
      return;
    }
    const requestHeaders = {
      Authorization: `Bearer ${await auth.getToken()}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    };

    const options = {
      headers: requestHeaders,
      responseType: "blob",
    };
    fetch(
      `${window.APP_CONFIG.API_HOST}/summary_excel?project_id=${project.id}`,
      options
    )
      .then((response) => {
        if (response.ok) {
          const header = response.headers
            .get("content-disposition")
            ?.split("filename=");
          if (header) {
            filename = header[1].substring(1, header[1].length - 1);
          }
          return response.blob();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((e) => {
        enqueueSnackbar(`There was an error downloading the file: ${e}`, {
          variant: "error",
        });
      });
  }

  if (loading || loadingDetailedSummary) {
    return <div>Loading summary...</div>;
  }

  const hasWarning = summary.warnings && summary.warnings[0];

  const formatMoney: ValueFormatter = (
    params: GridValueFormatterParams
  ): GridCellValue => {
    return project.formatMoney(Number(params.value));
  };

  const renderSiteReportLink = (params: GridRenderCellParams): ReactNode => {
    if (params && params.id) {
      const site = detailedSummary.find(
        (s) => s.site_id === params.row.site_id
      );
      if (site?.motor_quantity) {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setSite(site);
              setSiteReportOpen(true);
            }}
          >
            {params.value}
          </Link>
        );
      }
    }
    return params.value;
  };

  const columns: GridColumns = [
    createColumn(
      "identifier",
      "Site ID",
      "string",
      150,
      undefined,
      renderSiteReportLink
    ),
    createColumn(
      "motor_quantity",
      "Motor Quantity",
      "number",
      DEFAULT_COLUMN_WIDTH
    ),
    createColumn(
      "base_energy_usage",
      "Baseline Energy Usage (kWh)",
      "number",
      145
    ),
    createColumn(
      "turntide_energy_usage",
      "Turntide Energy Usage (kWh)",
      "number",
      145
    ),
    {
      ...createColumn("energy_savings_kwh", "Energy Savings (kWh)", "number"),
      valueGetter: (params: GridValueGetterParams) =>
        params.row.base_energy_usage - params.row.turntide_energy_usage,
    },
    createColumn(
      "energy_savings_percent",
      "% Energy Savings",
      "number",
      130,
      percentValueFormatter
    ),
    createColumn(
      "peak_kw_reduction",
      "Peak Demand Reduction (kW)",
      "number",
      150
    ),
    createColumn("utility_rate", "Utility Rate", "number", 115),
    createColumn(
      "demand_charge_reduction",
      "Peak Demand Savings",
      "number",
      130,
      formatMoney
    ),
    createColumn(
      "energy_cost_savings",
      "Energy Cost Savings",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn("co_reduction", "Carbon Reduction (CO2e)", "number", 130),
    createColumn(
      "total_material_price",
      "Total Material Price",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn(
      "total_misc_expense",
      "Total Misc Expenses",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn("total_labor_hours", "Total Labor Hours", "number", 115),
    createColumn(
      "total_labor_price",
      "Total Labor Price",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn("total_pm_hours", "Project Management Hours", "number", 150),
    createColumn(
      "total_pm_price",
      "Project Management Fees",
      "number",
      150,
      formatMoney
    ),
    createColumn("survey_costs", "Survey Costs", "number", 150, formatMoney),
    createColumn(
      "total_price",
      "Price Before Rebates",
      "number",
      150,
      formatMoney
    ),
    createColumn(
      "total_rebates",
      "Rebates",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    {
      ...createColumn(
        "price_after_rebates",
        "Price After Rebates",
        "number",
        150,
        formatMoney
      ),
      valueGetter: (params: GridValueGetterParams): GridCellValue =>
        params.row.total_price - params.row.total_rebates,
    },
    createColumn("simple_payback", "Simple Payback (Years)", "number"),
  ];

  return (
    <Box mt={5}>
      <Box mt={6}>
        <SummaryCards project={project} summary={summary} showIcons={true} />
        <Divider sx={{ pt: 4 }}>
          <Button
            variant="outlined"
            onClick={() => setShowSummary(!showSummary)}
            style={{
              color: "black",
              backgroundColor: "transparent",
              border: "1px #0000001f solid",
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            {showSummary
              ? "Hide Additional Information"
              : "Show Additional Information"}
          </Button>
        </Divider>
        {showSummary && (
          <>
            <Stack direction="row" spacing={10} style={{ paddingTop: 40 }}>
              <SummaryItem
                header="Price Before Rebates"
                value={summary.total_price}
                formatCurrency={true}
              />
              <SummaryItem
                header="Total Material Price"
                value={summary.total_material_price}
                formatCurrency={true}
              />
              <SummaryItem
                header="Baseline Energy Usage (kWh)"
                value={summary.base_energy_usage}
              />
              <SummaryItem
                header="Annual Energy Cost Savings"
                value={summary.energy_cost_savings}
                formatCurrency={true}
              />
              <SummaryItem
                header="Peak Demand Savings"
                value={summary.demand_charge_reduction}
                formatCurrency={true}
              />
            </Stack>
            <Stack direction="row" spacing={9} style={{ paddingTop: 20 }}>
              <SummaryItem
                header="Rebates"
                value={summary.total_rebates}
                formatCurrency={true}
              />
              <SummaryItem
                header="Total Labor Price"
                value={summary.total_labor_price}
                formatCurrency={true}
                footer={`(${
                  formatNumber(summary.total_labor_hours) || 0
                } hours)`}
              />
              <SummaryItem
                header="Turntide Energy Usage (kWh)"
                value={summary.turntide_energy_usage}
              />
              <SummaryItem
                header="% Energy Savings"
                value={summary.energy_savings_percent}
              />
              <SummaryItem
                header="Peak Demand Reduction (kW)"
                value={summary.peak_kw_reduction}
              />
            </Stack>
            <Stack direction="row" spacing={9} style={{ paddingTop: 20 }}>
              <SummaryItem
                header="Misc Expenses"
                value={summary.total_misc_expense}
                formatCurrency={true}
              />
              <SummaryItem
                header="Project Management Fees"
                value={summary.total_pm_price}
                formatCurrency={true}
                footer={`(${formatNumber(summary.total_pm_hours) || 0} hours)`}
              />
              <SummaryItem
                header="Survey Costs"
                value={summary.total_survey_costs}
                formatCurrency={true}
              />
              <SummaryItem
                header="Annual Energy Savings (kWh)"
                value={
                  summary.base_energy_usage - summary.turntide_energy_usage
                }
              />
              <SummaryItem
                header="Motor Quantity"
                value={summary.motor_quantity}
              />
            </Stack>
          </>
        )}
      </Box>
      <Box mt={8}>
        <Box
          mr={3}
          display="flex"
          flexDirection={hasWarning ? "row" : "row-reverse"}
        >
          {hasWarning && (
            <Box flexGrow={1}>
              <Box style={{ width: "fit-content", background: "#FFFACC" }}>
                {summary.warnings[0]}
              </Box>
            </Box>
          )}
          <ReportButton
            summary={summary}
            detailedSummary={detailedSummary}
            project={project}
          ></ReportButton>
          <IconButton onClick={() => downloadFile("excel")}>
            <i
              className="fas fa-file-excel fa-lg"
              style={{ color: "green" }}
            ></i>
          </IconButton>
          <Box>
            <Box pr={3}>
              <Typography color="textSecondary" variant="caption">
                Logic Version
              </Typography>
            </Box>
            <Link
              href={`https://${window.APP_CONFIG.ASSETS_HOST}/ROI-Calculator-${summary.logic_version}.pdf`}
              underline="none"
            >
              <Typography variant="h6">{summary.logic_version}</Typography>
            </Link>
          </Box>
        </Box>
        <Box mb={1}>
          <Typography variant="h6" fontFamily="Barlow">
            Detailed Summary
          </Typography>
        </Box>
        <DataTable
          rows={detailedSummary}
          columns={columns}
          columnBuffer={18}
          disableColumnMenu
          headerHeight={80}
          autoHeight
        />
      </Box>
      {siteReportOpen && site && (
        <SiteReportModal
          project={project}
          siteSummary={site}
          onClose={() => setSiteReportOpen(false)}
        />
      )}
    </Box>
  );
}
