import * as React from "react";
import { withRouter } from "react-router-dom";
import { AppBar, Toolbar, Typography, Box } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "./logo.svg";
import { HelpModal } from "modules/project-stepper/components/dialog/HelpModal";
import { ChangeLogModal } from "modules/common/components/dialog/ChangeLogModal";
const WhiteAppBar = withStyles(
  createStyles({
    root: {
      backgroundColor: "white",
      overflow: "auto",
      borderBottom: "solid 1px #D8D8D8",
      "& a": {
        color: "black",
      },
    },
  })
)(AppBar);

const HeaderComponent = () => {
  return (
    <React.Fragment>
      <WhiteAppBar position="static" elevation={0}>
        <Toolbar>
          <a href="/">
            <Logo />
          </a>
          {/* Divider */}
          <div
            style={{
              marginLeft: 45,
              marginRight: 45,
              borderLeft: "1px solid #D8D8D8",
              height: 24,
            }}
          />

          <Typography variant="h6" color="textSecondary">
            ROI Calculator
          </Typography>
          <Box flexGrow={1} />
          <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
            <Box p={1}>
              <a href="/oauth2/logout">
                <Typography variant="body2">Log out</Typography>
              </a>
            </Box>
            <Box p={1} mr={1}>
              <ChangeLogModal />
            </Box>
            <Box p={1} mr={1}>
              <HelpModal />
            </Box>
            <Box p={1} mr={1}>
              <a href="/users">
                <Typography variant="body2">Users</Typography>
              </a>
            </Box>
            <Box p={1} mr={1}>
              <a href="/">
                <Typography variant="body2">Home</Typography>
              </a>
            </Box>
          </Box>
        </Toolbar>
      </WhiteAppBar>
      <hr />
    </React.Fragment>
  );
};

export const Header = withRouter(HeaderComponent);
