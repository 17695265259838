import React, { useState } from "react";
import { Button, Grid, Box, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../common/components/dialog/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { DialogContent, TextField, MenuItem } from "@material-ui/core";

import { tableStyles } from "modules/common/components/table/tableStyles";
import { ShareDetails } from "./ShareDetails";
import { useFetchUsers } from "../hooks/useFetchUsers";
import { postRequest, deleteRequest } from "../../utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "../common/auth";
import { ProjectShare } from "types/ProjectShare";
import { User } from "types/User";

type Share = {
  project_shares: ProjectShare[];
  users: User[];
};

type ShareProjectProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  projectId: string;
  shares: Share;
  addProjectShare: (share: ProjectShare, user: User) => void;
  updateProjectShare: (share: ProjectShare) => void;
  deleteProjectShare: (id: string) => void;
};

export enum AccessLevel {
  EDIT = "Edit",
  READ_ONLY = "Read-Only",
}

export function ShareProjectDialog({
  open,
  setOpen,
  projectId,
  shares,
  addProjectShare,
  updateProjectShare,
  deleteProjectShare,
}: ShareProjectProps) {
  const classes = tableStyles();
  const [internalUser, setInternalUser] = useState("");
  const [extUser, setExtUser] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const onShare = (permLevel: string) => {
    const body =
      internalUser !== ""
        ? JSON.stringify({
            project_id: projectId,
            shared_with: users.find((u) => u.email === internalUser)?.id,
            permission_level: permLevel.replace("-", " "),
            shared_with_email: users.find((u) => u.email === internalUser)
              ?.email,
          })
        : JSON.stringify({
            project_id: projectId,
            shared_with_email: extUser,
            permission_level: permLevel,
          });
    postRequest<{ data: { project_share: ProjectShare } }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/project_shares`,
      auth,
      body
    )
      .then(({ data }) => {
        let u = {} as User;
        u.email = users.find((u) => u.email === internalUser)?.email || extUser;
        u.id = data.project_share.shared_with;
        addProjectShare(data.project_share, u);
        enqueueSnackbar(`Project has been successfully shared`, {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar(`Project share failed: ${error}`, {
          variant: "error",
        });
      });
  };

  const onExternalUserSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtUser(event.target.value);
    setInternalUser("");
  };

  const projectShares = () => {
    return shares.project_shares.filter((s) => s.project_id === projectId);
  };

  const { loading, data } = useFetchUsers();
  const users = data && !loading ? data : [];

  const onEditPermission = (project_share_id: string, option: string) => {
    if (option === "Remove") {
      deleteRequest<{ data: { success: boolean } }>(
        "DELETE",
        `${window.APP_CONFIG.API_HOST}/project_shares/${project_share_id}`,
        auth
      )
        .then(({ data }) => {
          deleteProjectShare(project_share_id);
          enqueueSnackbar(`Project has been successfully unshared.`, {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(`Unshare failed: ${error}`, {
            variant: "error",
          });
        });
    } else {
      const body = JSON.stringify({
        permission_level: option,
      });

      postRequest<{ data: { project_share: ProjectShare } }>(
        "PUT",
        `${window.APP_CONFIG.API_HOST}/project_shares/${project_share_id}`,
        auth,
        body
      )
        .then(({ data }) => {
          updateProjectShare(data.project_share);

          enqueueSnackbar(
            `Project share permission has been successfully updated`,
            {
              variant: "success",
            }
          );
        })
        .catch((error) => {
          enqueueSnackbar(`Permission update failed: ${error}`, {
            variant: "error",
          });
        });
    }
  };

  const onDone = () => {
    if (internalUser !== "" || extUser !== "") {
      setShowDetails(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <DialogTitle onClose={() => setOpen(false)}>
        <Box ml={1}>
          <Typography align="left" variant="h6">
            Share Project
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!showDetails ? (
          <Box mb={4} ml={1} mr={1} mt={1}>
            <Box mb={4}>
              <TextField
                value={extUser}
                onChange={onExternalUserSelect}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      style={{
                        width: "50%",
                        backgroundColor: "#E8E8E8",
                        padding: "8px",
                        marginRight: "8px",
                      }}
                    >
                      <i
                        style={{ color: "#999999", marginRight: "8px" }}
                        className="fa fa-envelope"
                        aria-hidden="true"
                        color="gray"
                      ></i>
                      e-mail
                    </Grid>
                  ),
                }}
              />
            </Box>
            <Divider />
            <Box mt={4} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography color="textSecondary" variant="overline">
                    User
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" variant="overline">
                    Access Level
                  </Typography>
                </Grid>
                {projectShares().map((row) => (
                  <React.Fragment key={row.id}>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                        {shares.users.find(
                          (user) => user.id === row.shared_with
                        )?.email || ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        select
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onEditPermission(row.id, event.target.value)}
                        value={
                          AccessLevel[
                            row.permission_level.replace(
                              " ",
                              "_"
                            ) as keyof typeof AccessLevel
                          ]
                        }
                        InputProps={{ disableUnderline: true }}
                      >
                        {Object.values(AccessLevel).map((level) => (
                          <MenuItem
                            key={`${row.id}-${level}`}
                            value={level}
                            selected={
                              AccessLevel[
                                row.permission_level.replace(
                                  " ",
                                  "_"
                                ) as keyof typeof AccessLevel
                              ] === level
                            }
                          >
                            {level}
                          </MenuItem>
                        ))}
                        <MenuItem key={`${row.id}-remove`} value="Remove">
                          Unshare
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Divider />
            <Box mt={5} display="flex" flexDirection="row-reverse">
              <Button
                className={classes.button}
                onClick={onDone}
                variant="contained"
              >
                Done
              </Button>
            </Box>
          </Box>
        ) : (
          <ShareDetails
            user={internalUser !== "" ? internalUser : extUser}
            onPrevClick={() => setShowDetails(false)}
            onShare={onShare}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
