import * as React from "react";
import { Box, Button, Card } from "@material-ui/core";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { SimpleTable } from "modules/common/components/table/SimpleTable";
import { ProjectManagementFees } from "types/ProjectManagementFees";
import { Project } from "models/project";
import DiscountField from "../../common/components/DiscountField";
import { ProjectPricingUpdate } from "models/priceAdjustments";
import EditableTableCell from "modules/common/components/table/EditableTableCell";
import { handleTextFieldChange } from "utils/helpers";
import { RowUpdate } from "types/FieldUpdateTypes";

export type ProjectManagementProps = {
  fees: ProjectManagementFees[];
  updateProject: ProjectPricingUpdate;
  updateSitePMPricing: (item: RowUpdate) => void;
  pmDiscount: number;
  project: Project;
};

export function ProjectManagement(props: ProjectManagementProps) {
  const { fees, updateProject, updateSitePMPricing, pmDiscount, project } =
    props;
  const [discountUpdated, setDiscountUpdated] = React.useState(false);
  const setDiscount = (d: string) => {
    updateProject({ pmDiscount: +d }, () => setDiscountUpdated(true));
  };
  const [rowUpdates, setRowUpdates] = React.useState<RowUpdate[]>([]);
  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lineItemId: string
  ) => {
    const changesInLineItem = rowUpdates.find(
      (c) => c.lineItemId === lineItemId
    );
    if (changesInLineItem) {
      updateSitePMPricing(changesInLineItem);
    }
  };

  return (
    <Box mt={2}>
      <Box mb={1} display="flex">
        <Typography
          variant="h6"
          style={{
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          Project Management + Survey Pricing
        </Typography>
        <DiscountField
          defaultValue={pmDiscount}
          setDiscount={setDiscount}
          discountUpdated={discountUpdated}
        />
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "PM Labor Hours",
              "PM Rate ($/hr)",
              "Total PM Cost",
              "Survey Cost",
            ]}
            emptyMessage="No Project Management Fees or Survey Costs"
          >
            {fees.map((f: ProjectManagementFees) => (
              <TableRow key={f.id}>
                <TableCell>{f.site_name}</TableCell>
                <TableCell>{f.site_identifier}</TableCell>
                <EditableTableCell
                  defaultValue={f.pm_hours || 0}
                  fieldName={"pm_hours"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      f.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <EditableTableCell
                  defaultValue={f.pm_hourly_rate || 0}
                  fieldName={"pm_hourly_rate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      f.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={true}
                />
                <TableCell>{project.formatMoney(f.pm_fee)}</TableCell>
                <EditableTableCell
                  defaultValue={f.survey_costs || 0}
                  fieldName={"survey_costs"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      f.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={true}
                />
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSave(event, f.id)}
                    variant="outlined"
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
    </Box>
  );
}
