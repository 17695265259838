import * as React from "react";
import { MotorPricing } from "./MotorPricing";
import { Rebates } from "./Rebates";
import { MotorLineItemRow } from "types/MotorLineItemRow";
import {
  postRequest,
  getResource,
  formatNumber,
  keysToSnake,
} from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { AccessoryLineItemRow } from "types/accessoryLineItemRow";
import { useFetchMotorLineItems } from "modules/hooks/useFetchMotorLineItems";
import { useFetchAccessoryLineItems } from "modules/hooks/useFetchAccessoryLineItems";
import { useFetchMotorRebates } from "modules/hooks/useFetchMotorRebates";
import { useFetchAssetRebates } from "modules/hooks/useFetchAssetRebates";
import { useFetchProjManagementFees } from "modules/hooks/useFetchProjManagementFees";
import { useFetchSubscriptionLineItems } from "modules/hooks/useFetchSubscriptionLineItems";
import { RowUpdate } from "types/FieldUpdateTypes";
import { Box, Divider, Typography } from "@material-ui/core";
import { MotorRebate } from "models/motorRebate";
import { AssetRebate } from "models/assetRebate";
import { MotorRebateRow } from "types/MotorRebateRow";
import { AssetRebateRow } from "types/AssetRebateRow";
import { CustomTabs } from "modules/common/components/CustomTabs";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProjectManagement } from "./ProjectManagement";
import { ProjectProps } from "types/ProjectProps";
import { Accessories } from "./Accessories";
import { orderBy, sumBy } from "lodash";
import { Project } from "models/project";
import { ProjectManagementFees } from "types/ProjectManagementFees";
import { uniq } from "lodash";
import { PriceAdjustments } from "models/priceAdjustments";
import { SubscriptionLineItemRow } from "types/SubscriptionLineItemRow";

export interface PricingProps {
  project: Project;
  motorDiscount: number;
  accessoryDiscount: number;
  pmDiscount: number;
  onDiscountUpdate: (project: Project) => void;
  readOnly: boolean;
  setExtendedWarrantyOption: (option: number) => void;
}

type Item =
  | MotorRebateRow
  | AssetRebateRow
  | MotorLineItemRow
  | AccessoryLineItemRow;

function isSubscriptionLineItemRow(
  item: AccessoryLineItemRow | MotorLineItemRow | SubscriptionLineItemRow
): item is SubscriptionLineItemRow {
  return (item as SubscriptionLineItemRow).years !== undefined;
}

export const calculateItemMaterialPrice = (
  item: AccessoryLineItemRow | MotorLineItemRow | SubscriptionLineItemRow
) => {
  if (isSubscriptionLineItemRow(item)) {
    return item.list_price * item.quantity * item.years;
  }
  return (item.override_price ?? item.list_price) * item.quantity;
};

export const calculatePriceAdjustments = (
  price: number,
  discount: number,
  materialMarkup: number
) => {
  const discountedMaterialPrice = price - (price * discount) / 100;
  const markupPrice = (discountedMaterialPrice * materialMarkup) / 100;
  return {
    discountedPrice: discountedMaterialPrice,
    markup: markupPrice,
  };
};

export const calculateMaterialPriceAdjustments = (
  item: AccessoryLineItemRow | MotorLineItemRow | SubscriptionLineItemRow,
  discount: number,
  materialMarkup: number
) =>
  calculatePriceAdjustments(
    calculateItemMaterialPrice(item),
    discount,
    materialMarkup
  );

export const calculateItemLaborPrice = (
  item: AccessoryLineItemRow | MotorLineItemRow
) => {
  const basePrice = "misc_expense" in item ? item.misc_expense : 0;
  return (
    basePrice + item.labor_hours_per_unit * item.labor_rate_hr * item.quantity
  );
};

export const calculateLaborPriceAdjustments = (
  item: AccessoryLineItemRow | MotorLineItemRow,
  laborMarkup: number
) => calculatePriceAdjustments(calculateItemLaborPrice(item), 0, laborMarkup);

// 1. discount the list price
// 2. markup the discounted price
// 3. tax the result
export const calculateTotalMaterialCost = (
  item: AccessoryLineItemRow | MotorLineItemRow | SubscriptionLineItemRow,
  tax_rate_percent: number,
  discount: number,
  markup: number
) => {
  const price = calculateMaterialPriceAdjustments(item, discount, markup);
  const subtotal = price.discountedPrice + price.markup;
  return subtotal * (1 + tax_rate_percent / 100);
};

// 1. discount the list price
// 2. markup the discounted price
// 3. tax the result
export const calculateTotalLaborCost = (
  item: AccessoryLineItemRow | MotorLineItemRow,
  markup: number
) => {
  const price = calculateLaborPriceAdjustments(item, markup);
  const subtotal = price.discountedPrice + price.markup;
  return subtotal;
};

export const calculateItemMarkup = (
  item: MotorLineItemRow | AccessoryLineItemRow,
  discount: number,
  materialMarkup: number,
  laborMarkup: number
) => {
  const price = calculateMaterialPriceAdjustments(
    item,
    discount,
    materialMarkup
  );
  const laborPrice = calculateLaborPriceAdjustments(item, laborMarkup);
  return price.markup + laborPrice.markup;
};

export const calculatePerSitePricing = (
  allMlis: MotorLineItemRow[],
  allAlis: AccessoryLineItemRow[],
  siteIds: string[],
  project: Project,
  fees: ProjectManagementFees[]
) => {
  let res = new Map<string, number>();
  siteIds.forEach((siteId: string) => {
    const mlis = allMlis.filter(
      (item: MotorLineItemRow) => item.site_identifier === siteId
    );
    let motorPrice = 0;
    mlis.forEach((mli: MotorLineItemRow) => {
      const materialAdjustments = calculateMaterialPriceAdjustments(
        mli,
        project.motorDiscount,
        project.motorMaterialMarkup
      );
      const laborAdjustments = calculateLaborPriceAdjustments(
        mli,
        project.motorLaborMarkup
      );
      motorPrice +=
        materialAdjustments.discountedPrice +
        materialAdjustments.markup +
        laborAdjustments.discountedPrice +
        laborAdjustments.markup;
    });
    const alis = allAlis.filter(
      (acc: AccessoryLineItemRow) => acc.site_identifier === siteId
    );
    let accessoryPrice = 0;
    alis.forEach((ali: AccessoryLineItemRow) => {
      const materialAdjustments = calculateMaterialPriceAdjustments(
        ali,
        project.accessoryDiscount,
        project.accessoryMaterialMarkup
      );
      const laborAdjustments = calculateLaborPriceAdjustments(
        ali,
        project.accessoryLaborMarkup
      );
      accessoryPrice +=
        materialAdjustments.discountedPrice +
        materialAdjustments.markup +
        laborAdjustments.discountedPrice +
        laborAdjustments.markup;
    });
    const pm = fees.find(
      (pmFee: ProjectManagementFees) => pmFee.site_identifier === siteId
    );
    const totalPM = pm ? pm.pm_fee + pm.survey_costs : 0;
    const pmFee = totalPM - (totalPM * project.pmDiscount) / 100;
    res.set(siteId, motorPrice + accessoryPrice + pmFee);
  });
  return res;
};

export function Pricing({
  project,
  accessoryDiscount,
  pmDiscount,
  onDiscountUpdate,
  setExtendedWarrantyOption,
  readOnly,
}: PricingProps) {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [accessoriesAndMotors, setAccessoriesAndMotors] = React.useState({
    alis: [] as AccessoryLineItemRow[],
    mlis: [] as MotorLineItemRow[],
  });
  const [motorRebates, setMotorRebates] = React.useState<MotorRebate[]>([]);
  const [assetRebates, setAssetRebates] = React.useState<AssetRebate[]>([]);
  const [metadata, setMetadata] = React.useState({
    accessorySkus: [] as string[],
    utility_cost_savings: 0,
  });

  React.useEffect(() => {
    getResource<{
      data: { accessory_skus: string[]; utility_cost_savings: number };
    }>(
      `${window.APP_CONFIG.API_HOST}/pricing_metadata?project_id=${project.id}`,
      auth
    )
      .then(({ data }) => {
        setMetadata({
          accessorySkus: data.accessory_skus,
          utility_cost_savings: data.utility_cost_savings,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`There was an error retrieving accessories. ${error}`, {
          variant: "error",
        });
      });
  }, [auth, enqueueSnackbar, project.id]);

  const totalMotorPrice = () =>
    getMotorLineItems()
      .map((item) =>
        calculateTotalMaterialCost(
          item,
          item.tax_rate_percent,
          project.motorDiscount,
          project.motorMaterialMarkup
        )
      )
      .reduce((sum, current) => sum + current, 0);

  const totalMotorLabor = () =>
    getMotorLineItems()
      .map((item) => calculateTotalLaborCost(item, project.motorLaborMarkup))
      .reduce((sum, current) => sum + current, 0);

  const totalAccessoryPrice = () =>
    [...getAccessories(), ...getSubscriptions()]
      .map((item) =>
        calculateTotalMaterialCost(
          item,
          item.tax_rate_percent,
          project.accessoryDiscount,
          project.accessoryMaterialMarkup
        )
      )
      .reduce((sum, current) => sum + current, 0);

  const totalAccessoryLabor = () =>
    getAccessories()
      .map((item) =>
        calculateTotalLaborCost(item, project.accessoryLaborMarkup)
      )
      .reduce((sum, current) => sum + current, 0);

  const totalProjManagementFees = () => {
    const total = sumBy(fees, "pm_fee") + sumBy(fees, "survey_costs");
    return total - (total * pmDiscount) / 100;
  };

  const totalMotorMarkup = () =>
    getMotorLineItems()
      .map((mli: MotorLineItemRow) =>
        calculateItemMarkup(
          mli,
          project.motorDiscount,
          project.motorMaterialMarkup,
          project.motorLaborMarkup
        )
      )
      .reduce((sum, current) => sum + current, 0);

  const totalAccessoryMarkup = () =>
    getAccessories()
      .map((ali: AccessoryLineItemRow) =>
        calculateItemMarkup(
          ali,
          project.accessoryDiscount,
          project.accessoryMaterialMarkup,
          project.accessoryLaborMarkup
        )
      )
      .reduce((sum, current) => sum + current, 0);

  // Calculates values that need to be displayed in the “Rebates by Asset” section. If an asset rebate doesn't have a max
  // percent of price, then "Final Rebate" is the sum of motor rebates for the site. If user enters and saves a new max
  // percent of price in the asset section, the “Final Rebate” value is recalculated based on the asset rebate.
  const calculateRebates = () => {
    let rebates = new Map<string, number>();
    const allMlis = getMotorLineItems();
    const allAlis = getAccessories();
    const siteIds = uniq(
      mlis.map((mli: MotorLineItemRow) => mli.site_identifier)
    );
    const perSitePricing = calculatePerSitePricing(
      allMlis,
      allAlis,
      siteIds,
      project,
      fees
    );
    const assetRebates = getAssetRebates();
    const motorRebates = getMotorRebates();
    assetRebates.forEach((aRebate: AssetRebate) => {
      const siteId = aRebate.siteIdentifier;
      const sitePrice = perSitePricing.get(siteId);
      const motorRebatesForSite = motorRebates.filter(
        (rebate: MotorRebate) => rebate.siteIdentifier === siteId
      );
      const totalMotorRebates = motorRebatesForSite
        .map((rebate: MotorRebate) => rebate.totalRebate())
        .reduce((sum, current) => sum + current, 0);
      const totalRebates = totalMotorRebates + aRebate.totalRebate();
      if (sitePrice) {
        const maxRebate = (sitePrice * aRebate.percentOfMaxPrice) / 100;
        rebates.set(siteId, Math.min(maxRebate, totalRebates));
      }
    });
    return rebates;
  };

  const calculateTotalRebate = (rebatesBySite: Map<string, number>) => {
    let totalRebate = 0;
    rebatesBySite.forEach((rebate) => {
      totalRebate += rebate;
    });
    return totalRebate;
  };

  const priceBeforeRebate = () => {
    const motorPrice = totalMotorPrice();
    const motorLabor = totalMotorLabor();
    const accessoryPrice = totalAccessoryPrice();
    const accessoryLabor = totalAccessoryLabor();
    const projManagementFees = totalProjManagementFees();
    return (
      motorPrice +
      motorLabor +
      accessoryPrice +
      accessoryLabor +
      projManagementFees
    );
  };

  const priceAfterRebate = (price: number) => {
    return price - totalRebate;
  };

  const { loadingMlis, mlis } = useFetchMotorLineItems(project.id);
  const { loadingAlis, alis } = useFetchAccessoryLineItems(project.id);
  const { loadingSlis, slis, setSlis } = useFetchSubscriptionLineItems(
    project.id
  );
  const { loadingMotorRebates, mRebates } = useFetchMotorRebates(project.id);
  const { loadingAssetRebates, aRebates } = useFetchAssetRebates(project.id);
  const { loadingFees, fees, setFees } = useFetchProjManagementFees(project.id);

  // The following methods return the state variable if it contains data. If not, they return
  // the array returned by the custom hooks, which are called when the component is initially loaded.
  // Subsequently the user may update the tables, which is handled by sending PUT requests to the server
  // and updating the state.
  const getMotorLineItems = () => {
    const updatedMotorLineItems = accessoriesAndMotors.mlis;
    const ret = updatedMotorLineItems.length > 0 ? updatedMotorLineItems : mlis;
    return orderBy(ret, ["site_name"]);
  };

  const getAccessories = () => {
    const updatedAccessories = accessoriesAndMotors.alis;
    const ret = updatedAccessories.length > 0 ? updatedAccessories : alis;
    return orderBy(ret, ["site_name", "sku"]);
  };

  const getMotorRebates = () => {
    const ret = motorRebates.length > 0 ? motorRebates : mRebates;
    return orderBy(ret, ["siteName", "sku"]);
  };

  const getAssetRebates = () => {
    const ret = assetRebates.length > 0 ? assetRebates : aRebates;
    return orderBy(ret, ["siteName", "sku"]);
  };

  const getSubscriptions = () => {
    return orderBy(slis, ["siteName", "sku"]);
  };

  const updateMotorLineItem = (item: RowUpdate) => {
    const body = createRequestBody(
      [
        "override_price",
        "tax_rate_percent",
        "labor_rate_hr",
        "labor_hours_per_unit",
        "misc_expense",
      ],
      item
    );
    const callback = (data: Item) => {
      let lineItems = getMotorLineItems();
      lineItems = updateTaxRate(
        lineItems,
        data,
        project.motorDiscount,
        project.motorMaterialMarkup
      );
      let accessories = getAccessories();
      accessories = updateTaxRate(
        accessories,
        data,
        project.accessoryDiscount,
        project.accessoryMaterialMarkup,
        false
      );
      const slis = updateTaxRate(
        getSubscriptions(),
        data,
        project.accessoryDiscount,
        project.accessoryMaterialMarkup,
        false
      );
      setSlis(slis);
      setAccessoriesAndMotors({
        alis: accessories,
        mlis: lineItems,
      });
    };

    updateLineItem(item, JSON.stringify(body), "motor_line_items", callback);
  };

  const updateMotorRebate = (item: RowUpdate) => {
    const body = createRequestBody(
      [
        "per_kwh_saved_rebate",
        "per_peak_kw_reduction_rebate",
        "per_motor_power_unit_rebate",
        "misc_rebate",
        "percent_of_max_price",
      ],
      item
    );
    const callback = (data: Item) => {
      let lineItems = getMotorRebates();
      const updatedItems = lineItems.map((l: MotorRebate) => {
        if (l.id === data.id) {
          let r = new MotorRebate();
          r.setMotorRebate(data as MotorRebateRow);
          return r;
        } else {
          return l;
        }
      }) as MotorRebate[];

      setMotorRebates(updatedItems);
    };
    updateLineItem(item, JSON.stringify(body), "motor_rebates", callback);
  };

  const updateAssetRebate = (item: RowUpdate) => {
    const body = createRequestBody(
      [
        "per_ton_rebate",
        "per_unit_rebate",
        "misc_rebate",
        "percent_of_max_price",
      ],
      item
    );
    const callback = (data: Item) => {
      let lineItems = getAssetRebates();
      const updatedItems = lineItems.map((l: AssetRebate) => {
        if (l.id === data.id) {
          let a = new AssetRebate();
          a.setAssetRebate(data as AssetRebateRow);
          return a;
        } else {
          return l;
        }
      }) as AssetRebate[];
      setAssetRebates(updatedItems);
    };
    updateLineItem(item, JSON.stringify(body), "asset_rebates", callback);
  };

  const createRequestBody = (fieldNames: string[], item: RowUpdate) => {
    const fields = item.changes;
    let obj = {};
    fieldNames.forEach((field) => {
      Object.assign(
        obj,
        fields.find((f) => f.fieldName === field) && {
          [field]: fields.find((f) => f.fieldName === field)?.fieldValue,
        }
      );
    });
    return obj;
  };

  const updateAccessoryLineItem = (item: RowUpdate) => {
    const body = createRequestBody(
      [
        "override_price",
        "tax_rate_percent",
        "labor_hours_per_unit",
        "labor_rate_hr",
        "quantity",
      ],
      item
    );
    const callback = (data: Item) => {
      let lineItems = getAccessories();
      lineItems = updateTaxRate(
        lineItems,
        data,
        project.accessoryDiscount,
        project.accessoryMaterialMarkup
      );
      let mlis = getMotorLineItems();
      mlis = updateTaxRate(
        mlis,
        data,
        project.motorDiscount,
        project.motorMaterialMarkup,
        false
      );
      const slis = updateTaxRate(
        getSubscriptions(),
        data,
        project.accessoryDiscount,
        project.accessoryMaterialMarkup,
        false
      );
      setSlis(slis);
      setAccessoriesAndMotors({ alis: lineItems, mlis: mlis });
    };
    updateLineItem(
      item,
      JSON.stringify(body),
      "accessory_line_items",
      callback
    );
  };

  function updateTaxRate<
    T extends AccessoryLineItemRow | MotorLineItemRow | SubscriptionLineItemRow
  >(
    lineItems: T[],
    data: Item,
    discount: number,
    markup: number,
    compareId: boolean = true
  ) {
    return lineItems.map((l: T) => {
      if (compareId && l.id === data.id) return data;
      else if (l.site_identifier === data.site_identifier) {
        const lineItem = data as T;
        return Object.assign(l, {
          tax_rate_percent: lineItem.tax_rate_percent,
          total_item_cost: calculateTotalMaterialCost(
            l,
            lineItem.tax_rate_percent,
            discount,
            markup
          ),
        });
      } else {
        return l;
      }
    }) as T[];
  }

  const updateLineItem = (
    item: RowUpdate,
    body: string,
    endpoint: string,
    successCallback: (data: Item) => void
  ) => {
    postRequest<{ data: any }>(
      "PUT",
      `${window.APP_CONFIG.API_HOST}/${endpoint}/${item.lineItemId}`,
      auth,
      body,
      ""
    )
      .then(({ data }) => {
        successCallback(data);
      })
      .catch((error) => {
        enqueueSnackbar(`Error updating item ${item.lineItemId}: ${error}`, {
          variant: "error",
        });
      });
  };

  const updateProject = (
    priceAdjustments: PriceAdjustments,
    confirmUpdate: () => void
  ) => {
    if (!priceAdjustments) {
      return;
    }
    postRequest<{ data: { project: ProjectProps } }>(
      "PUT",
      `${window.APP_CONFIG.API_HOST}/projects/${project.id}`,
      auth,
      JSON.stringify(keysToSnake(priceAdjustments))
    )
      .then(({ data: { project } }) => {
        const p = new Project();
        p.setProjectProps(project);
        p.editable = true;
        onDiscountUpdate(p);
        enqueueSnackbar(`Successfully updated pricing.`, {
          variant: "success",
        });
        confirmUpdate();
      })
      .catch((error: any) => {
        enqueueSnackbar(`There was an error updating pricing: ${error}`, {
          variant: "error",
        });
      });
  };

  const updateSitePMPricing = (item: RowUpdate) => {
    const body = createRequestBody(
      ["pm_hours", "pm_hourly_rate", "survey_costs"],
      item
    );
    const callback = ({ site }: { site: ProjectManagementFees }) => {
      site.pm_fee =
        site.pm_hours * site.pm_hourly_rate * (1 - project.pmDiscount / 100);
      const updatedFees = fees.map((fee) =>
        fee.id === site.id ? { ...fee, ...site } : fee
      );
      setFees(updatedFees);
    };

    postRequest<{ data: any }>(
      "PATCH",
      `${window.APP_CONFIG.API_HOST}/sites/${item.lineItemId}/pm`,
      auth,
      JSON.stringify(body),
      "application/json",
      true
    )
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => {
        enqueueSnackbar(
          `Error updating PM for site ${item.lineItemId}: ${error}`,
          {
            variant: "error",
          }
        );
      });
  };

  const useStyles = makeStyles((theme: Theme) => ({
    card: {
      background: theme.palette.common.white,
      width: "260px",
      height: "300px",
      margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      "& h6": {
        margin: `${theme.spacing(1)}px ${theme.spacing(
          6
        )}px 0px ${theme.spacing(6)}px`,
        textAlign: "center",
        fontWeight: "bold",
      },
      "& div": {
        margin: `${0}px ${theme.spacing(6)}px 0px ${theme.spacing(6)}px`,
        textAlign: "center",
      },
    },
    selected: {
      background: "#E8E8E8",
    },
  }));
  const styles = useStyles();
  type SummaryCardProps = {
    activeCard: string;
  };

  const PM_TAB_NAME = "PM + Survey Costs";

  const SummaryCard = ({ activeCard }: SummaryCardProps) => {
    return (
      <Box
        mt={6}
        mb={6}
        display="flex"
        justifyContent="center"
        style={{
          filter: "drop-shadow(-1px 3px 3px rgba(50, 50, 0, 0.5))",
        }}
      >
        <Box
          className={`${styles.card} ${
            activeCard === "Motors" ? styles.selected : ""
          }`}
        >
          <Typography variant="h6">Motors</Typography>
          <Divider />
          <Typography
            variant="subtitle1"
            title="Motor material price with discount, markup, and tax"
          >
            Material
          </Typography>
          <Box>{project.formatMoney(totalMotorPrice())}</Box>
          <Typography
            variant="subtitle1"
            title="Motor labor price with discount, markup, and tax"
          >
            Labor
          </Typography>
          <Box>{project.formatMoney(totalMotorLabor())}</Box>
          <Typography variant="subtitle1" title="Material + Labor markup">
            Markup
          </Typography>
          <Box pb={4}>{project.formatMoney(totalMotorMarkup())}</Box>
        </Box>
        <Box
          className={`${styles.card} ${
            activeCard === "Accessories" ? styles.selected : ""
          }`}
        >
          <Typography variant="h6">Accessories</Typography>
          <Divider />
          <Typography
            variant="subtitle1"
            title="Accessories material price with discount, markup, and tax"
          >
            Material
          </Typography>
          <Box>{project.formatMoney(totalAccessoryPrice())}</Box>
          <Typography
            variant="subtitle1"
            title="Accessories labor price with discount, markup, and tax"
          >
            Labor
          </Typography>
          <Box>{project.formatMoney(totalAccessoryLabor())}</Box>
          <Typography variant="subtitle1" title="Material + Labor markup">
            Markup
          </Typography>
          <Box pb={4}>{project.formatMoney(totalAccessoryMarkup())}</Box>
        </Box>
        <Box
          className={`${styles.card} ${
            activeCard === PM_TAB_NAME ? styles.selected : ""
          }`}
        >
          <Typography variant="h6">{PM_TAB_NAME}</Typography>
          <Divider />
          <Typography variant="subtitle1">Total</Typography>
          <Box pb={4}>{project.formatMoney(totalProjManagementFees())}</Box>
        </Box>
        <Box
          className={`${styles.card} ${
            activeCard === "Rebates" ? styles.selected : ""
          }`}
        >
          <Typography variant="h6">Rebates</Typography>
          <Divider />
          <Typography variant="subtitle1">Total</Typography>
          <Box>{project.formatMoney(totalRebate)}</Box>
        </Box>
        <Box className={styles.card}>
          <Typography variant="h6">Total</Typography>
          <Divider />
          <Typography
            variant="subtitle1"
            title="Total Price incl. discount, markup, tax, and rebates"
          >
            Total Price
          </Typography>
          <Box>{project.formatMoney(priceAfterRebate(projectPrice))}</Box>
          <Typography variant="subtitle1">Payback</Typography>
          <Box pb={4}>
            {formatNumber(
              priceAfterRebate(projectPrice) / metadata.utility_cost_savings,
              2
            )}{" "}
            Years
          </Box>
        </Box>
      </Box>
    );
  };
  if (
    loadingMlis ||
    loadingAlis ||
    loadingSlis ||
    loadingMotorRebates ||
    loadingAssetRebates ||
    loadingFees
  ) {
    return <div>Loading...</div>;
  }

  const projectPrice = priceBeforeRebate();
  const rebatesBySite = calculateRebates();
  const totalRebate = calculateTotalRebate(rebatesBySite);

  return (
    <div>
      <CustomTabs
        tabNames={["Motors", "Accessories", PM_TAB_NAME, "Rebates"]}
        tabPanels={[
          <Box>
            <SummaryCard activeCard="Motors" />
            <MotorPricing
              motorLineItems={getMotorLineItems()}
              updateMotorLineItem={updateMotorLineItem}
              updateProject={updateProject}
              readOnly={readOnly}
              project={project}
            />
          </Box>,
          <Box>
            <SummaryCard activeCard="Accessories" />
            <Accessories
              accessories={getAccessories()}
              updateAccessoryLineItem={updateAccessoryLineItem}
              updateProject={updateProject}
              accessoryDiscount={accessoryDiscount}
              project={project}
              updateAccessories={(ali: AccessoryLineItemRow) =>
                setAccessoriesAndMotors({
                  alis: [...getAccessories(), ali],
                  mlis: mlis,
                })
              }
              setExtendedWarrantyOption={setExtendedWarrantyOption}
              updateSubscriptionLineItems={(data: SubscriptionLineItemRow[]) =>
                setSlis(data)
              }
              accessorySkus={metadata.accessorySkus}
              readOnly={readOnly}
              subscriptionLineItems={getSubscriptions()}
            />
          </Box>,
          <Box>
            <SummaryCard activeCard={PM_TAB_NAME} />
            <ProjectManagement
              fees={fees}
              updateProject={updateProject}
              pmDiscount={pmDiscount}
              project={project}
              updateSitePMPricing={updateSitePMPricing}
            />
          </Box>,
          <Box>
            <SummaryCard activeCard="Rebates" />
            <Rebates
              motorRebates={getMotorRebates()}
              assetRebates={getAssetRebates()}
              updateMotorRebate={updateMotorRebate}
              updateAssetRebate={updateAssetRebate}
              rebatesBySite={rebatesBySite}
              readOnly={readOnly}
              project={project}
            />
          </Box>,
        ]}
      />
    </div>
  );
}
