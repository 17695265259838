import * as React from "react";

import { Project } from "models/project";

import { BlobProvider } from "@react-pdf/renderer";
import { SummaryPDF } from "modules/project-stepper/components/SummaryPDF";
import { useFetchSites } from "modules/hooks/useFetchSites";
import { DetailedSummary } from "types/DetailedSummary";
import { ExecutiveSummary } from "types/ExecutiveSummary";
import { postRequest } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import { PDFButton } from "./PDFButton";

type ReportButtonProps = {
  project: Project;
  summary: ExecutiveSummary;
  detailedSummary: DetailedSummary[];
};

export function ReportButton({
  project,
  summary,
  detailedSummary,
}: ReportButtonProps) {
  const { loading: loadingSites, allSites } = useFetchSites(project.id);
  const auth = useAuth();
  const handleClick = () => {
    const body = { status: "SUMMARY_EXPORTED" };
    postRequest<{ status: string }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/project_statuses?project_id=${project.id}`,
      auth,
      JSON.stringify(body)
    );
  };

  return (
    <BlobProvider
      document={
        <SummaryPDF
          project={project}
          summary={summary}
          detailedSummary={detailedSummary.map((summaryItem) => ({
            ...summaryItem,
            ...allSites.find((s) => s.identifier === summaryItem.identifier),
          }))}
        />
      }
    >
      {({ url }) => (
        <PDFButton url={url} disabled={loadingSites} onClick={handleClick} />
      )}
    </BlobProvider>
  );
}
