import { DetailedSummary } from "types/DetailedSummary";
import { EquipmentDataRow } from "types/EquipmentDataRow";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SnakeToCamelCase, keysToCamel } from "utils/helpers";

export type MotorSummary = {
  [K in keyof EquipmentDataRow as SnakeToCamelCase<K>]: EquipmentDataRow[K];
};

export type SiteSummary = {
  [K in keyof DetailedSummary as SnakeToCamelCase<K>]: DetailedSummary[K];
};

export const createSiteSummary = (
  siteSummaryData: DetailedSummary
): SiteSummary => keysToCamel(siteSummaryData);
