import React from "react";
import { getResource } from "utils/helpers";
import { ProjectShare } from "types/ProjectShare";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { User } from "types/User";

export const useFetchProjectShares = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingProjectShares, setLoading] = React.useState(true);
  const [projectSharesAndUsers, setProjectShares] = React.useState({
    shared_with_user: [] as ProjectShare[],
    shared_by_user: [] as ProjectShare[],
    users: [] as User[],
  });

  React.useEffect(() => {
    const getProjectShares = () => {
      setLoading(true);
      getResource<{
        data: {
          shared_with_user: ProjectShare[];
          shared_by_user: ProjectShare[];
          users: User[];
        };
      }>(`${window.APP_CONFIG.API_HOST}/project_shares`, auth)
        .then(({ data }) => {
          setLoading(false);
          setProjectShares({
            shared_with_user: data.shared_with_user,
            shared_by_user: data.shared_by_user,
            users: data.users,
          });
        })
        .catch((error) => {
          enqueueSnackbar(`There was an error retrieving users: ${error}`, {
            variant: "error",
          });
          setLoading(false);
        });
    };
    getProjectShares();
  }, [enqueueSnackbar, auth]);
  return {
    loadingProjectShares,
    projectSharesAndUsers,
  };
};
