import React from "react";
import { getResource } from "utils/helpers";
import { ProjectProps } from "types/ProjectProps";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { capitalize, startCase } from "lodash";

export const useFetchProjects = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [allProjects, setProjects] = React.useState<ProjectProps[]>([]);
  React.useEffect(() => {
    let unmounted = false;
    const getProjects = async () => {
      setLoading(true);
      getResource<{ data: ProjectProps[] }>(
        `${window.APP_CONFIG.API_HOST}/projects`,
        auth
      )
        .then(({ data }) => {
          if (!unmounted) {
            setLoading(false);
            const pr = data.map((p: ProjectProps) => {
              return {
                ...p,
                status: formatStatus(p.status),
                created_at: new Date(p.created_at),
                updated_at: new Date(p.updated_at),
              };
            });
            setProjects(pr);
          }
        })
        .catch((error) => {
          if (!unmounted) {
            enqueueSnackbar(
              `There was an error retrieving projects. ${error}`,
              {
                variant: "error",
              }
            );
            setLoading(false);
          }
        });
    };
    getProjects();
    return () => {
      unmounted = true;
    };
  }, [enqueueSnackbar, auth]);
  return {
    loading,
    allProjects,
  };
};

export const formatStatus = (status: string) => {
  return startCase(capitalize(status));
};
