import React from "react";
import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import Typography from "@material-ui/core/Typography";
import { DialogTitle } from "./DialogTitle";
import { tableStyles5 } from "modules/common/components/table/tableStyles5";

type DefinitionsModalProps = {
  variant: string;
};

interface Content {
  modalTitle: string;
  rows: { column: string; definition: string }[];
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  root: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px ${theme.spacing(
      8
    )}px`,
    "& .MuiGrid-item": {
      width: "100%",
      borderBottom: "1px solid #EEEDE8",
      padding: `${theme.spacing(3)}px 0px`,
    },
  },
}));

export function DefinitionsModal({ variant }: DefinitionsModalProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const classes = tableStyles5(theme);
  const getContent = () => {
    let content: Content = { modalTitle: "", rows: [] };
    if (variant === "SiteData") {
      content.modalTitle = "Site Column Definitions";
      content.rows = [
        { column: "Site Name", definition: "Unique site name." },
        {
          column: "Site ID",
          definition:
            "Secondary identifier to 'Site Name'. Typically used to as the customer's store number.",
        },
        { column: "City", definition: "" },
        { column: "State/Province/Region", definition: "" },
        { column: "Zip/Postal Code", definition: "" },
        { column: "Country", definition: "" },
        {
          column: "Climate",
          definition:
            "See the list of available climates in the 'Climate Maps' tab. This selects the TMY Weather file for the 8760 model.",
        },
        {
          column: "CO2e g/kWh",
          definition:
            "CO2e (carbon dioxide equivalent) emissions in grams per kWh of the electrical grid that servers the site",
        },
        { column: "Utility Rate", definition: "Sets the price per kWh." },
        {
          column: "Building Type",
          definition:
            "Establishes the heating and cooling balance points used by the 8760 model.",
        },
        {
          column: "$ per Motor Power (HP or KW)",
          definition:
            "Utility incentive rate based on each hp or kw power that is retrofitted",
        },
        {
          column: "$ per kWh Saved",
          definition:
            "Utility incentive rate based on each kWh of energy saved",
        },
        {
          column: "$ per peak kW Reduction ",
          definition:
            "Utility incentive rate based on each kW of demand reduced",
        },
        {
          column: "$ per Ton",
          definition:
            "Utility incentive rate based on the rated tonnage of the retrofitted unit",
        },
        {
          column: "$ per Unit",
          definition: "Utility incentive rate based on the qty of motors",
        },
        {
          column: "Max % of Price",
          definition:
            "Maximum rebate amount for a given site as a percentage of the total cost",
        },
        {
          column: "$ per Hour Labor Rate",
          definition:
            "Hourly rate of labor for the site. If left blank, it will default to $160.",
        },
        {
          column: "Peak Demand Charge ($/kW)",
          definition: "Sets the demand price per kW",
        },
      ];
    } else if (variant === "EquipmentData") {
      content.modalTitle = "Equipment Column Definitions";
      content.rows = [
        {
          column: "Site ID",
          definition:
            "Secondary identifier to 'Site Name'. Typically used to as the customer's store number. This is the common link between the 'Equipment Template' and the 'Site Template (1)'.",
        },
        { column: "Unit Name", definition: "Unique name for each RTU." },
        { column: "Manufacturer", definition: "" },
        { column: "Model Number", definition: "" },
        {
          column: "Fan Type",
          definition:
            "Describes the fan. This can be 'Supply', 'Return' or 'Exhaust'.",
        },
        { column: "Drive Type", definition: "Can be 'Belt' or 'Direct'." },
        {
          column: "Frame Standard",
          definition: "Select either NEMA or IEC",
        },
        {
          column: "Motor Power (HP or kW)",
          definition: "Motor Power (HP or kW) of the existing motor.",
        },
        {
          column: "New Motor Power (HP or kW)",
          definition:
            "Motor Power (HP or kW) of the new motor.  Use this if the new motor will be smaller than the existing motor.",
        },
        {
          column: "Frame Size (NEMA or IEC)",
          definition: "NEMA or IEC frame size of the existing motor.",
        },
        {
          column: "Shaft Diameter (in or mm)",
          definition:
            "Shaft diameter of the existing motor in inches or millimeters.",
        },
        {
          column: "Motor RPM",
          definition: "Nameplate rated RPM of the existing motor.",
        },
        {
          column: "Voltage",
          definition:
            "Line voltage used at the site. This is typically 208 V or 460 V. In Canada its typically 575 V.",
        },
        {
          column: "Hz",
          definition:
            "Line power frequency. In the US this is 60 Hz. In other countries, it can be 50 Hz.",
        },
        {
          column: "Tonnage",
          definition: "RTU nameplate cooling capacity in tons.",
        },
        {
          column: "VFD",
          definition:
            "'Yes' means there is a VFD on the existing motor. 'No' means there is no VFD.",
        },
        {
          column: "Motor Series",
          definition:
            "Turntide series of motor. This will be either a 'V-series' or a 'Q-series'.",
        },
        {
          column: "Efficiency Type",
          definition:
            "NEMA nameplate efficiency of the existing motor. This will be either 'Standard' or 'Premium'.",
        },
        {
          column: "Loading %",
          definition:
            "Actual loading of the existing motor divided by the motor nameplate horsepower. For RTU supply fans, typically default to 60%. This will be updated based on the site XOI audit.",
        },
        {
          column: "Fan Control",
          definition:
            "'ON' means the fan runs during the occupied period to provide ventilation to the space.  'AUTO' means the fan runs only during heating & cooling operation so there is no ventilation mode.",
        },
        {
          column: "Wk Base Start",
          definition:
            "Baseline motor start time for during the week, Monday to Friday, when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Wk Base End",
          definition:
            "Baseline motor end time for during the week, Monday to Friday, when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sat Base Start",
          definition:
            "Baseline motor start time for Saturday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sat Base End",
          definition:
            "Baseline motor end time for Saturday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sun Base Start",
          definition:
            "Baseline motor start time for Sunday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sun Base End",
          definition:
            "Baseline motor end time for Sunday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Wk Turntide Start",
          definition:
            "Turntide motor start time for during the week, Monday to Friday, when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Wk Turntide End",
          definition:
            "Turntide motor end time for during the week, Monday to Friday, when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sat Turntide Start",
          definition:
            "Turntide motor start time for Saturday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sat Turntide End",
          definition:
            "Turntide motor end time for Saturday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sun Turntide Start",
          definition:
            "Turntide motor start time for Sunday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Sun Turntide End",
          definition:
            "Turntide motor end time for Sunday when the HVAC system controls to the occupied setpoints.",
        },
        {
          column: "Vacant Months",
          definition:
            "Specify the months of the year that there is no HVAC operation. This is used mainly for schools. Use 1 to 12 for the month number, seperated by a comma. Example '6, 7' means closed June & July.",
        },
        {
          column: "Nighttime Setback",
          definition:
            "'Yes' means the 8760 model will calculate fan operation at night when the HVAC must turn on to meet nighttime setpoints. 'No' means no fan operation at night.",
        },
        {
          column: "Base % Speed mode 1 (heat-2)",
          definition:
            "Fan speed as a percentage of full speed for current motor during heat-2 mode",
        },
        {
          column: "Base % Speed mode 2 (heat-1)",
          definition:
            "Fan speed as a percentage of full speed for current motor during heat-1 mode",
        },
        {
          column: "Base % Speed mode 3 (vent)",
          definition:
            "Fan speed as a percentage of full speed for current motor during vent mode",
        },
        {
          column: "Base % Speed mode 4 (cool-1)",
          definition:
            "Fan speed as a percentage of full speed for current motor during cool-1 mode",
        },
        {
          column: "Base % Speed mode 5 (cool-2)",
          definition:
            "Fan speed as a percentage of full speed for current motor during cool-2 mode",
        },
        {
          column: "Turntide % Speed mode 1 (heat-2)",
          definition:
            "Fan speed as a percentage of full speed for Turntide motor during heat-2 mode. Recommended speed is 90%.",
        },
        {
          column: "Turntide % Speed mode 2 (heat-1)",
          definition:
            "Fan speed as a percentage of full speed for Turntide motor during heat-1 mode. Recommended speed is 90%.",
        },
        {
          column: "Turntide % Speed mode 3 (vent)",
          definition:
            "Fan speed as a percentage of full speed for Turntide motor during vent mode. Recommended speed is 40%.",
        },
        {
          column: "Turntide % Speed mode 4 (cool-1)",
          definition:
            "Fan speed as a percentage of full speed for Turntide motor during cool-1 mode. Recommended speed is 75%.",
        },
        {
          column: "Turntide % Speed mode 5 (cool-2)",
          definition:
            "Fan speed as a percentage of full speed for Turntide motor during vent mode. Recommended speed is 90%.",
        },
        {
          column: "Base % Time mode 1 (heat-2)",
          definition:
            "Percentage of annual runhours that motor spends in heat-2 mode",
        },
        {
          column: "Base % Time mode 2 (heat-1)",
          definition:
            "Percentage of annual runhours that motor spends in heat-1 mode",
        },
        {
          column: "Base % Time mode 3 (vent)",
          definition:
            "Percentage of annual runhours that motor spends in vent mode",
        },
        {
          column: "Base % Time mode 4 (cool-1)",
          definition:
            "Percentage of annual runhours that motor spends in cool-1 mode",
        },
        {
          column: "Base % Time mode 5 (cool-2)",
          definition:
            "Percentage of annual runhours that motor spends in cool-2 mode",
        },
        {
          column: "Turntide % Time mode 1 (heat-2)",
          definition:
            "Percentage of annual runhours that motor spends in heat-2 mode",
        },
        {
          column: "Turntide % Time mode 2 (heat-1)",
          definition:
            "Percentage of annual runhours that motor spends in heat-1 mode",
        },
        {
          column: "Turntide % Time mode 3 (vent)",
          definition:
            "Percentage of annual runhours that motor spends in vent mode",
        },
        {
          column: "Turntide % Time mode 4 (cool-1)",
          definition:
            "Percentage of annual runhours that motor spends in cool-1 mode",
        },
        {
          column: "Turntide % Time mode 5 (cool-2)",
          definition:
            "Percentage of annual runhours that motor spends in cool-2 mode",
        },
      ];
    }
    return content;
  };
  const content = getContent();
  return (
    <div>
      <Button sx={classes.button} variant="outlined" onClick={handleClickOpen}>
        Column Definitions
      </Button>
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle onClose={handleClose}>{content.modalTitle}</DialogTitle>
        <StyledDialogContent dividers>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography color="textSecondary" variant="overline">
                Column
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="textSecondary" variant="overline">
                Definition
              </Typography>
            </Grid>
            {content.rows.map((r) => (
              <React.Fragment key={`${variant}-${r.column}`}>
                <Grid item xs={4}>
                  <Typography variant="body1">{r.column}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{r.definition}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </StyledDialogContent>
      </Dialog>
    </div>
  );
}
