import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StyledTextField } from "./StyledTextField";

type DiscountProps = {
  defaultValue: number;
  setDiscount: (discount: string) => void;
  discountUpdated?: boolean;
};

function DiscountField({
  defaultValue,
  setDiscount,
  discountUpdated,
}: DiscountProps) {
  const [inputValue, setInputValue] = React.useState(null);

  const handleTextFieldChange = (e: any) => {
    setInputValue(e.target.value);
    setDiscount(e.target.value);
  };
  const color = discountUpdated ? "secondary" : undefined;
  const useStyles = makeStyles((theme) => ({
    updatedText: {
      color: "red",
      fontSize: "1.25rem",
    },
    root: {
      fontSize: "1.25rem",
    },
  }));
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ justifyContent: "flex-end" }}
    >
      <Typography
        color={color}
        variant="h6"
        style={{
          fontFamily: "Barlow",
        }}
      >
        % Discount: &nbsp;{" "}
      </Typography>
      <StyledTextField
        type="number"
        InputProps={{
          disableUnderline: true,
          className: discountUpdated ? classes.updatedText : classes.root,
        }}
        style={{
          width: 50,
          marginTop: 3,
          backgroundColor: "#FFFFFF",
          fontFamily: "Barlow",
        }}
        onChange={handleTextFieldChange}
        value={inputValue !== null ? inputValue : defaultValue}
      />
    </Box>
  );
}

export default DiscountField;
