import * as React from "react";
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridFilterMenuItem,
  SortGridMenuItems,
} from "@mui/x-data-grid";

export function CustomColumnMenuComponent(props: GridColumnMenuProps) {
  const { hideMenu, currentColumn, ...other } = props;
  return (
    <GridColumnMenuContainer
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      onKeyDown={() => {}}
      {...other}
    >
      <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
      <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
    </GridColumnMenuContainer>
  );
}
