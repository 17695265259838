import React from "react";
import {
  Font,
  Image,
  Line,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import { DetailedSummary } from "types/DetailedSummary";
import raw from "raw.macro";
import { Project } from "models/project";
import { SvgComponent } from "./SvgComponent";
import { TURNTIDE_ORGANIZATION_ID } from "modules/hooks/useFetchUserOrgs";
import { ExecutiveSummary } from "types/ExecutiveSummary";
import { formatNumber } from "utils/helpers";

const headerSvg = raw("../../../logo.svg");
const savingsSvg = raw("../icons/Savings-report.svg");
const co2Svg = raw("../icons/CO2-report.svg");
const rebateSvg = raw("../icons/Rebate.svg");
const paybackSvg = raw("../icons/Payback.svg");
const lightbulbSvg = raw("../icons/bulb.svg");
const leafSvg = raw("../icons/leaf.svg");

// Google's Webfonts are only hosted as WOFF2, which is incompatible with react-pdf.
// We thus have to self-host the downloaded TTFs.
Font.register({
  family: "Barlow",
  fonts: [
    {
      src: "/fonts/Barlow-Light.ttf",
      fontWeight: 300,
    },
    {
      src: "/fonts/Barlow-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Barlow-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "/fonts/Barlow-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/fonts/Barlow-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

export interface ReportSummary extends DetailedSummary {
  streetAddr?: string;
  city?: string;
  state?: string;
  country?: string;
}

// Create styles

// In Figma, the Title font size is 40; here, we'll define all sizes relative to the title,
// then adjust the title size until it all looks right in the PDF
const DESIGN_TITLE_SIZE = 40;
const titleSize = 15;
const subtitleSize = (titleSize * 20) / DESIGN_TITLE_SIZE;
export const tableHeaderSize = (titleSize * 14) / DESIGN_TITLE_SIZE;
export const kpiLabelSize = (titleSize * 14.34) / DESIGN_TITLE_SIZE;
export const kpiValueSize = (titleSize * 28.68) / DESIGN_TITLE_SIZE;
export const detailValueSize = (titleSize * 18) / DESIGN_TITLE_SIZE;
const CO2_TONS_PER_LED = 0.0264;
const CO2_TONS_PER_TREE = 0.06;
const ID_COLUMN_WIDTH = 2.25;
export const MOTOR_QTY_COLUMN_WIDTH = 3 - ID_COLUMN_WIDTH;
const LOGO_WIDTH = 79.8252;
const LOGO_HEIGHT = 13.7528;

export const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    fontFamily: "Barlow",
    alignItems: "stretch",
    padding: 10,
    marginBottom: 15,
  },
  header: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: "row",
    margin: 20,
    display: "flex",
    justifyContent: "space-between",
    fontSize: subtitleSize,
  },
  title: {
    fontSize: titleSize,
    fontWeight: 600,
    textAlign: "center",
  },
  subtitle: {
    fontSize: subtitleSize,
    fontWeight: 700,
    marginLeft: 5,
  },

  execSummarySection: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 10,
    marginBottom: 20,
  },
  execSummaryCell: {
    flexDirection: "column",
    fontSize: titleSize,
  },
  execSummaryLabel: {
    fontSize: subtitleSize,
    fontWeight: 700,
    color: "#15507B",
  },
  execSummaryValue: {
    fontSize: titleSize,
    fontWeight: 300,
    color: "black",
  },
  execSummaryRowHeader: {
    flexGrow: 0.25,
  },

  kpiRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "nowrap",
    alignItems: "flex-start",
  },
  kpiCell: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  kpiLabel: {
    fontSize: kpiLabelSize,
    fontWeight: 500,
    color: "#6B6C6F",
  },
  kpiValue: {
    fontSize: kpiValueSize,
    fontWeight: 400,
    color: "#000000",
  },

  detailedSummarySection: { margin: 20 },
  detailedSummaryTable: {
    fontWeight: 500,
  },
  detailedSummaryRow: {
    justifyContent: "space-evenly",
  },
  detailedSummaryCell: {
    textAlign: "left",
  },

  table: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },

  th: {
    flexWrap: "nowrap",
    fontWeight: 500,
    textAlign: "left",
  },

  tr: {
    alignItems: "stretch",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
  },
  trh: { textAlign: "left", flexGrow: ID_COLUMN_WIDTH },

  td: {
    color: "#2F2F2F",
    flexBasis: 0,
    flexGrow: 1,
    flexWrap: "nowrap",
    minWidth: 0,
    overflow: "hidden",
    padding: 5,
    whiteSpace: "nowrap",
  },

  // define complete styles for the table header to workaround bug in react-pdf dynamic rendering
  detailedSummaryHeaderRow: {
    alignItems: "stretch",
    flexDirection: "row",
    fontSize: tableHeaderSize,
    textAlign: "left",
    height: 23,
  },

  detailedSummaryHeaderCell: {
    color: "#000000",
    flexBasis: 0,
    flexGrow: 1,
    flexWrap: "nowrap",
    fontFamily: "Barlow",
    fontSize: tableHeaderSize,
    fontWeight: 500,
    minWidth: 0,
    padding: 5,
    textAlign: "left",
    whiteSpace: "nowrap",
  },

  siteIdHeaderCell: {
    color: "#000000",
    flexBasis: 0,
    flexGrow: ID_COLUMN_WIDTH,
    flexWrap: "nowrap",
    fontFamily: "Barlow",
    fontSize: tableHeaderSize,
    fontWeight: 500,
    minWidth: 0,
    padding: 5,
    textAlign: "left",
    whiteSpace: "nowrap",
  },

  siteIdHeaderCellFixed: {
    color: "#000000",
    flexBasis: 0,
    flexGrow: 1.99,
    flexWrap: "nowrap",
    fontFamily: "Barlow",
    fontSize: tableHeaderSize,
    fontWeight: 500,
    minWidth: 0,
    padding: 5,
    textAlign: "left",
    whiteSpace: "nowrap",
  },

  motorQuantityHeaderCell: {
    color: "#000000",
    flexBasis: 0,
    flexGrow: MOTOR_QTY_COLUMN_WIDTH,
    fontFamily: "Barlow",
    flexWrap: "nowrap",
    fontSize: tableHeaderSize,
    fontWeight: 500,
    minWidth: 0,
    padding: 5,
    whiteSpace: "nowrap",
  },

  headerImages: {
    flexDirection: "row",
    height: LOGO_HEIGHT,
  },

  siteKpiCell: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

export const TableRowLine = () => (
  <Svg
    height="1"
    width="100%"
    style={{ marginLeft: 5, marginTop: 2, marginBottom: 2 }}
  >
    <Line x1="0" y1="0" x2="545" y2="0" strokeWidth={1} stroke="#A3A3A3" />
  </Svg>
);

// Hack due to what appears to be a react-pdf layout bug
export interface TableHeaderProps {
  siteIdStyle: any;
}

export type KpiSectionProps = {
  project: Project;
  summary: ExecutiveSummary;
};

export const KpiSection = ({ project, summary }: KpiSectionProps) => (
  <View style={[styles.table, styles.execSummarySection]}>
    <View style={[styles.tr, styles.kpiRow]}>
      <View style={[styles.td, styles.kpiCell, styles.execSummaryRowHeader]}>
        <SvgComponent
          svgXml={savingsSvg}
          width={41.746031746031746}
          height={25}
          viewBox="-10 0 80 63"
        />
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>PRICE AFTER REBATES</Text>
        <Text style={styles.execSummaryValue}>
          {project.formatMoney(summary.total_price - summary.total_rebates)}
        </Text>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>ANNUAL UTILITY SAVINGS</Text>
        <Text style={styles.execSummaryValue}>
          {project.formatMoney(summary.utility_cost_savings)}
        </Text>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>SIMPLE PAYBACK</Text>
        <Text style={styles.execSummaryValue}>
          {formatNumber(summary.simple_payback)} years
        </Text>
      </View>
    </View>
    <View style={[styles.tr, styles.kpiRow]}>
      <View style={[styles.td, styles.kpiCell, styles.execSummaryRowHeader]}>
        <SvgComponent svgXml={co2Svg} width={41} height={25} />
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>ANNUAL CO2E REDUCTION</Text>
        <Text style={styles.execSummaryValue}>
          {formatNumber(summary.co_reduction)}
          {" tons"}
        </Text>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>LED RETROFIT EQUIVALENT</Text>
        <Text style={styles.execSummaryValue}>
          {formatNumber(summary.co_reduction / CO2_TONS_PER_LED, 0)}
          {" bulbs"}
        </Text>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>NEW TREE EQUIVALENT</Text>
        <Text style={styles.execSummaryValue}>
          {formatNumber(summary.co_reduction / CO2_TONS_PER_TREE, 0)}
          {" trees"}
        </Text>
      </View>
    </View>
  </View>
);

export const SiteKpiSection = ({ project, summary }: KpiSectionProps) => (
  <View style={[styles.table, styles.execSummarySection]}>
    <View style={[styles.tr, styles.kpiRow]}>
      <View
        style={[styles.td, styles.kpiCell, styles.execSummaryRowHeader]}
      ></View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>PRICE AFTER REBATES</Text>
        <View style={styles.siteKpiCell}>
          <SvgComponent
            svgXml={rebateSvg}
            width={45}
            height={25}
            viewBox="15 0 110 77"
          />
          <Text style={styles.execSummaryValue}>
            {project.formatMoney(summary.total_price - summary.total_rebates)}
          </Text>
        </View>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>ANNUAL UTILITY SAVINGS</Text>
        <View style={styles.siteKpiCell}>
          <SvgComponent
            svgXml={savingsSvg}
            width={42}
            height={25}
            viewBox="10 0 80 63"
          />
          <Text style={styles.execSummaryValue}>
            {project.formatMoney(summary.utility_cost_savings)}
          </Text>
        </View>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>SIMPLE PAYBACK</Text>
        <View style={styles.siteKpiCell}>
          <SvgComponent
            svgXml={paybackSvg}
            width={35}
            height={25}
            viewBox="11 0 87 80"
          />
          <Text style={styles.execSummaryValue}>
            {formatNumber(summary.simple_payback)} years
          </Text>
        </View>
      </View>
    </View>
    <View style={[styles.tr, styles.kpiRow]}>
      <View
        style={[styles.td, styles.kpiCell, styles.execSummaryRowHeader]}
      ></View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>ANNUAL CO2E REDUCTION</Text>
        <View style={styles.siteKpiCell}>
          <SvgComponent
            svgXml={co2Svg}
            width={50}
            height={25}
            viewBox="10 0 102 62"
          />
          <Text style={styles.execSummaryValue}>
            {formatNumber(summary.co_reduction)}
            {" tons"}
          </Text>
        </View>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>LED RETROFIT EQUIVALENT</Text>
        <View style={styles.siteKpiCell}>
          <SvgComponent
            svgXml={lightbulbSvg}
            width={20}
            height={25}
            viewBox="50 0 301 301"
          />
          <Text style={styles.execSummaryValue}>
            {formatNumber(summary.co_reduction / CO2_TONS_PER_LED, 0)}
            {" bulbs"}
          </Text>
        </View>
      </View>
      <View style={[styles.td, styles.kpiCell]}>
        <Text style={styles.execSummaryLabel}>NEW TREE EQUIVALENT</Text>
        <View style={styles.siteKpiCell}>
          <SvgComponent
            svgXml={leafSvg}
            width={30}
            height={25}
            viewBox="32 0 301 301"
          />
          <Text style={styles.execSummaryValue}>
            {formatNumber(summary.co_reduction / CO2_TONS_PER_TREE, 0)}
            {" trees"}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

export const LogoHeader = ({ project }: { project: Project }) => (
  <View style={styles.header} fixed>
    <View style={styles.headerImages}>
      <SvgComponent
        svgXml={headerSvg}
        width={LOGO_WIDTH}
        height={LOGO_HEIGHT}
      />
      {TURNTIDE_ORGANIZATION_ID !== project.organizationId && (
        <Image
          src={`/brand-images/${project.organizationId}.png`}
          style={{
            height: LOGO_HEIGHT,
            marginLeft: 10,
            paddingLeft: 10,
            borderLeft: "1px solid #D8D8D8",
          }}
        />
      )}
    </View>
    <Text>
      Prepared on{" "}
      {new Intl.DateTimeFormat("en-US", { dateStyle: "long" }).format(
        new Date()
      )}
    </Text>
  </View>
);
