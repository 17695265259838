import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

interface TabProps {
  tabNames: string[];
  tabPanels: React.ReactElement[];
}

interface TabPanelProps {
  children: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
  },
  tab: {
    border: "solid 1px black",
    color: theme.palette.primary.main,
  },
  indicator: {
    backgroundColor: "transparent",
    color: "black",
  },
}));

export function CustomTabs({ tabNames, tabPanels }: TabProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar color="transparent" position="static" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: classes.indicator,
          }}
          centered
        >
          {tabNames.map((tab, index) => (
            <Tab
              key={tab}
              disableRipple={true}
              className={classes.tab}
              label={tab}
              {...a11yProps(index)}
              style={index < tabNames.length - 1 ? { borderRight: 0 } : {}}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabPanels &&
        tabPanels.map((tabPanel, index) => (
          <TabPanel key={`tabPanel-${index}`} value={value} index={index}>
            {tabPanel}
          </TabPanel>
        ))}
    </div>
  );
}
