import * as React from "react";
import { render } from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CssBaseline } from "@material-ui/core";
import { StyledSnackbarProvider } from "./theme/components/StyledSnackbarProvider";
import {
  AuthProvider,
  AuthService,
  Logout,
  PrivateRoute,
} from "modules/common/auth";
import Root from "modules/root/components/Root";
import { ServerContext } from "modules/common/types";
import Raven from "raven-js";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { FlagsProvider } from "flagged";

// Need to use same history for auth service to allow it to redirect to previous location after
// handling login callback, otherwise the router doesn't pick up the location change.
const history = createBrowserHistory();
const context: ServerContext = {
  auth0Domain: window.APP_CONFIG?.AUTH0_DOMAIN,
  auth0ClientId: window.APP_CONFIG?.AUTH0_CLIENT_ID,
  auth0Audience: window.APP_CONFIG?.AUTH0_AUDIENCE,
  apiHost: window.APP_CONFIG?.API_HOST,
  logoutPath: window.APP_CONFIG?.LOGOUT_PATH,

  sentryDsn: window.APP_CONFIG?.SENTRY_DSN,
  environment: window.APP_CONFIG.SENTRY_ENVIRONMENT,
  release: window.APP_CONFIG.SENTRY_RELEASE,
};

// Setup Authentication
const authService = new AuthService({
  domain: context.auth0Domain || "",
  clientID: context.auth0ClientId || "",
  audience: context.auth0Audience || "",
  redirectPath: "/oauth2/callback",
  onRedirectCallback: (location: any) => {
    if (location?.pathname) {
      history.push(location.pathname, { path: location.href });
    } else {
      const path =
        window.location.protocol + "//" + window.location.host + "/projects";
      window.history.pushState({ path: path }, "", path);
    }
  },
});

if (context.sentryDsn) {
  Raven.config(context.sentryDsn, {
    release: context.release,
    environment: context.environment,
    serverName: context.apiHost,
  }).install();
}

const App = (
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <AuthProvider authService={authService}>
        <FlagsProvider features={{ hi4ai: true }}>
          <StyledSnackbarProvider>
            <Router history={history}>
              <Switch>
                <Route path="/oauth2/logout" component={Logout} />
                <PrivateRoute component={Root} />
              </Switch>
            </Router>
          </StyledSnackbarProvider>
        </FlagsProvider>
      </AuthProvider>
    </CssBaseline>
  </ThemeProvider>
);

render(App, document.getElementById("root"));
