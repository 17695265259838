import * as React from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import { TableCellProps } from "@material-ui/core/TableCell";
import { TableProps } from "@material-ui/core/Table";
import { Typography, Theme } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import {
  withStyles,
  createStyles,
  StyleRules,
  WithStyles,
} from "@material-ui/core/styles";
import { defaults } from "lodash";

const Header = withStyles({
  root: {
    textTransform: "uppercase",
  },
})(TableHead);

const HeaderRow = withStyles({
  head: {
    height: "inherit", // the height for table rows is 46px by default, reset that
  },
})(TableRow);

const styles = () =>
  createStyles({
    warning: {
      backgroundColor: "#FFFACC",
    },
  });

interface StyledRowProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  isSkuInValidation: boolean;
}

export const StyledTableRow = withStyles(styles)((props: StyledRowProps) => {
  const { children, isSkuInValidation, classes } = props;
  return (
    <TableRow className={isSkuInValidation ? classes.warning : ""}>
      {children}
    </TableRow>
  );
});

type HeaderSpec = string | ({ value: string } & TableCellProps);
const Headers: React.FC<{ headers: HeaderSpec[] }> = ({ headers }) => (
  <>
    {headers.map((header: HeaderSpec) => {
      let args;
      if (typeof header === "string") {
        args = { value: header };
      } else {
        args = header;
      }
      const { value, ...rest } = args;
      return (
        <TableCell key={value} {...rest}>
          <LightLabel>{value}</LightLabel>
        </TableCell>
      );
    })}
  </>
);

const createTypographyVariant = (
  defaultProps: Partial<TypographyProps>,
  styles: StyleRules
) => {
  const unstyledComponent = (props: TypographyProps) => {
    props = defaults({}, props, defaultProps);
    return <Typography {...props} />;
  };
  return withStyles(styles)(unstyledComponent);
};

const LightLabel = createTypographyVariant(
  { variant: "body2" },
  createStyles(({ palette }: Theme) => ({
    root: {
      color: palette.text.disabled,
      fontWeight: 600,
    },
  }))
);

type SimpleTableProps = {
  headers: HeaderSpec[];
  emptyMessage: string;
} & TableProps;
export const SimpleTable: React.FC<SimpleTableProps> = ({
  headers,
  emptyMessage,
  children,
  ...tableProps
}) => {
  return (
    <TableContainer>
      <Table {...tableProps}>
        <Header>
          <HeaderRow>
            <Headers headers={headers} />
          </HeaderRow>
        </Header>
        <TableBody>
          {children ? (
            children
          ) : (
            <Empty message={emptyMessage} cols={headers.length} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type EmptyProps = { message: string; cols: number };
const Empty: React.FC<EmptyProps> = ({ message, cols }) => (
  <TableRow>
    <TableCell colSpan={cols} style={{ textAlign: "center" }}>
      {message}
    </TableCell>
  </TableRow>
);

export default SimpleTable;
