import * as React from "react";
import {
  FileUpload,
  FormsChangedBanner,
} from "modules/common/components/FileUpload";
import { SiteDataRow } from "types/SiteDataRow";
import { SiteData } from "models/siteData";
import { postRequest, uploadFile } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import { Error } from "types/Error";
import { UploadErrorDetail } from "modules/common/components/ErrorDetail";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { tableStyles5 } from "modules/common/components/table/tableStyles5";
import { DefinitionsModal } from "modules/common/components/dialog/DefinitionsModal";
import { useFetchSites } from "modules/hooks/useFetchSites";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useFeature } from "flagged";
import { AddSiteModal, SiteProps } from "./dialog/AddSiteModal";
import { EditSiteModal, UpdateSiteProps } from "./dialog/EditSiteModal";
import {
  DataTable,
  createColumn,
  DEFAULT_COLUMN_WIDTH,
  ValueFormatter,
  renderStringWithTooltip,
  renderClickToEditTooltip,
  percentValueFormatter,
} from "modules/common/components/table/DataTable";
import { Project } from "../../../models/project";
import {
  GridCellParams,
  GridCellValue,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import {
  CO2E_LABEL,
  CO2E_UNITS,
  PEAK_DEMAND_CHARGE_LABEL,
  TAX_RATE_LABEL,
  UTILITY_RATE_LABEL,
} from "../constants/strings";

const XlsxPopulate = require("xlsx-populate");

const useStyles = () => ({
  root: {
    background: "#ffffff",
    position: "fixed",
    height: "100%",
    width: "100%",
    zIndex: 5000,
    top: "0",
    left: "0",
    textAlign: "center",
    paddingTop: "25%",
    opacity: ".80",
  },
});

export interface SiteDataProps {
  project: Project;
  disableEquipmentDataButton: (disabled: boolean) => void;
  readOnly: boolean;
}

export function SiteDataUpload(props: SiteDataProps) {
  const [rows, setRows] = useState(new Array<SiteData>());
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [addSiteOpen, setAddSiteOpen] = useState(false);
  const [editSiteOpen, setEditSiteOpen] = useState(false);
  const [actionSite, setActionSite] = useState<SiteData>({} as SiteData);
  const theme = useTheme();
  const classes = tableStyles5(theme);
  const auth = useAuth();
  const projectId = props.project.id;
  const { enqueueSnackbar } = useSnackbar();
  const hasHi4ai = useFeature("hi4ai");

  const transformResult = (rows: SiteDataRow[]) => {
    let dataRows: SiteData[] = [];
    rows.forEach((d: SiteDataRow) => {
      const data = new SiteData();
      data.setSiteData(d);
      dataRows.push(data);
    });
    setRows(dataRows);
  };

  const onChange = (f: File | null) => {
    if (f != null) {
      const formData = new FormData();
      setUploading(true);
      formData.append("file", f);
      formData.append("filename", f.name);
      uploadFile<{ data: SiteDataRow[] }>(
        `${window.APP_CONFIG.API_HOST}/sites?project_id=${projectId}`,
        auth,
        formData
      )
        .then(({ data }) => {
          setUploading(false);
          if (data.length > 0) {
            props.disableEquipmentDataButton(false);
            setErrors([]);
            transformResult(data);
          }
        })
        .catch((e) => {
          setUploading(false);
          setErrors(e.errors || e);
        });
    }
  };
  const downloadExcel = async () => {
    const sitesData = rows.length > 0 ? rows : sites;
    const requestHeaders = {
      Authorization: `Bearer ${await auth.getToken()}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    };

    const options = {
      headers: requestHeaders,
      responseType: "blob",
    };
    fetch(`${window.APP_CONFIG.API_HOST}/site_template`, options)
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        XlsxPopulate.fromDataAsync(buffer)
          .then((workbook: any) => {
            const values = sitesData.map(({ id, ...item }) =>
              Object.values(item)
            );
            const r = workbook.sheet(0).range(`A6:T${sitesData.length + 5}`);
            r.value(values);
            return workbook.outputAsync();
          })
          .then((data: Blob | MediaSource) => {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${projectId}-sites.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((err: any) =>
            enqueueSnackbar(`Something went wrong: ${err}`, {
              variant: "error",
            })
          );
      });
  };

  const getSiteRows = () => (rows.length > 0 ? rows : sites);
  const addSite = (
    siteProps: SiteProps,
    modalClose: () => void,
    setModalErrors: (arg: Array<any>) => void
  ) => {
    postRequest<{ data: SiteDataRow[] }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/sites?project_id=${projectId}`,
      auth,
      JSON.stringify([siteProps]),
      "application/json",
      true
    )
      .then(({ data }) => {
        data.forEach((d: SiteDataRow) => {
          const siteData = new SiteData();
          siteData.setSiteData(d);
          setRows(getSiteRows().concat(siteData));
        });

        enqueueSnackbar("Successfully added site.", {
          variant: "success",
        });
        modalClose();
        setAddSiteOpen(false);
        props.disableEquipmentDataButton(false);
      })
      .catch((error) => {
        setModalErrors(error.errors || [error]);
      });
  };

  const updateSite = (
    id: string,
    siteProps: UpdateSiteProps,
    modalClose: () => void,
    setModalErrors: (arg: Array<any>) => void
  ) => {
    postRequest<{
      data: {
        site: SiteDataRow;
      };
    }>(
      "PUT",
      `${window.APP_CONFIG.API_HOST}/sites/${id}`,
      auth,
      JSON.stringify({
        co2e_g_per_kwh: siteProps.co2eGPerKwh,
        peak_demand_charge: siteProps.peakDemandCharge,
        utility_rate: siteProps.utilityRate,
        tax_rate_percent: siteProps.taxRatePercent,
      }),
      "application/json",
      true
    )
      .then(({ data }) => {
        const d = data.site as SiteDataRow;
        const newRows = getSiteRows().slice();
        const rowIndex = newRows.findIndex((row) => row.id === d.id);
        if (rowIndex > -1) {
          newRows[rowIndex].setSiteData(d);
          setRows(newRows);
        }

        enqueueSnackbar("Successfully updated site.", {
          variant: "success",
        });
        modalClose();
        setEditSiteOpen(false);
      })
      .catch((error) => {
        setModalErrors(error.errors || [error]);
      });
  };

  const styles = useStyles();

  const { loading, allSites } = useFetchSites(projectId);
  const sites = allSites && !loading ? allSites : [];
  if (sites.length > 0) {
    props.disableEquipmentDataButton(false);
  }
  if (uploading) {
    return (
      <Box sx={styles.root}>
        <CircularProgress />
      </Box>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatMoney: ValueFormatter = (
    params: GridValueFormatterParams
  ): GridCellValue => {
    if (params.value) {
      return props.project.formatMoney(Number(params.value), 4, 0);
    }
  };

  const columns = [
    createColumn(
      "name",
      "Site Name",
      "string",
      180,
      undefined,
      renderStringWithTooltip
    ),
    createColumn(
      "identifier",
      "Site ID",
      "string",
      150,
      undefined,
      renderStringWithTooltip
    ),
    createColumn(
      "streetAddr",
      "Street Address",
      "string",
      180,
      undefined,
      renderStringWithTooltip
    ),
    createColumn("city", "City", "string", 120),
    createColumn("state", "State", "string", 120),
    createColumn("zipcode", "Zip", "string", 100),
    createColumn("country", "Country", "string", 140),
    createColumn(
      "buildingType",
      "Building Type",
      "string",
      200,
      undefined,
      renderStringWithTooltip
    ),
  ];
  if (hasHi4ai) {
    columns.push(
      createColumn(
        "satelliteStatus",
        "Satellite Estimate Status",
        "string",
        200
      )
    );
  }
  columns.push(
    createColumn(
      "taxRatePercent",
      TAX_RATE_LABEL,
      "number",
      110,
      percentValueFormatter,
      renderClickToEditTooltip
    ),
    createColumn(
      "utilityRate",
      UTILITY_RATE_LABEL,
      "number",
      115,
      formatMoney,
      renderClickToEditTooltip
    ),
    createColumn(
      "peakDemandCharge",
      `${PEAK_DEMAND_CHARGE_LABEL} (${props.project.getDemandChargeUnits()})`,
      "number",
      180,
      formatMoney,
      renderClickToEditTooltip
    ),
    createColumn(
      "perMotorPowerUnitRebate",
      props.project.currencySymbol + " per Motor Power Unit",
      "number",
      150,
      formatMoney
    ),
    createColumn(
      "perKwhSavedRebate",
      props.project.currencySymbol + " per KWh Saved",
      "number",
      130,
      formatMoney
    ),
    createColumn(
      "perPeakKwReductionRebate",
      props.project.currencySymbol + " per peak kW Reduction",
      "number",
      165,
      formatMoney
    ),
    createColumn(
      "perTonRebate",
      props.project.currencySymbol + " per Ton",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn(
      "perUnitRebate",
      props.project.currencySymbol + " per Unit",
      "number",
      DEFAULT_COLUMN_WIDTH
    ),
    createColumn(
      "percentOfMaxPrice",
      "Max % of Price",
      "number",
      DEFAULT_COLUMN_WIDTH,
      percentValueFormatter
    ),
    createColumn(
      "laborRateHr",
      props.project.currencySymbol + " per Hour Labor",
      "number",
      150,
      formatMoney
    ),
    createColumn("tmyClimateZone", "Climate", "string", 110),
    createColumn(
      "co2eGPerKwh",
      `${CO2E_LABEL} (${CO2E_UNITS})`,
      "number",
      110,
      undefined,
      renderClickToEditTooltip
    )
  );

  return (
    <Box sx={classes.table}>
      <Grid
        container
        spacing={0}
        mt={1}
        mb={1}
        mr={0}
        justifyContent="space-between"
        alignItems={"flex-start"}
      >
        <Grid item xs={9}>
          <FileUpload
            value={null}
            onChange={onChange}
            name="site_sheet"
            sx={classes.button}
            disabled={props.readOnly}
            title={
              getSiteRows().length > 0
                ? "Equipment details and subsequent project info are reset when excel form is uploaded. Click on each site's row to edit some inputs without resetting equipment details."
                : ""
            }
          />
          <Button
            variant="outlined"
            sx={classes.button}
            onClick={downloadExcel}
          >
            Download {getSiteRows().length === 0 ? "Template" : "Excel Form"}
          </Button>
          <Button
            variant="outlined"
            sx={classes.button}
            onClick={() => setAddSiteOpen(true)}
            style={{ marginLeft: "24px" }}
          >
            Add Site
          </Button>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <DefinitionsModal variant="SiteData" />
        </Grid>
        <Grid item xs={12} sx={{ verticalAlign: "top" }}>
          <FormsChangedBanner />
        </Grid>
      </Grid>
      {errors?.length > 0 ? (
        <div>
          <UploadErrorDetail errors={errors} title="Site Template Errors" />
        </div>
      ) : (
        getSiteRows().length > 0 && (
          <DataTable
            rows={getSiteRows()}
            columns={columns}
            pageSize={50}
            disableColumnMenu
            columnBuffer={10}
            style={{ height: 600, width: "100%" }}
            onCellClick={(params: GridCellParams) => {
              setActionSite(params.row as SiteData);
              setEditSiteOpen(!editSiteOpen);
            }}
          />
        )
      )}
      {addSiteOpen && (
        <AddSiteModal
          addSite={addSite}
          onCancel={() => setAddSiteOpen(false)}
        />
      )}
      {editSiteOpen && (
        <EditSiteModal
          site={actionSite}
          project={props.project}
          updateSite={updateSite}
          onCancel={() => setEditSiteOpen(false)}
        />
      )}
    </Box>
  );
}
