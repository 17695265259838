import React from "react";
import { Grid, InputAdornment, OutlinedInput, InputLabel } from "@mui/material";
import { RefNumberFormatCustom } from "modules/common/components/table/NumberFormatCustom";

export type NumericEditProps = {
  id: string;
  value: number;
  callback: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  label: string;
  units?: string;
  autoFocus?: boolean;
  isError?: boolean;
};

export const NumericEdit = ({
  id,
  value,
  callback,
  label,
  units,
  autoFocus = false,
  isError = false,
}: NumericEditProps) => (
  <Grid item xs={6} justifyContent={"stretch"}>
    <InputLabel htmlFor={id}>{label}</InputLabel>

    <OutlinedInput
      value={value || 0}
      required
      onChange={(event) => callback(event)}
      endAdornment={
        <InputAdornment position="end">{units || ""}</InputAdornment>
      }
      type="text"
      className="input"
      sx={{ backgroundColor: "#ffffff" }}
      aria-describedby={`${id}-helper-text`}
      autoFocus={autoFocus}
      inputComponent={RefNumberFormatCustom as any}
      inputProps={{
        "aria-label": label,
        min: 0,
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      fullWidth
      error={isError}
    />
  </Grid>
);
