import { SiteDataRow } from "types/SiteDataRow";
import { EquipmentData } from "./equipmentData";

export enum SatelliteStatus {
  NOT_REQUESTED = "NOT REQUESTED",
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  INVALID_ADDRESS = "INVALID ADDRESS",
  NO_RTUS_ON_LOCATION = "NO RTUs ON LOCATION",
}

export class SiteData {
  // This order matters for dump to excel template
  name = "";
  identifier = "";
  id = "";
  streetAddr = "";
  city = "";
  state = "";
  zipcode = "";
  country = "";
  buildingType = "";
  taxRatePercent = "";
  utilityRate = "";
  peakDemandCharge = "";
  perMotorPowerUnitRebate = "";
  perKwhSavedRebate = "";
  perPeakKwReductionRebate = "";
  perTonRebate = "";
  perUnitRebate = "";
  percentOfMaxPrice = "";
  laborRateHr = "";
  tmyClimateZone = "";
  co2eGPerKwh = 0;
  satelliteStatus = SatelliteStatus.NOT_REQUESTED;
  equipments = [] as EquipmentData[];
  imageUrl = "";

  setSiteData(s: SiteDataRow) {
    this.id = s.id;
    this.identifier = s.identifier;
    this.name = s.name;
    this.streetAddr = s.street_addr;
    this.city = s.city;
    this.state = s.state;
    this.country = s.country;
    this.zipcode = s.zipcode;
    this.co2eGPerKwh = s.co2e_g_per_kwh;
    this.utilityRate = s.utility_rate;
    this.buildingType = s.building_type;
    this.taxRatePercent = s.tax_rate_percent;
    this.tmyClimateZone = s.tmy_climate_zone;
    this.perMotorPowerUnitRebate = s.per_motor_power_unit_rebate;
    this.perKwhSavedRebate = s.per_kwh_saved_rebate;
    this.perPeakKwReductionRebate = s.per_peak_kw_reduction_rebate;
    this.perTonRebate = s.per_ton_rebate;
    this.perUnitRebate = s.per_unit_rebate;
    this.percentOfMaxPrice = s.percent_of_max_price;
    this.laborRateHr = s.labor_rate_hr;
    this.peakDemandCharge = s.peak_demand_charge;
    this.satelliteStatus =
      (s.satellite_status as SatelliteStatus) || SatelliteStatus.NOT_REQUESTED;
  }
}
