import { Box, Card } from "@material-ui/core";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { handleTextFieldChange, formatNumber } from "utils/helpers";
import EditableTableCell from "modules/common/components/table/EditableTableCell";
import Button from "@material-ui/core/Button";
import * as React from "react";
import { RowUpdate } from "types/FieldUpdateTypes";
import { tableStyles } from "modules/common/components/table/tableStyles";
import { MotorRebate } from "models/motorRebate";
import { AssetRebate } from "models/assetRebate";
import { SimpleTable } from "modules/common/components/table/SimpleTable";
import { Project } from "models/project";

type RebatesProps = {
  motorRebates: MotorRebate[];
  assetRebates: AssetRebate[];
  updateMotorRebate: (item: RowUpdate) => void;
  updateAssetRebate: (item: RowUpdate) => void;
  rebatesBySite: Map<string, number>;
  readOnly: boolean;
  project: Project;
};

export function Rebates({
  motorRebates,
  assetRebates,
  updateMotorRebate,
  updateAssetRebate,
  rebatesBySite,
  readOnly,
  project,
}: RebatesProps) {
  const classes = tableStyles();
  const [rowUpdates, setRowUpdates] = React.useState<RowUpdate[]>([]);
  const handleSaveMotorRebate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lineItemId: string
  ) => {
    const changesInLineItem = rowUpdates.find(
      (c) => c.lineItemId === lineItemId
    );
    if (changesInLineItem) {
      const rebate = motorRebates.find((m) => m.id === lineItemId);
      if (
        rebate &&
        !changesInLineItem.changes.find(
          (c) => c.fieldName === "percent_of_max_price"
        )
      ) {
        changesInLineItem.changes.push({
          fieldName: "percent_of_max_price",
          fieldValue: rebate.percentOfMaxPrice.toString(),
        });
      }
      updateMotorRebate(changesInLineItem);
    }
  };

  const handleSaveAssetRebate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lineItemId: string
  ) => {
    const changesInLineItem = rowUpdates.find(
      (c) => c.lineItemId === lineItemId
    );
    if (changesInLineItem) {
      const rebate = assetRebates.find((m) => m.id === lineItemId);
      if (
        rebate &&
        !changesInLineItem.changes.find(
          (c) => c.fieldName === "percent_of_max_price"
        )
      ) {
        changesInLineItem.changes.push({
          fieldName: "percent_of_max_price",
          fieldValue: rebate.percentOfMaxPrice.toString(),
        });
      }
      updateAssetRebate(changesInLineItem);
    }
  };

  return (
    <Box mt={2}>
      <Box mb={1}>
        <Typography variant="h6">Asset Rebate Summary</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "Asset Quantity",
              "Tonnage",
              `${project.currencySymbol} per Ton`,
              `${project.currencySymbol} per Unit`,
              "Misc. Rebate",
              "Max. Percent of Price",
              "Final Rebate",
              { value: "", align: "center" },
            ]}
            emptyMessage="No Asset Rebates"
          >
            {assetRebates.map((rebate: AssetRebate) => (
              <TableRow key={rebate.id}>
                <TableCell>{rebate.siteName}</TableCell>
                <TableCell>{rebate.siteIdentifier}</TableCell>
                <TableCell>{rebate.quantity}</TableCell>
                <TableCell>{rebate.tonnage}</TableCell>
                <EditableTableCell
                  defaultValue={rebate.perTonRebate}
                  fieldName={"per_ton_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={rebate.perUnitRebate}
                  fieldName={"per_unit_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={rebate.miscRebate}
                  fieldName={"misc_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={
                    rebate.percentOfMaxPrice === null ||
                    rebate.percentOfMaxPrice === undefined
                      ? 100
                      : rebate.percentOfMaxPrice
                  }
                  fieldName={"percent_of_max_price"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  isCurrency={false}
                />
                <TableCell>
                  {project.formatMoney(
                    rebatesBySite.get(rebate.siteIdentifier) || 0
                  )}
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSaveAssetRebate(event, rebate.id)}
                    variant="outlined"
                    className={readOnly ? "" : classes.button}
                    disabled={readOnly}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
      <Box mb={1} mt={6}>
        <Typography variant="h6">Motor Rebates</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site Id",
              "SKU",
              "Base Motor Power",
              "Quantity",
              "kWh Saved",
              "Peak Demand Reduction (kW)",
              `${project.currencySymbol} per Motor Power`,
              `${project.currencySymbol} per KWh Saved`,
              `${project.currencySymbol} per peak KW Reduction`,
              "Misc. Rebate",
              { value: "", align: "center" },
            ]}
            emptyMessage="No Accessory Line Items"
          >
            {motorRebates.map((rebate: MotorRebate) => (
              <TableRow key={rebate.id}>
                <TableCell component="th" scope="row">
                  {rebate.siteName}
                </TableCell>
                <TableCell>{rebate.siteIdentifier}</TableCell>
                <TableCell>{rebate.sku}</TableCell>
                <TableCell>{rebate.base_motor_power}</TableCell>
                <TableCell>{rebate.quantity}</TableCell>
                <TableCell>{formatNumber(rebate.kwhSaved)}</TableCell>
                <TableCell>{rebate.peakKwReduction}</TableCell>
                <EditableTableCell
                  defaultValue={rebate.perMotorPowerUnitRebate}
                  fieldName={"per_motor_power_unit_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={rebate.perKwhSavedRebate}
                  fieldName={"per_kwh_saved_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={rebate.perPeakKwReductionRebate}
                  fieldName={"per_peak_kw_reduction_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <EditableTableCell
                  defaultValue={rebate.miscRebate}
                  fieldName={"misc_rebate"}
                  onCellValueChange={(event) =>
                    handleTextFieldChange(
                      event,
                      rebate.id,
                      rowUpdates,
                      setRowUpdates
                    )
                  }
                  currencySymbol={project.currencySymbol}
                  locale={project.locale}
                />
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleSaveMotorRebate(event, rebate.id)}
                    variant="outlined"
                    className={readOnly ? "" : classes.button}
                    disabled={readOnly}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
    </Box>
  );
}
