import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { capitalize, startCase } from "lodash";
import { SiteDataRow } from "types/SiteDataRow";
import { SiteData } from "models/siteData";

export const useFetchSites = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [allSites, setSites] = React.useState<SiteData[]>([]);
  React.useEffect(() => {
    if (projectId && projectId !== "") {
      const getSites = async () => {
        setLoading(true);
        getResource<{ data: SiteDataRow[] }>(
          `${window.APP_CONFIG.API_HOST}/sites?project_id=${projectId}`,
          auth
        )
          .then(({ data }) => {
            setLoading(false);
            let dataRows: SiteData[] = [];
            if (data.length > 0) {
              data.forEach((d: SiteDataRow) => {
                const data = new SiteData();
                data.setSiteData(d);
                dataRows.push(data);
              });
            }
            setSites(dataRows);
          })
          .catch((error) => {
            enqueueSnackbar(
              `There was an error retrieving sites data: ${error}`,
              {
                variant: "error",
              }
            );
          });
      };
      getSites();
    }
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loading,
    allSites,
  };
};

export const formatStatus = (status: string) => {
  return startCase(capitalize(status));
};
