import * as React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { CO2, Payback, Rebate, Savings } from "modules/project-stepper/icons";
import { DetailedSummary } from "types/DetailedSummary";
import { Project } from "models/project";
import { ExecutiveSummary } from "types/ExecutiveSummary";
import { formatNumber } from "utils/helpers";

export type TypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline"
  | "inherit"
  | undefined;

type SummaryHeaderProps = {
  header: string;
  value: string | number;
  icon: React.ReactNode;
  headerVariant?: TypographyVariant; // e.g. subtitle1
  valueVariant?: TypographyVariant; // e.g. h5
};

export const SummaryHeader = ({
  header,
  value,
  icon,
  headerVariant,
  valueVariant,
}: SummaryHeaderProps) => {
  return (
    <Grid item xs={3}>
      <Box
        style={{
          filter: "drop-shadow(-1px 3px 3px rgba(50, 50, 0, 0.5))",
        }}
      >
        <Paper
          sx={{
            p: 2,
            clipPath: "polygon(100% 0, 100% 85%, 90% 100%, 0 100%, 0 0)",
          }}
        >
          <Box pr={3} pb={0}>
            <Typography
              color="primary"
              variant={headerVariant || "subtitle1"}
              style={{
                fontWeight: "bold",
                color: "primary",
                textTransform: "uppercase",
              }}
            >
              {header}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            {icon}
            <Box
              p={icon ? 2 : 0}
              fontWeight="fontWeightLight"
              fontSize={`${valueVariant || "h4"}.fontSize`}
            >
              {value}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};

type SummaryCardProps = {
  project: Project;
  summary: DetailedSummary | ExecutiveSummary;
  showIcons: boolean;
  headerVariant?: TypographyVariant;
  valueVariant?: TypographyVariant;
};
export const SummaryCards = ({
  project,
  summary,
  showIcons,
  headerVariant,
  valueVariant,
}: SummaryCardProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row" spacing={3}>
        <SummaryHeader
          header="Price After Rebates"
          value={project.formatMoney(
            summary.total_price - summary.total_rebates
          )}
          icon={showIcons && <Rebate />}
          headerVariant={headerVariant}
          valueVariant={valueVariant}
        />
        <SummaryHeader
          header="Annual Utility Savings"
          value={project.formatMoney(summary.utility_cost_savings)}
          icon={showIcons && <Savings />}
          headerVariant={headerVariant}
          valueVariant={valueVariant}
        />
        <SummaryHeader
          header="Simple Payback"
          value={`${formatNumber(summary.simple_payback, 1) || 0} years`}
          icon={showIcons && <Payback />}
          headerVariant={headerVariant}
          valueVariant={valueVariant}
        />
        <SummaryHeader
          header="Annual CO2e Reduction"
          value={`${summary.co_reduction || 0} tons`}
          icon={showIcons && <CO2 />}
          headerVariant={headerVariant}
          valueVariant={valueVariant}
        />
      </Grid>
    </Box>
  );
};
