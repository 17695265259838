import React from "react";
import { Theme, withStyles, WithStyles, Link } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { styles } from "./styles";

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fal fas fa-times"></i>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(4)}px ${theme.spacing(9)}px`,
  },
}))(MuiDialogContent);

export function HelpModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        style={{ color: "black", textDecoration: "none" }}
        component="button"
        variant="body2"
        onClick={handleClickOpen}
      >
        Help
      </Link>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="help-title" onClose={handleClose}>
          ROI Calculator Help
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Please email <b>roicalculator@turntide.com</b> for assistance.
            Include your username, name of your project and a description of
            your issue. We will review and contact you shortly. Thank you!
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
