import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";

export const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#F0F0F0",
      },

      "&.projects": {
        "& .MuiDataGrid-row.Mui-odd": {
          backgroundColor: "white",
        },
      },
      "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus":
        {
          outline: "none !important",
        },
    },
    container: {
      border: "black 1px solid",
    },
    button: {
      borderRadius: "2px",
      fontSize: "14px",
      "&.MuiButton-contained": {
        color: "#FFFFFF",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
        padding: "8px 24px",
        border: "1px solid transparent",
        textTransform: "uppercase",
      },
      "&.MuiButton-outlined": {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: "#FFFFFF",
        },
      },
      "&.MuiButton-root:hover": {
        background: "transparent",
      },
      "&.MuiButton-text": {
        padding: 0,
      },
    },
  })
);
