import React, { useState } from "react";
import { postRequest } from "utils/helpers";
import { ProjectProps } from "types/ProjectProps";
import { Box, Typography, Card } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useAuth } from "modules/common/auth";
import { tableStyles } from "modules/common/components/table/tableStyles";
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuButton,
} from "modules/common/components/ActionMenu";
import { useMenuState } from "modules/hooks/useMenuState";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { CustomCheckbox } from "modules/common/components/CustomCheckbox";
import { GridCellParams, DataGrid } from "@mui/x-data-grid";
import { ShareProjectDialog } from "modules/project-sharing/ShareProjectDialog";
import { CustomColumnMenuComponent } from "modules/common/components/table/CustomColumnMenuComponent";
import { useFetchProjectShares } from "modules/hooks/useFetchProjectShares";
import { ProjectShare } from "types/ProjectShare";
import { User } from "types/User";
import { useFetchProjects } from "modules/hooks/useFetchProjects";

function ProjectsComponent() {
  const classes = tableStyles();
  const [archiveConfDialogOpen, setArchiveDialogOpen] = useState(false);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [shares, setProjectShares] = useState({
    project_shares: [] as ProjectShare[],
    users: [] as User[],
    updated: false,
  });
  const { loading, allProjects } = useFetchProjects();
  const [projects, setProjects] = useState<ProjectProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const menu = useMenuState();
  const [actionProject, setActionProject] = useState<ProjectProps>(
    {} as ProjectProps
  );
  const handleMenuOpen = (p: ProjectProps, event: React.MouseEvent) => {
    setActionProject(p);
    menu.handleOpen(event);
  };
  const [displayArchivedProjects, setDisplayArchivedProjects] = useState(false);
  const handleArchiveProject = (p: ProjectProps) => {
    postRequest<{ data: { project: ProjectProps } }>(
      "PUT",
      `${window.APP_CONFIG.API_HOST}/projects/${p.id}`,
      auth,
      JSON.stringify({ archived: !p.archived })
    )
      .then(({ data }) => {
        enqueueSnackbar(
          `${data.project.archived ? "Archived" : "Unarchived"} project ${
            data.project.name
          }`,
          {
            variant: "success",
          }
        );
        setProjects((projects) =>
          projects.map((p) => (p.id === data.project.id ? data.project : p))
        );
        setArchiveDialogOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar(`Project update failed: ${error}`, {
          variant: "error",
        });
      });
  };
  const handleDisplay = () => {
    setDisplayArchivedProjects(!displayArchivedProjects);
  };
  const columns = [
    {
      field: "name",
      width: 300,
      renderHeader: () => <GridHeader title="Project Name" />,
      headerAlign: "left" as "left",
      headerName: "Project Name",
    },
    {
      field: "created_at",
      renderHeader: () => <GridHeader title="Date Created" />,
      width: 250,
      headerAlign: "left" as "left",
      headerName: "Date Created",
      type: "date",
    },
    {
      field: "updated_at",
      renderHeader: () => <GridHeader title="Date Updated" />,
      width: 250,
      headerName: "Date Updated",
      type: "date",
    },
    {
      field: "status",
      renderHeader: () => <GridHeader title="Status" />,
      width: 200,
      headerName: "Status",
    },
    {
      field: "currency",
      renderHeader: () => <GridHeader title="Currency" />,
      width: 150,
      headerName: "Currency",
    },
    {
      field: "organization_name",
      renderHeader: () => <GridHeader title="Organization" />,
      width: 200,
      headerName: "Organization",
    },
    {
      field: "user_email",
      renderHeader: () => <GridHeader title="Owner" />,
      width: 300,
      headerName: "Owner",
    },
    {
      field: "action",
      renderHeader: () => <GridHeader title="Action" />,
      renderCell: (params: GridCellParams) => (
        <ActionMenuButton
          onClick={(event) => handleMenuOpen(params.row as ProjectProps, event)}
        />
      ),
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const { loadingProjectShares, projectSharesAndUsers } =
    useFetchProjectShares();

  const prjSharesAndUsers =
    projectSharesAndUsers && !loadingProjectShares
      ? projectSharesAndUsers
      : { shared_by_user: [], shared_with_user: [], users: [] };

  const projectShares = () => {
    return shares.updated
      ? shares
      : {
          project_shares: prjSharesAndUsers.shared_by_user,
          users: projectSharesAndUsers.users,
        };
  };

  const addProjectShare = (share: ProjectShare, user: User) => {
    const prjShares = projectShares();
    setProjectShares({
      project_shares: prjShares.project_shares.concat(share),
      users: prjShares.users.concat(user),
      updated: true,
    });
  };

  const updateProjectShare = (share: ProjectShare) => {
    const prjShares = projectShares();
    setProjectShares({
      ...prjShares,
      project_shares: prjShares.project_shares.map((p) =>
        p.id === share.id ? share : p
      ),
      updated: true,
    });
  };

  const deleteProjectShare = (id: string) => {
    const prjShares = projectShares();
    setProjectShares({
      ...prjShares,
      project_shares: prjShares.project_shares?.filter((p) => p.id !== id),
      updated: true,
    });
  };

  if (loading) {
    return (
      <Box mt={5} pl={8} pr={8}>
        Loading projects...
      </Box>
    );
  }

  return (
    <Box mt={5} pl={8} pr={8} className={`projects ${classes.table}`}>
      <Box display="flex" pb={4}>
        <Box p={1} flexGrow={1}>
          <Typography variant="h6">Projects</Typography>
        </Box>
        <Box>
          <Button
            disableRipple={true}
            className={classes.button}
            color="primary"
            href="/projects/new"
          >
            Add Project
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormGroup row>
          <FormControlLabel
            style={{
              marginRight: "0",
            }}
            control={
              <CustomCheckbox
                disableRipple
                checked={displayArchivedProjects}
                onChange={handleDisplay}
              />
            }
            label="Display archived projects"
          />
        </FormGroup>
      </Box>
      <Box>
        <Card>
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={(projects.length > 0
                ? projects
                : !loading
                ? allProjects
                : []
              ).filter(
                (p: ProjectProps) =>
                  displayArchivedProjects ||
                  (!displayArchivedProjects && !p.archived)
              )}
              columns={columns}
              pageSize={50}
              sortingOrder={["asc", "desc"]}
              components={{
                ColumnMenu: CustomColumnMenuComponent,
              }}
            />
          </div>
        </Card>
      </Box>
      <ActionMenu
        {...menu.props}
        disableAutoFocusItem={true}
        onClick={menu.handleClose}
      >
        <ActionMenuItem>
          {actionProject && (
            <a
              style={{
                color: "black",
                textDecoration: "none",
                width: "100%",
                textAlign: "right",
              }}
              href={`/projects/${actionProject.id}`}
            >
              {actionProject.editable ? "Edit" : "View"}
            </a>
          )}
        </ActionMenuItem>
        {actionProject.editable && (
          <ActionMenuItem onClick={() => setArchiveDialogOpen(true)}>
            <Typography>
              {actionProject.archived ? "Unarchive" : "Archive"}
            </Typography>
          </ActionMenuItem>
        )}
        {actionProject.editable && (
          <ActionMenuItem onClick={() => setSharingDialogOpen(true)}>
            <Typography>Sharing</Typography>
          </ActionMenuItem>
        )}
      </ActionMenu>
      <Dialog
        open={archiveConfDialogOpen}
        onClose={() => setArchiveDialogOpen(true)}
      >
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${
              actionProject.archived ? "unarchive" : "archive"
            } project ${actionProject.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleArchiveProject(actionProject)}
            color="primary"
            autoFocus
          >
            {`${actionProject.archived ? "Unarchive" : "Archive"} Project`}
          </Button>
        </DialogActions>
      </Dialog>
      {actionProject.id && sharingDialogOpen && (
        <ShareProjectDialog
          open={sharingDialogOpen}
          setOpen={setSharingDialogOpen}
          projectId={actionProject.id}
          shares={projectShares()}
          addProjectShare={addProjectShare}
          updateProjectShare={updateProjectShare}
          deleteProjectShare={deleteProjectShare}
        />
      )}
    </Box>
  );
}

type HeaderProps = {
  title: string;
};

function GridHeader({ title }: HeaderProps) {
  return (
    <Box
      fontWeight="fontWeightBold"
      color="text.hint"
      style={{ textTransform: "uppercase", paddingTop: "2px" }}
    >
      {title}
    </Box>
  );
}

export const Projects = withRouter(ProjectsComponent);
