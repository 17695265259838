import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, Grid, Stack } from "@mui/material";
import { DialogTitle } from "../../../common/components/dialog/DialogTitle";
import { ErrorDetail } from "../../../common/components/ErrorDetail";
import { Error } from "../../../../types/Error";
import { SiteData } from "models/siteData";
import {
  CO2E_LABEL,
  CO2E_UNITS,
  PEAK_DEMAND_CHARGE_LABEL,
  TAX_RATE_LABEL,
  UTILITY_RATE_LABEL,
} from "../../constants/strings";
import { Project } from "models/project";
import { NumericEdit } from "modules/common/components/NumericEdit";

export type UpdateSiteProps = {
  taxRatePercent: number;
  utilityRate: number;
  peakDemandCharge: number;
  co2eGPerKwh: number;
};

type EditSiteProps = {
  site: SiteData;
  project: Project;
  updateSite: (
    id: string,
    siteProps: UpdateSiteProps,
    closeModal: () => void,
    setModalErrors: (arg: Array<any>) => void
  ) => void;
  onCancel: () => void;
};

export function EditSiteModal({
  site,
  project,
  updateSite,
  onCancel,
}: EditSiteProps) {
  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState<Error[]>([]);
  const [taxRatePercent, setTaxRatePercent] = useState(+site.taxRatePercent);
  const [utilityRate, setUtilityRate] = useState(+site.utilityRate);
  const [peakDemandCharge, setPeakDemandCharge] = useState(
    +site.peakDemandCharge
  );
  const [co2eGPerKwh, setCo2eGPerKwh] = useState(+site.co2eGPerKwh);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
    onCancel();
  };

  const onSave = () => {
    setButtonsDisabled(true);
    updateSite(
      site.id,
      {
        taxRatePercent,
        utilityRate,
        peakDemandCharge,
        co2eGPerKwh,
      },
      handleClose,
      (errors) => {
        setErrors(errors);
        setButtonsDisabled(false);
      }
    );
  };

  return (
    <div>
      <Dialog
        onClose={handleCancel}
        open={open}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#F1F1F2",
          },
        }}
      >
        <DialogTitle onClose={handleCancel}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            textAlign="left"
            ml={3}
            mt={2}
            sx={{ fontFamily: "Barlow" }}
          >
            <Stack>
              <span>{`${site.name} - ${site.identifier}`}</span>
              <span>{`${site.streetAddr}, ${site.city}`}</span>
              <span>{`${site.state}, ${site.zipcode}, ${site.country}`}</span>
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form autoComplete="off">
            <Grid spacing={2} pl={4} container justifyContent={"stretch"}>
              <NumericEdit
                id="utilityRate"
                value={utilityRate}
                callback={(event) => setUtilityRate(+event.target.value || 0)}
                label={UTILITY_RATE_LABEL}
                autoFocus={true}
                units={project.getUtilityRateUnits()}
              />
              <NumericEdit
                id="taxRatePercent"
                value={taxRatePercent}
                callback={(event) =>
                  setTaxRatePercent(+event.target.value || 0)
                }
                label={TAX_RATE_LABEL}
                units="%"
              />
              <NumericEdit
                id="peakDemandCharge"
                value={peakDemandCharge}
                callback={(event) =>
                  setPeakDemandCharge(+event.target.value || 0)
                }
                label={PEAK_DEMAND_CHARGE_LABEL}
                units={project.getDemandChargeUnits()}
              />
              <NumericEdit
                id="co2eGPerKwh"
                value={co2eGPerKwh}
                callback={(event) => setCo2eGPerKwh(+event.target.value || 0)}
                label={CO2E_LABEL}
                units={CO2E_UNITS}
              />

              {errors?.length > 0 && (
                <div>
                  <ErrorDetail errors={errors} title="Edit Site Error" />
                </div>
              )}
            </Grid>
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                style={{ marginRight: "24px" }}
                variant="outlined"
                onClick={handleCancel}
                disabled={buttonsDisabled}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => onSave()}
                disabled={
                  (taxRatePercent === +site.taxRatePercent &&
                    utilityRate === +site.utilityRate &&
                    peakDemandCharge === +site.peakDemandCharge &&
                    co2eGPerKwh === +site.co2eGPerKwh) ||
                  buttonsDisabled
                }
              >
                Save
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
