/** Data structure returned by the Azure Maps Address Search API */
export class SearchResultAddress {
  buildingNumber?: string;
  country?: string;
  countryCode?: string;
  countryCodeISO3?: string;
  countrySecondarySubdivision?: string;
  countrySubdivision?: string;
  countrySubdivisionName?: string;
  extendedPostalCode?: string;
  freeformAddress?: string;
  localName?: string;
  municipality?: string;
  municipalitySubdivision?: string;
  postalCode?: string;
  streetName?: string;
  streetNameAndNumber?: string;
  streetNumber?: string;
  isApiResult?: boolean; // true if the API returned this one; false if the UI added it to capture typing
}

export const DEFAULT_SEARCH_RESULT_ADDRESS: SearchResultAddress = {};
