import { MotorRebateRow } from "types/MotorRebateRow";

export class MotorRebate {
  id = "";
  siteName = "";
  siteIdentifier = "";
  sku = "";
  base_motor_power = 0;
  quantity = 0;
  kwhSaved = 0;
  peakKwReduction = 0;
  perKwhSavedRebate = 0;
  perPeakKwReductionRebate = 0;
  perMotorPowerUnitRebate = 0;
  miscRebate = 0;
  percentOfMaxPrice = 0;

  setMotorRebate(rebate: MotorRebateRow) {
    this.id = rebate.id;
    this.siteName = rebate.site_name;
    this.siteIdentifier = rebate.site_identifier;
    this.sku = rebate.sku;
    this.base_motor_power = rebate.base_motor_power;
    this.quantity = rebate.quantity;
    this.kwhSaved = rebate.kwh_saved;
    this.peakKwReduction = rebate.peak_kw_reduction || 0;
    this.perKwhSavedRebate = rebate.per_kwh_saved_rebate || 0;
    this.perPeakKwReductionRebate = rebate.per_peak_kw_reduction_rebate || 0;
    this.perMotorPowerUnitRebate = rebate.per_motor_power_unit_rebate || 0;
    this.miscRebate = rebate.misc_rebate || 0;
    this.percentOfMaxPrice = rebate.percent_of_max_price;
  }

  totalRebate = () => {
    return (
      this.perKwhSavedRebate * this.kwhSaved +
      this.peakKwReduction * this.perPeakKwReductionRebate +
      this.perMotorPowerUnitRebate * this.quantity * this.base_motor_power +
      this.miscRebate
    );
  };
}
