import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { SubscriptionLineItemRow } from "types/SubscriptionLineItemRow";

export const useFetchSubscriptionLineItems = (
  projectId: string | undefined
) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingSlis, setLoading] = React.useState(true);
  const [slis, setSlis] = React.useState<SubscriptionLineItemRow[]>([]);
  React.useEffect(() => {
    const getSubscriptions = async () => {
      setLoading(true);
      getResource<{ data: SubscriptionLineItemRow[] }>(
        `${window.APP_CONFIG.API_HOST}/subscription_line_items?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          setSlis(data);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving subscriptions. ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getSubscriptions();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loadingSlis,
    slis,
    setSlis,
  };
};
