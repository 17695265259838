import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import * as Raven from "raven-js";
import { asyncThrottle } from "modules/common/utils/async-throttle";

type Auth0Config = {
  domain: string;
  clientID: string;
  audience: string;
  redirectPath: string;
  onRedirectCallback: (url: string) => void;
};

export class AuthService {
  private static readonly TRIGGER_LOGOUT = "trigger-logout";
  private readonly client: Promise<Auth0Client>;

  constructor(config: Auth0Config) {
    this.client = AuthService.createClient(config);
  }

  private static createClient = async (config: Auth0Config) => {
    const client = await createAuth0Client({
      domain: config.domain,
      client_id: config.clientID,
      audience: config.audience,
      redirect_uri: `${window.location.origin}${config.redirectPath}`,
      leeway: 300,
    });

    // handle login redirect callback if necessary, before finishing resolving client
    if (
      window.location.search.includes("code=") ||
      window.location.search.includes("error=")
    ) {
      const targetUrl = await AuthService.handleRedirectCallback(client);
      config.onRedirectCallback(targetUrl);
    }

    // update raven context with login information
    await AuthService.updateRavenContext(client);

    // listen for logout events from other tabs
    window.addEventListener("storage", (evt) => {
      if (evt.key === AuthService.TRIGGER_LOGOUT && evt.newValue === null) {
        client.logout();
      }
    });

    return client;
  };

  private static handleRedirectCallback = async (client: Auth0Client) => {
    try {
      const { appState } = await client.handleRedirectCallback();
      return (appState && appState.referrerLocation) || "/";
    } catch (err: any) {
      // redirect back to the authorization page with an additional custom error_message query
      // param, which will be displayed as a flash message on the login page.
      await client.loginWithRedirect({
        prompt: "login",
        error_message: err.message,
      });
    }
  };

  private static updateRavenContext = async (client: Auth0Client) => {
    const claims = await client.getIdTokenClaims();
    if (claims) {
      const internalId = claims["https://turntide.com/internal_id"] || "";
      const roles = claims["https://turntide.com/roles"] || [];
      Raven.setUserContext({ id: internalId });
      Raven.setTagsContext({ userRoles: roles.join(",") });
    }
  };

  // save current location and redirect to auth0 login page
  login = async () => {
    const client = await this.client;
    return await client.loginWithRedirect({
      appState: { referrerLocation: window.location },
    });
  };

  // log out of SSO
  logout = async () => {
    const client = await this.client;

    // signal other tabs to also logout
    window.localStorage.setItem(AuthService.TRIGGER_LOGOUT, "");
    window.localStorage.removeItem(AuthService.TRIGGER_LOGOUT);

    return await client.logout();
  };

  isAuthenticated = async () => {
    // remove this line after auth is implemented
    return true;
    // const client = await this.client;
    // return await client.isAuthenticated();
  };

  // fetch a cached or renewed access token, triggering login if no longer authenticated
  // (note that getTokenSilently currently can't be called concurrently, so inflight requests
  // are coalesced with asyncThrottle)
  // tslint:disable-next-line:member-ordering
  getToken = asyncThrottle(async () => {
    const client = await this.client;
    try {
      await client.getTokenSilently(); // maybe refresh auth
      let claims = await client.getIdTokenClaims();

      // hack: there is a race condition between fetching a token from cache, and the
      // cache eviction setTimeout, which mostly manifests when waking up from sleep.
      // therefore we conditionally force a uncached refetch when we receive an expired token.
      const expiration = (claims.exp || 0) * 1000;
      if (Date.now() > expiration) {
        await client.getTokenSilently({
          ignoreCache: true,
          // @ts-ignore
          audience: client.options.audience,
          // @ts-ignore
          scope: client.options.scope,
        });
        claims = await client.getIdTokenClaims();
      }

      // FIXME:
      //  this is a work around for an issue in keycloak-proxy where the proxy
      //  applies id_token claim validations to access tokens.
      //  (see https://issues.jboss.org/browse/KEYCLOAK-8954)
      //  for now use the id_token to authenticate with the backend,
      //  and back this out once istio lands
      return claims.__raw;
    } catch (e) {
      // not authenticated, redirect to login
      await this.login();
      return new Promise(() => {
        /* never resolves */
      });
    }
  });
}
