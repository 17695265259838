import * as React from "react";
import { Box, Checkbox } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";

const checkBoxStyles = (theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&$checked": {
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },
  checked: {},
});

export const CustomCheckbox = (
  props: React.ComponentProps<typeof Checkbox>
) => {
  return (
    <Box sx={checkBoxStyles(useTheme())}>
      <Checkbox {...props}></Checkbox>
    </Box>
  );
};
