import * as React from "react";
import { Typography } from "@mui/material";

type WarningBannerProps = {
  label: string;
};

export function WarningBanner({ label }: WarningBannerProps) {
  return (
    <h4>
      <Typography
        style={{
          fontFamily: "Barlow",
          color: "#F05136",
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        {label}
      </Typography>
    </h4>
  );
}
