import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Grid,
} from "@mui/material";
import {
  useFetchUserOrgs,
  TURNTIDE_ORGANIZATION_ID,
} from "modules/hooks/useFetchUserOrgs";
import { useFetchCurrencies } from "modules/hooks/useFetchCurrencies";
import { boolToString } from "utils/helpers";

export interface ProjectOptionsProps {
  setConnectivityOption: (connectivityOption: string) => void;
  setMAndVOption: (mAndVOption: boolean) => void;
  setProjectName: (projectName: string) => void;
  setCurrency: (currency: string) => void;
  setExchangeRate: (rate: number) => void;
  setProjectOrganization: (org: string) => void;
  projectName: string;
  connectivityOption: string;
  mAndVOption: boolean;
  currencyOption: string;
  readOnly: boolean;
  projectOrgId: string;
  exchangeRate: number;
}

export function ProjectOptions(props: ProjectOptionsProps) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          margin: theme.spacing(9),
        },
        "& #project-name-label": {
          display: "inline-block",
        },
        "& #project-name": {
          width: "50%",
        },
        "& .input": {
          backgroundColor: "white",
          width: "100%",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "solid 1px #BCBCBC",
        },
        "& .MuiFormLabel-root": {
          color: "black",
        },
      },
    })
  );
  const classes = useStyles();
  const connectivityOptions = [
    {
      value: "None",
      label: "None - without cloud connection",
    },
    {
      value: "Cellular",
      label: "Cellular - RMK-002",
    },
    {
      value: "Wifi",
      label: "Local Network - Hardwired to BMS (RMK-001)",
    },
  ];
  const handleConnectivityOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setConnectivityOption(event.target.value);
  };

  const mAndVOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  const handleMAndVOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setMAndVOption(event.target.value === "Yes");
  };

  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setProjectName(event.target.value);
  };

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    props.setCurrency(event.target.value);
    props.setExchangeRate(
      currencies.find((c) => c.currency === event.target.value)?.exchangeRate ||
        1
    );
  };

  const {
    projectName,
    connectivityOption,
    mAndVOption,
    currencyOption,
    readOnly,
    projectOrgId,
    exchangeRate,
    setProjectOrganization,
  } = props;

  const handleOrganizationChange = (event: SelectChangeEvent) => {
    setProjectOrganization(event.target.value);
  };

  const { loading, data } = useFetchUserOrgs();

  useEffect(() => {
    // Make Turntide the default org iff the user has it available;
    // make the only org ID the default if there's only one.
    if (!projectOrgId && !loading) {
      const organizations = data && !loading ? data : [];
      const defaultOrgId =
        organizations.length === 1
          ? organizations[0].id
          : TURNTIDE_ORGANIZATION_ID;
      if (organizations.findIndex((org) => org.id === defaultOrgId) > -1) {
        setProjectOrganization(defaultOrgId);
      }
    }
  }, [projectOrgId, data, loading, setProjectOrganization]);

  const { loadingCurrencies, currencyData } = useFetchCurrencies();
  const currencies = currencyData && !loadingCurrencies ? currencyData : [];
  const orgs = data && !loading ? data : [];

  return (
    <Box className={classes.root}>
      <form autoComplete="off">
        <Box mb={4}>
          <Typography variant="h5">Project Properties</Typography>
        </Box>
        <label htmlFor="project-name" className="project-input">
          <Box mb={1}>
            <Typography>Project Name</Typography>
          </Box>
          <TextField
            value={projectName}
            required
            onChange={handleProjectNameChange}
            id="project-name"
            type="text"
            variant="outlined"
            className="input"
            label="Required"
            disabled={readOnly}
            autoFocus
          />
        </label>

        <Box mt={3}>
          <label className="project-input">
            <Box mb={1}>
              <Typography>Cloud Connectivity</Typography>
            </Box>
            <TextField
              id="select-cloud-connectivity"
              select
              value={connectivityOption}
              onChange={handleConnectivityOptionChange}
              className="input"
              variant="outlined"
              disabled={readOnly}
            >
              {connectivityOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  selected={option.value === connectivityOption}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </label>
          <Box mt={3}>
            <label>
              <Box mb={1}>
                <Typography>Measurement and Verification (M&V)</Typography>
              </Box>
              <TextField
                id="m-and-v"
                select
                value={mAndVOption ? "Yes" : "No"}
                onChange={handleMAndVOptionChange}
                variant="outlined"
                className="input"
                disabled={readOnly}
              >
                {mAndVOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={option.value === boolToString(mAndVOption)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </label>
          </Box>
          <Box mt={3}>
            <label>
              <Grid container mb={1} justifyContent="space-between">
                <Typography>Currency</Typography>
                {currencyOption !== "USD" && (
                  <Typography>Current Exchange Rate: {exchangeRate}</Typography>
                )}
              </Grid>
              {currencies.find((c) => c.currency === currencyOption) && (
                <Select
                  id="currency"
                  value={currencyOption}
                  onChange={handleCurrencyChange}
                  variant="outlined"
                  className="input"
                  disabled={readOnly}
                >
                  {currencies.map((option) => (
                    <MenuItem
                      key={option.currency}
                      value={option.currency}
                      selected={option.currency === currencyOption}
                    >
                      {option.currency}
                      {option.currency !== "USD" && (
                        <>:&nbsp;{option.exchangeRate}</>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </label>
          </Box>
          <Box mt={3}>
            <label>
              <Box mb={1}>
                <Typography>Project Organization</Typography>
              </Box>
              <Select
                value={!loading && orgs?.length ? projectOrgId : ""}
                onChange={handleOrganizationChange}
                variant="outlined"
                className="input"
                disabled={readOnly}
                id="project-org"
              >
                {orgs.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    selected={option.id === projectOrgId}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </label>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
