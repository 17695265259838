import React, { useCallback, useEffect, useRef } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { EquipmentDataRow } from "types/EquipmentDataRow";
import {
  FileUpload,
  FormsChangedBanner,
} from "modules/common/components/FileUpload";
import { useSnackbar } from "notistack";
import { EquipmentData, Availability } from "models/equipmentData";
import { getResource, uploadFile } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import { Project } from "models/project";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Error } from "types/Error";
import { UploadErrorDetail } from "modules/common/components/ErrorDetail";
import { tableStyles5 } from "modules/common/components/table/tableStyles5";
import { TableHeader } from "modules/common/components/table/TableHeader";
import { DefinitionsModal } from "modules/common/components/dialog/DefinitionsModal";
import { CustomCheckbox } from "modules/common/components/CustomCheckbox";
import { useState } from "react";
import { capitalize } from "lodash";
import { useTheme, Theme } from "@mui/material/styles";
import {
  DataTable,
  percentValueFormatter,
  renderStringWithTooltip,
} from "modules/common/components/table/DataTable";
import { Typography } from "@material-ui/core";

const XlsxPopulate = require("xlsx-populate");

export interface EquipmentDataProps {
  disablePricingButton: (disabled: boolean) => void;
  readOnly: boolean;
  project: Project;
  onEquipmentStatusUpdate: (
    activeMotorIds: string[],
    inactiveMotorIds: string[]
  ) => void;
}

const useStyles = (theme: Theme) => ({
  root: {
    position: "fixed",
    height: "100%",
    width: "100%",
    zIndex: 5000,
    top: "0",
    left: "0",
    textAlign: "center",
    paddingTop: "25%",
    opacity: ".80",
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      height: "42px",
      top: "4px",
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "2px",
    },
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 4px) scale(0.75)",
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
    },
  },
});

interface DatagridProps {
  footerText: string;
}

const useMenuStyles = ({ footerText }: DatagridProps) => ({
  root: {
    "& .MuiDataGrid-columnHeader--numeric .MuiDataGrid-columnHeaderDraggableContainer, .MuiDataGrid-columnHeader--numeric .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer":
      {
        flexDirection: "row !important",
      },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
      "&::before": {
        content: `"${footerText}"`,
        visibility: "visible",
      },
    },
  },
});

export function EquipmentDataUpload(props: EquipmentDataProps) {
  const columns = [
    {
      field: "siteId",
      width: 150,
      renderHeader: () => <TableHeader headerName="Site ID" />,
      renderCell: renderStringWithTooltip,
    },
    {
      field: "unitName",
      width: 150,
      renderHeader: () => <TableHeader headerName="Unit Name" />,
      renderCell: renderStringWithTooltip,
    },
    {
      field: "manufacturer",
      width: 180,
      renderHeader: () => <TableHeader headerName="manufacturer" />,
      renderCell: renderStringWithTooltip,
    },
    {
      field: "modelNumber",
      width: 180,
      renderHeader: () => <TableHeader headerName="Model Number" />,
    },
    {
      field: "fanType",
      width: 130,
      renderHeader: () => <TableHeader headerName="Fan Type" />,
    },
    {
      field: "vfd",
      renderHeader: () => <TableHeader headerName="VFD" />,
    },
    {
      field: "motorPower",
      width: 170,
      renderHeader: () => <TableHeader headerName="Motor Power (HP/KW)" />,
      type: "number",
    },
    {
      field: "newMotorPower",
      width: 170,
      renderHeader: () => <TableHeader headerName="New Motor Power (HP/KW)" />,
      type: "number",
    },
    {
      field: "energySavings",
      width: 175,
      type: "number",
      renderHeader: () => <TableHeader headerName="Energy Savings (kWh)" />,
    },
    {
      field: "peakKwReduction",
      width: 180,
      type: "number",
      renderHeader: () => (
        <TableHeader headerName="Peak Demand Reduction (kW)" />
      ),
    },
    {
      field: "energyCostSavings",
      width: 160,
      renderHeader: () => (
        <TableHeader
          headerName={`Energy Cost Savings (${props.project.currencySymbol})`}
        />
      ),
      type: "number",
      renderCell: (params: GridValueGetterParams) =>
        props.project.formatMoney(params.row.energyCostSavings),
    },
    {
      field: "demandChargeReduction",
      width: 165,
      renderHeader: () => (
        <TableHeader
          headerName={`Peak Demand Savings (${props.project.currencySymbol})`}
        />
      ),
      type: "number",
      renderCell: (params: GridValueGetterParams) =>
        props.project.formatMoney(params.row.demandChargeReduction),
    },
    {
      field: "priceAfterRebates",
      width: 165,
      renderHeader: () => (
        <TableHeader
          headerName={`Price After Rebates (${props.project.currencySymbol})`}
        />
      ),
      type: "number",
      renderCell: (params: GridValueGetterParams) =>
        props.project.formatMoney(params.row.priceAfterRebates),
    },
    {
      field: "simplePayback",
      width: 180,
      renderHeader: () => <TableHeader headerName="Simple Payback (Years)" />,
      type: "number",
    },
    {
      field: "tonnage",
      width: 130,
      renderHeader: () => <TableHeader headerName="Tonnage" />,
      type: "number",
    },
    {
      field: "fanControl",
      width: 165,
      renderHeader: () => <TableHeader headerName="Fan Control" />,
    },
    {
      field: "loadingPercentage",
      width: 150,
      renderHeader: () => <TableHeader headerName="Loading %" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "vacantMonths",
      width: 180,
      renderHeader: () => <TableHeader headerName="Vacant Months" />,
      type: "number",
    },
    {
      field: "nightTimeSetback",
      width: 140,
      renderHeader: () => <TableHeader headerName="Nighttime Setback" />,
    },
    {
      field: "motorSeries",
      width: 120,
      renderHeader: () => <TableHeader headerName="Motor Series" />,
    },
    {
      field: "efficiencyType",
      width: 190,
      renderHeader: () => <TableHeader headerName="Efficiency Type" />,
    },
    {
      field: "frameStandard",
      width: 185,
      renderHeader: () => <TableHeader headerName="Frame Standard" />,
    },
    {
      field: "frame",
      width: 110,
      renderHeader: () => <TableHeader headerName="Frame" />,
    },
    {
      field: "shaftDiameter",
      width: 180,
      renderHeader: () => <TableHeader headerName="Shaft Diameter" />,
    },
    {
      field: "rpm",
      width: 100,
      renderHeader: () => <TableHeader headerName="RPM" />,
      type: "number",
    },
    {
      field: "voltage",
      width: 125,
      renderHeader: () => <TableHeader headerName="Voltage" />,
      type: "number",
    },
    {
      field: "hz",
      width: 90,
      renderHeader: () => <TableHeader headerName="Hz" />,
      type: "number",
    },
    {
      field: "wkBaseStart",
      width: 150,
      renderHeader: () => <TableHeader headerName="Wk Base Start" />,
    },
    {
      field: "wkBaseEnd",
      width: 150,
      renderHeader: () => <TableHeader headerName="Wk Base End" />,
    },
    {
      field: "satBaseStart",
      width: 150,
      renderHeader: () => <TableHeader headerName="Sat Base Start" />,
    },
    {
      field: "satBaseEnd",
      width: 150,
      renderHeader: () => <TableHeader headerName="Sat Base End" />,
    },
    {
      field: "sunBaseStart",
      width: 150,
      renderHeader: () => <TableHeader headerName="Sun Base Start" />,
    },
    {
      field: "sunBaseEnd",
      width: 150,
      renderHeader: () => <TableHeader headerName="Sun Base End" />,
    },
    {
      field: "wkTtStart",
      width: 165,
      renderHeader: () => <TableHeader headerName="Wk Turntide Start" />,
    },
    {
      field: "wkTtEnd",
      width: 165,
      renderHeader: () => <TableHeader headerName="Wk Turntide End" />,
    },
    {
      field: "satTtStart",
      width: 165,
      renderHeader: () => <TableHeader headerName="Sat Turntide Start" />,
    },
    {
      field: "satTtEnd",
      width: 165,
      renderHeader: () => <TableHeader headerName="Sat Turntide End" />,
    },
    {
      field: "sunTtStart",
      width: 165,
      renderHeader: () => <TableHeader headerName="Sun Turntide Start" />,
    },
    {
      field: "sunTtEnd",
      width: 165,
      renderHeader: () => <TableHeader headerName="Sun Turntide End" />,
    },
    {
      field: "basePercentSpeedMode1",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Speed Mode 1" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentSpeedMode2",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Speed Mode 2" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentSpeedMode3",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Speed Mode 3" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentSpeedMode4",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Speed Mode 4" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentSpeedMode5",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Speed Mode 5" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentSpeedMode1",
      width: 165,
      renderHeader: () => <TableHeader headerName="Turntide % Speed Mode 1" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentSpeedMode2",
      width: 165,
      renderHeader: () => <TableHeader headerName="Turntide % Speed Mode 2" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentSpeedMode3",
      width: 165,
      renderHeader: () => <TableHeader headerName="Turntide % Speed Mode 3" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentSpeedMode4",
      width: 165,
      renderHeader: () => <TableHeader headerName="Turntide % Speed Mode 4" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentSpeedMode5",
      width: 165,
      renderHeader: () => <TableHeader headerName="Turntide % Speed Mode 5" />,
      valueFormatter: percentValueFormatter,
      type: "number",
    },
    {
      field: "basePercentTimeMode1",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Time Mode 1" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentTimeMode2",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Time Mode 2" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentTimeMode3",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Time Mode 3" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentTimeMode4",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Time Mode 4" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "basePercentTimeMode5",
      width: 165,
      renderHeader: () => <TableHeader headerName="Base % Time Mode 5" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentTimeMode1",
      width: 160,
      renderHeader: () => <TableHeader headerName="Turntide % Time Mode 1" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentTimeMode2",
      width: 160,
      renderHeader: () => <TableHeader headerName="Turntide % Time Mode 2" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentTimeMode3",
      width: 160,
      renderHeader: () => <TableHeader headerName="Turntide % Time Mode 3" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentTimeMode4",
      width: 160,
      renderHeader: () => <TableHeader headerName="Turntide % Time Mode 4" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
    {
      field: "ttPercentTimeMode5",
      width: 160,
      renderHeader: () => <TableHeader headerName="Turntide % Time Mode 5" />,
      type: "number",
      valueFormatter: percentValueFormatter,
    },
  ];
  const [result, setResult] = React.useState({
    rows: new Array<EquipmentData>(),
    hasWarning: false,
  });
  const [errors, setErrors] = React.useState<Error[]>([]);
  const [uploading, setUploading] = React.useState(false);
  const { disablePricingButton, onEquipmentStatusUpdate } = props;
  const projectId = props.project.id;
  const [loading, setLoading] = React.useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();
  const theme = useTheme();
  const classes = tableStyles5(theme);
  const [selectionModel, setSelectionModel] = React.useState<string[]>([]);
  const menuStyle = useMenuStyles({
    footerText: `${selectionModel.length} motors included out of ${result.rows.length} total in ROI`,
  });
  const [displayActiveEquipmentsOnly, setDisplayActiveEquipmentsOnly] =
    useState(false);

  const handleDisplay = () => {
    setDisplayActiveEquipmentsOnly(!displayActiveEquipmentsOnly);
  };

  const transformResult = useCallback(
    (rows: EquipmentDataRow[]) => {
      let dataRows: EquipmentData[] = [];
      let hasInValidationSkus = false;
      rows.forEach((d: EquipmentDataRow) => {
        const data = new EquipmentData();
        data.setEquipmentData(d);
        dataRows.push(data);
      });
      hasInValidationSkus = dataRows.some(
        (d) => d.availability === Availability.InValidation
      );
      setResult({
        rows: dataRows,
        hasWarning: hasInValidationSkus,
      });
      setSelectionModel(
        dataRows.filter((row) => row.includeInRoi).map((row) => row.id)
      );
      if (dataRows.filter((row) => row.frame === "56Y").length) {
        const action = (key: string) => (
          <IconButton
            aria-label="close"
            onClick={() => closeSnackbar(key)}
            title="Close message"
          >
            <i className="fal fas fa-times"></i>
          </IconButton>
        );
        enqueueSnackbar(
          <Typography component={"span"}>
            The 56Y Frame motor can have the following motor SKU options,
            depending on the shaft diameter and shaft length combination:
            <ul>
              <li>{`If shaft diameter is 5/8" and shaft length is > 1-7/8", -NA or -A motor SKU is required`}</li>
              <li>{`If shaft diameter is 5/8" and shaft length is <1-7/8", -NC or -C motor SKU is required`}</li>
              <li>{`If shaft diameter is 7/8" and shaft length is > 2-1/4", -NA or -A motor SKU + KIT-ADAP-001 are required`}</li>
              <li>{`If shaft diameter is 7/8" and shaft length is < 2-1/4", -ND or -D motor SKU is required`}</li>
            </ul>
          </Typography>,
          {
            variant: "warning",
            persist: true,
            action,
          }
        );
      }
    },
    [closeSnackbar, enqueueSnackbar]
  );

  useEffect(() => {
    if (projectId && projectId !== "") {
      const getEquipments = async () => {
        setLoading(true);
        getResource<{ data: EquipmentDataRow[] }>(
          `${window.APP_CONFIG.API_HOST}/equipment_motors?project_id=${projectId}`,
          auth
        )
          .then(({ data }) => {
            setLoading(false);
            if (data.length > 0) {
              disablePricingButton(false);
              transformResult(data);
            }
          })
          .catch((error) => {
            enqueueSnackbar(
              `There was an error retrieving equipments data: ${error}`,
              {
                variant: "error",
              }
            );
          });
      };
      getEquipments();
    }
  }, [enqueueSnackbar, projectId, disablePricingButton, auth, transformResult]);

  const onChange = (f: File | null) => {
    if (f != null) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", f);
      formData.append("filename", f.name);
      uploadFile<{ data: EquipmentDataRow[] }>(
        `${window.APP_CONFIG.API_HOST}/equipment_motors?project_id=${projectId}`,
        auth,
        formData
      )
        .then(({ data }) => {
          if (data.length > 0) {
            disablePricingButton(false);
            setErrors([]);
            transformResult(data);
          }
        })
        .catch((e) => {
          setUploading(false);
          setErrors(e.errors);
        });
    }
  };

  type IntervalFunction = () => unknown | void;

  // Typescript version of the custom hook implemented here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  function useInterval(callback: IntervalFunction, delay: number | null) {
    const savedCallback = useRef<IntervalFunction | null>(null);

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    });

    // Set up the interval.
    useEffect(() => {
      function tick() {
        if (savedCallback.current !== null) {
          savedCallback.current();
        }
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const pollStatus = async () => {
    if (uploading) {
      getResource<{ data: { project: Project } }>(
        `${window.APP_CONFIG.API_HOST}/projects/${projectId}`,
        auth
      )
        .then(({ data }) => {
          if (data.project.status === "EQUIPMENT_LIST_UPLOADED") {
            setUploading(false);
            getResource<{ data: EquipmentDataRow[] }>(
              `${window.APP_CONFIG.API_HOST}/equipment_motors?project_id=${projectId}`,
              auth
            )
              .then(({ data }) => {
                setLoading(false);
                if (data.length > 0) {
                  disablePricingButton(false);
                  setErrors([]);
                  transformResult(data);
                }
              })
              .catch((error) => {
                enqueueSnackbar(
                  `There was an error retrieving equipments data: ${error}`,
                  {
                    variant: "error",
                  }
                );
              });
          }
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error calculating efficiency: ${error}`,
            {
              variant: "error",
            }
          );
        });
    }
  };

  useInterval(
    () => {
      pollStatus();
    },
    uploading ? 5000 : null
  );

  const styles = useStyles(theme);

  const downloadTemplate = async (templateType: string) => {
    const requestHeaders = {
      Authorization: `Bearer ${await auth.getToken()}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    };

    const options = {
      headers: requestHeaders,
      responseType: "blob",
    };
    const endColumn = templateType === "advanced" ? "BB" : "AH";
    fetch(
      `${window.APP_CONFIG.API_HOST}/equipment_template?template_type=${templateType}`,
      options
    )
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        XlsxPopulate.fromDataAsync(buffer)
          .then((workbook: any) => {
            const values = result.rows.map(
              ({ id, identifier, availability, ...item }) => {
                item.manufacturer = capitalize(item.manufacturer);
                item.efficiencyType = capitalize(
                  item.efficiencyType.toLowerCase()
                );
                item.fanType = capitalize(item.fanType);
                return Object.values(item);
              }
            );
            const r = workbook
              .sheet(0)
              .range(`A6:${endColumn}${result.rows.length + 5}`);
            r.value(values);
            return workbook.outputAsync();
          })
          .then((data: Blob | MediaSource) => {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${projectId}-equipments.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((err: any) =>
            enqueueSnackbar(`Something went wrong: ${err}`, {
              variant: "error",
            })
          );
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (uploading) {
    return (
      <Box sx={styles.root}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={menuStyle.root}>
      <Grid
        container
        spacing={0}
        mt={1}
        mb={1}
        mr={0}
        justifyContent="space-between"
        alignItems={"flex-start"}
      >
        <Grid item xs={9}>
          <FileUpload
            value={null}
            onChange={onChange}
            sx={classes.button}
            name="equipment_sheet"
            disabled={false}
          />
          <Button
            variant="outlined"
            color="primary"
            sx={classes.button}
            aria-haspopup="true"
            onClick={handleClick}
          >
            {result.rows.length === 0
              ? "Download Template"
              : "Download Excel Form"}
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              style={{ color: "#006BA6" }}
              onClick={() => downloadTemplate("standard")}
            >
              Download Standard{" "}
              {result.rows.length === 0 ? "Template" : "Excel Form"}
            </MenuItem>

            <MenuItem
              style={{ color: "#006BA6" }}
              onClick={() => downloadTemplate("advanced")}
            >
              Download Advanced{" "}
              {result.rows.length === 0 ? "Template" : "Excel Form"}
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <DefinitionsModal variant="EquipmentData" />
        </Grid>
        <Grid item xs={8} sx={{ verticalAlign: "top" }}>
          <FormsChangedBanner />
        </Grid>
        {result.rows.length > 0 && (
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <FormGroup row>
              <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                  <CustomCheckbox
                    disableRipple
                    checked={displayActiveEquipmentsOnly}
                    onChange={handleDisplay}
                  />
                }
                label="Display only active motors"
              />
            </FormGroup>
          </Box>
        )}
      </Grid>
      {errors?.length > 0 ? (
        <UploadErrorDetail errors={errors} title="Equipment Template Errors" />
      ) : (
        result.rows.length > 0 && (
          <DataTable
            rows={result.rows.filter(
              (eq: EquipmentData) =>
                !displayActiveEquipmentsOnly ||
                (displayActiveEquipmentsOnly && eq.includeInRoi)
            )}
            columns={columns}
            pageSize={50}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              const newActiveMotorIds = newSelectionModel.filter(
                (motorId) => !selectionModel.includes(motorId as string)
              ) as string[];
              const newInactiveMotorIds = selectionModel.filter(
                (motorId) => !newSelectionModel.includes(motorId as string)
              ) as string[];
              setSelectionModel(newSelectionModel as string[]);
              onEquipmentStatusUpdate(newActiveMotorIds, newInactiveMotorIds);
            }}
            selectionModel={selectionModel}
            autoHeight
          />
        )
      )}
    </Box>
  );
}
