import React, { useState } from "react";
import { DialogTitle } from "modules/common/components/dialog/DialogTitle";
import {
  Box,
  Button,
  DialogContent,
  Divider,
  MenuItem,
  TextField,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { Organization } from "types/Organization";
import { validateEmail } from "utils/helpers";
import { UserFormStyles } from "./styles";

interface AddUserProps extends WithStyles<typeof UserFormStyles> {
  open: boolean;
  onClose: () => void;
  addUser: (email: string, organization: string) => void;
  organizations: Organization[];
}

function AddUserDialogComponent({
  open,
  onClose,
  addUser,
  organizations,
  classes,
}: AddUserProps) {
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState(organizations[0]?.id || "");
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle onClose={onClose}>
        <Box ml={1}>
          <Typography style={{ fontWeight: 600 }} variant="h5">
            New User
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box className={classes.root}>
          <Box mb={3} mt={3}>
            <Typography variant="h6">User Properties</Typography>
          </Box>
          <label>
            <Box mb={1}>
              <Typography>Email Address</Typography>
            </Box>
            <TextField
              required
              type="text"
              variant="outlined"
              className="input"
              label="Required"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <Box mt={3}>
            <label>
              <Box mb={1}>
                <Typography>Organization</Typography>
              </Box>
              <TextField
                select
                value={organization}
                onChange={(event) => setOrganization(event.target.value)}
                variant="outlined"
                className="input"
              >
                {organizations.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    selected={option.id === organization}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </label>
          </Box>
          <Box pt={3} display="flex" justifyContent="flex-end">
            <Button
              disableRipple={true}
              color="primary"
              variant="contained"
              onClick={() => addUser(email, organization)}
              disabled={!validateEmail(email)}
            >
              Add
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export const AddUserDialog = withStyles(UserFormStyles)(AddUserDialogComponent);
