import { Error } from "types/Error";
import { Box, Grid } from "@material-ui/core";
import * as React from "react";

type ErrorProp = {
  title: string;
  errors: Error[];
};

export const UploadErrorDetail = ({ errors, title }: ErrorProp) => {
  return (
    <Grid item>
      <Box
        fontSize={16}
        fontWeight="bold"
        p={2}
        bgcolor="error.main"
        color="error.contrastText"
      >
        <Box textAlign="center">{title}</Box>
        <Box mt={2}>Please fix the following error(s) and re-upload:</Box>
        {errors.map((e: Error) => (
          <Box ml={2} mt={2} key={e.detail}>
            <Box>{`Row: ${e.row} | Field: ${e.source} | Error: ${e.title} | Detail: ${e.detail}`}</Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export const ErrorDetail = ({ errors, title }: ErrorProp) => {
  return (
    <Grid item>
      <Box
        fontSize={16}
        fontWeight="bold"
        p={2}
        bgcolor="error.main"
        color="error.contrastText"
      >
        <Box textAlign="center">{title}</Box>
        {errors.map((e: Error) => (
          <Box ml={2} mt={2} key={e.detail}>
            <Box>{`${e.source ? "Field: " + e.source + " | " : ""} Error: ${
              e.title || e.message
            }${e.detail ? " | Detail: " + e.detail : ""}`}</Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};
