import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[400],
      "&.MuiIconButton-root:hover": {
        background: "transparent",
      },
      "& .fas": {
        fontSize: "14px",
      },
    },
    background: {
      background: "#FFFACC",
    },
  });
