import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { ExchangeRate } from "types/ExchangeRate";
import { Currency } from "models/currency";

export const useFetchCurrencies = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingCurrencies, setLoading] = React.useState(true);
  const [currencyData, setCurrencies] = React.useState<Currency[]>([]);
  React.useEffect(() => {
    const getCurrencies = async () => {
      setLoading(true);
      getResource<{ data: ExchangeRate[] }>(
        `${window.APP_CONFIG.API_HOST}/currencies`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          const currencies = data.map((exchangeRate: ExchangeRate) => {
            const r = new Currency();
            r.setExchangeRate(exchangeRate);
            return r;
          });
          setCurrencies(currencies);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving currencies. ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getCurrencies();
  }, [enqueueSnackbar, auth]);
  return {
    loadingCurrencies,
    currencyData,
  };
};
