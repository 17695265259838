import * as React from "react";
import { ReactNode, useContext } from "react";
import { AuthService } from "./AuthServicePKCE";

const AuthContext = React.createContext<AuthService>({} as AuthService);

interface AuthProviderProps {
  authService: AuthService;
  children: ReactNode;
}

export function AuthProvider({ authService, children }: AuthProviderProps) {
  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
