import React from "react";
import { Link, Grid, Theme, withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { DialogTitle } from "./DialogTitle";

interface ChangeLogContent {
  modalTitle: string;
  rows: { date: string; change: string }[];
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px ${theme.spacing(
      8
    )}px`,
    "& .MuiGrid-item": {
      width: "100%",
      borderBottom: "1px solid #EEEDE8",
      padding: `${theme.spacing(3)}px 0px`,
    },
  },
}))(MuiDialogContent);

export function ChangeLogModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getContent = () => {
    let content: ChangeLogContent = { modalTitle: "", rows: [] };
    content.modalTitle = "Change Log";
    content.rows = [
      {
        date: "6/27/23",
        change: `Project Management and FDD are no longer in Project Options. 
           Instead, the PM labor hours, hourly rate, and survey costs can be set in "PM + Survey Costs" under "Pricing and Rebates".`,
      },
      {
        date: "6/15/23",
        change: "Support businesses that close after midnight",
      },
      {
        date: "5/10/23",
        change: "Include cooling penalties in kWh saved for motor rebates",
      },
      {
        date: "5/4/23",
        change: `We have revamped extended warranties.
           Warranty length selection (0-4 years) has moved from Project Options to the Accessories page,
           where extended warranty SKUs are listed in a new "Subscriptions & Extended Warranties" table.`,
      },
      {
        date: "4/19/23",
        change: "New equipment template. Phase is no longer a required input.",
      },
      {
        date: "4/5/23",
        change:
          "Update baseline fan load calculations for better accuracy. Version updated to 1.7.12",
      },
      {
        date: "3/21/23",
        change:
          "Update fan affinity formulas for more accuracy. Version updated to 1.7.11",
      },
      {
        date: "9/26/22",
        change:
          "Site ID is now included in the Site dropdown for Accessory Pricing.",
      },
      {
        date: "9/21/22",
        change: `Edit Site dialog: To make edits to an individual site, click on a row in the site data table and hit save to confirm changes.
          The 4 fields that are editable via this method are:
          Utility Rate,
          Peak Demand Charge,
          Tax Rate, and CO₂e Grid Intensity.`,
      },
      {
        date: "9/1/22",
        change: `Motors tab in BOM Excel export: The Bill of Materials Excel export now includes a "Motors" tab that summarizes SKUs and pricing for each RTU.`,
      },
      {
        date: "6/21/22",
        change:
          "If not included, default rebates for sites will be added based on state and county.",
      },
      {
        date: "6/9/22",
        change:
          "Site Report dialog: from the detailed summary, click a site name to see details including any associated satellite imagery.",
      },
      {
        date: "6/8/22",
        change:
          "56Z/56HZ/143T/145T broken up to into two different options 56Z/56HZ and 143T/145T. 56Z/56HZ and 56/56H will return a C frame sku with given shaft ⅝ diameter. 56Z/56HZ and 56/56H will return a D frame sku with given shaft ⅞ diameter. 56Z/56HZ and 56/56H will return an X frame sku if no shaft diameter provided.",
      },
      {
        date: "5/27/22",
        change: "Co-branded PDF reports available for select organizations.",
      },
      {
        date: "5/16/22",
        change:
          "You can request a satellite estimate from HI4AI via the Request Satellite Estimate button on the site step.",
      },
      {
        date: "5/11/22",
        change: "Address auto complete with the add site modal.",
      },
      {
        date: "5/09/22",
        change: "Support for EUR, CLP, and COP currencies.",
      },
      {
        date: "4/27/22",
        change:
          "Equipment templates now have the ability select 0 tonnage, for fans without compressors. Please use the updated templates.",
      },
      {
        date: "3/30/22",
        change:
          "You can now add individual sites via the Add Site button the site step.",
      },
      {
        date: "3/24/22",
        change:
          "Major template update for site and equipment to prioritize important fields. Street address now required for sites.",
      },
      {
        date: "3/4/22",
        change:
          "CO2e now is looked up from address rather than electrical grid. Manual override of this value in g/kWH can be input via the site template",
      },
      {
        date: "3/1/22",
        change:
          "Climate zone is now optional. Address entered will be used to lookup climate zone if no climate zone is provided.",
      },
      {
        date: "1/5/22",
        change:
          "Equipment templates now have the ability to mark a motor as active or inactive in the first column. Please use the updated templates.",
      },
      {
        date: "1/4/22",
        change:
          "Summary now displays % energy savings for the full equipment rather than the motor only percent savings. Details of motor energy savings are available in the Excel summary.",
      },
      {
        date: "1/3/22",
        change:
          "Added peak demand charge to the site template. Please use new site templates. Utility cost made up of the energy savings and peak demand charge reduction savings.",
      },
      {
        date: "12/22/21",
        change: "Updated base efficiency curves. New logic version is 1.7.10",
      },
      {
        date: "10/26/21",
        change: "Ability to invite users and edit roles implemented.",
      },
      {
        date: "10/22/21",
        change: "Annual operating hours added to per motor summary in excel",
      },
      {
        date: "10/20/21",
        change:
          "Support for new advanced equipment template upload. This allows the ability to override time and speeds in each mode.",
      },
      {
        date: "10/8/21",
        change:
          "New equipment template with support for IEC frame motors. You can also select the motor power of your retrofit motor using the new motor power column.",
      },
      {
        date: "9/30/21",
        change: "Support for GBP currency.",
      },
      {
        date: "9/21/21",
        change: "Added utility rate to site and motor summaries",
      },
      {
        date: "9/17/21",
        change: "Per motor summary added to the summary excel dump.",
      },
      {
        date: "7/28/21",
        change:
          "Added shaft diameter column to equipment template. This is used for selecting the correct sku for 56/56H frames.",
      },
      {
        date: "7/21/21",
        change:
          "Schedule fields moved from per site to per equipment. You will need to use the new templates for all projects.",
      },
      {
        date: "7/21/21",
        change: "Implemented ability to archive projects.",
      },
      {
        date: "7/20/21",
        change: "New sku selection for new sku consolidation rules.",
      },
      {
        date: "7/8/21",
        change:
          "New balance points for building types updated. ROI calculation impacted and incremented to 1.7.9",
      },
      {
        date: "7/7/21",
        change:
          "Ability to apply discounts implemented for Motors, Accessories and Project Management tabs. ",
      },
      {
        date: "7/7/21",
        change: "Ability to add accessories implemented.",
      },
      {
        date: "6/14/21",
        change:
          "SKUs included in error message when the SKU is not found or is in development.",
      },
      {
        date: "6/3/21",
        change: "Peak kW reduction value added to all project summaries.",
      },
      {
        date: "6/1/21",
        change:
          "New motor efficiency curves implemented for ROI calculation. Logic version upgraded from 1.7.7 to 1.7.8",
      },
      {
        date: "5/24/21",
        change:
          "New site template. Please download the latest. Optional labor rate per hour column added for each site. If nothing is entered, the default value will be $160.",
      },
      {
        date: "5/21/21",
        change: "Change Log created to track changes made to the web app",
      },
    ];

    return content;
  };
  const content = getContent();
  return (
    <div>
      <Link
        style={{ color: "black", textDecoration: "none" }}
        component="button"
        variant="body2"
        onClick={handleClickOpen}
      >
        Change Log
      </Link>
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle onClose={handleClose}>{content.modalTitle}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography color="textSecondary" variant="overline">
                Date
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color="textSecondary" variant="overline">
                Changes
              </Typography>
            </Grid>
            {content.rows.map((r) => (
              <React.Fragment key={r.date}>
                <Grid item xs={4}>
                  <Typography variant="body1">{r.date}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{r.change}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
