import { EquipmentDataRow } from "types/EquipmentDataRow";

export enum Availability {
  GeneralAvailability = "GA",
  InValidation = "IN_VALIDATION",
}

export class EquipmentData {
  [key: string]: any; // Needed for dynamic property access
  // This order matters for dump to excel template
  id = "";
  identifier = "";
  active = "";
  siteId = "";
  unitName = "";
  manufacturer = "";
  modelNumber = "";
  fanType = "";
  vfd = "";
  motorPower = 0;
  newMotorPower = 0;
  tonnage = 0;
  fanControl = "";
  loadingPercentage = 0;
  vacantMonths = "";
  nightTimeSetback = "";
  motorSeries = "";
  efficiencyType = "";
  frameStandard = "";
  frame = "";
  shaftDiameter = "";
  rpm = 0;
  voltage = 0;
  hz = 0;
  availability = Availability.GeneralAvailability;
  wkBaseStart = "";
  wkBaseEnd = "";
  satBaseStart = "";
  satBaseEnd = "";
  sunBaseStart = "";
  sunBaseEnd = "";
  wkTtStart = "";
  wkTtEnd = "";
  satTtStart = "";
  satTtEnd = "";
  sunTtStart = "";
  sunTtEnd = "";
  basePercentSpeedMode1 = 0;
  basePercentSpeedMode2 = 0;
  basePercentSpeedMode3 = 0;
  basePercentSpeedMode4 = 0;
  basePercentSpeedMode5 = 0;
  ttPercentSpeedMode1 = 0;
  ttPercentSpeedMode2 = 0;
  ttPercentSpeedMode3 = 0;
  ttPercentSpeedMode4 = 0;
  ttPercentSpeedMode5 = 0;
  basePercentTimeMode1 = 0;
  basePercentTimeMode2 = 0;
  basePercentTimeMode3 = 0;
  basePercentTimeMode4 = 0;
  basePercentTimeMode5 = 0;
  ttPercentTimeMode1 = 0;
  ttPercentTimeMode2 = 0;
  ttPercentTimeMode3 = 0;
  ttPercentTimeMode4 = 0;
  ttPercentTimeMode5 = 0;
  includeInRoi = true;
  simplePayback = 0;
  energySavings = 0;
  peakKwReduction = 0;
  energyCostSavings = 0;
  demandChargeReduction = 0;
  priceAfterRebates = 0;
  runHours = 0;

  setEquipmentData(e: EquipmentDataRow) {
    this.id = e.id;
    this.siteId = e.site_identifier;
    this.identifier = e.id;
    this.unitName = e.equipment_unit_name;
    this.manufacturer = e.equipment_manufacturer;
    this.modelNumber = e.equipment_model;
    this.fanType = e.fan_type;
    this.frameStandard = e.frame_standard;
    this.motorPower = e.motor_power;
    this.newMotorPower = e.new_motor_power;
    this.frame = e.frame;
    this.shaftDiameter = e.shaft_diameter;
    this.rpm = e.rpm;
    this.voltage = e.voltage;
    this.hz = e.hz;
    this.tonnage = e.equipment_tonnage;
    this.vfd = e.vfd;
    this.motorSeries = e.motor_series;
    this.efficiencyType = e.efficiency_type;
    this.loadingPercentage = e.loading_percent;
    this.fanControl = e.equipment_fan_control;
    this.availability = e.availability as Availability;
    this.wkBaseStart = e.equipment_wk_base_start;
    this.wkBaseEnd = e.equipment_wk_base_end;
    this.satBaseStart = e.equipment_sat_base_start;
    this.satBaseEnd = e.equipment_sat_base_end;
    this.sunBaseStart = e.equipment_sun_base_start;
    this.sunBaseEnd = e.equipment_sun_base_end;
    this.wkTtStart = e.equipment_wk_tt_start;
    this.wkTtEnd = e.equipment_wk_tt_end;
    this.satTtStart = e.equipment_sat_tt_start;
    this.satTtEnd = e.equipment_sat_tt_end;
    this.sunTtStart = e.equipment_sun_tt_start;
    this.sunTtEnd = e.equipment_sun_tt_end;
    this.vacantMonths = this.transformVacantMonths(e.equipment_vacant_months);
    this.nightTimeSetback = e.equipment_night_time_setback;
    this.basePercentTimeMode1 = e.equipment_base_percent_time_mode_1;
    this.basePercentTimeMode2 = e.equipment_base_percent_time_mode_2;
    this.basePercentTimeMode3 = e.equipment_base_percent_time_mode_3;
    this.basePercentTimeMode4 = e.equipment_base_percent_time_mode_4;
    this.basePercentTimeMode5 = e.equipment_base_percent_time_mode_5;
    this.ttPercentTimeMode1 = e.equipment_tt_percent_time_mode_1;
    this.ttPercentTimeMode2 = e.equipment_tt_percent_time_mode_2;
    this.ttPercentTimeMode3 = e.equipment_tt_percent_time_mode_3;
    this.ttPercentTimeMode4 = e.equipment_tt_percent_time_mode_4;
    this.ttPercentTimeMode5 = e.equipment_tt_percent_time_mode_5;
    this.basePercentSpeedMode1 = e.base_percent_speed_mode_1;
    this.basePercentSpeedMode2 = e.base_percent_speed_mode_2;
    this.basePercentSpeedMode3 = e.base_percent_speed_mode_3;
    this.basePercentSpeedMode4 = e.base_percent_speed_mode_4;
    this.basePercentSpeedMode5 = e.base_percent_speed_mode_5;
    this.ttPercentSpeedMode1 = e.tt_percent_speed_mode_1;
    this.ttPercentSpeedMode2 = e.tt_percent_speed_mode_2;
    this.ttPercentSpeedMode3 = e.tt_percent_speed_mode_3;
    this.ttPercentSpeedMode4 = e.tt_percent_speed_mode_4;
    this.ttPercentSpeedMode5 = e.tt_percent_speed_mode_5;
    this.includeInRoi = e.status === "ACTIVE";
    this.active = e.status === "ACTIVE" ? "Y" : "N";
    this.energySavings = e.energy_savings;
    this.simplePayback = e.simple_payback;
    this.peakKwReduction = e.peak_kw_reduction;
    this.energyCostSavings = e.energy_cost_savings;
    this.demandChargeReduction = e.demand_charge_reduction;
    this.priceAfterRebates = e.price_after_rebates;
    this.runHours = e.tt_annual_operating_hours;
  }

  transformVacantMonths(months: number[]) {
    const monthsDict: { [key: number]: string } = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };

    let result = "";
    months.forEach((m: number) => {
      result = result + monthsDict[m] + ", ";
    });
    return result.substring(0, result.length - 2);
  }
}
