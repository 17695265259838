import { SearchResultAddress } from "types/SearchResultAddress";

/** Adapter class to encapsulate mapping a SearchResultAddress to SiteProps */
export class SiteAddress {
  address: SearchResultAddress;

  constructor(address: SearchResultAddress) {
    this.address = address;
  }

  get street_addr(): string {
    const streetNumber =
      this.address.streetNumber || this.address.buildingNumber || "";
    return (
      this.address.streetNameAndNumber ||
      `${streetNumber}${streetNumber ? " " : ""}${
        this.address.streetName || ""
      }` ||
      this.address.freeformAddress ||
      ""
    );
  }

  get city(): string {
    return this.address.municipality || "";
  }

  get state(): string {
    return this.address.countrySubdivision || "";
  }
  get country(): string {
    return (
      this.address.countryCode ||
      this.address.countryCodeISO3 ||
      this.address.country ||
      ""
    );
  }

  get zipcode(): string {
    return (
      (this.address.countryCode === "CA" // For Canada, try to return e.g. "M1M 1M1" rather than just "M1M"
        ? this.address.extendedPostalCode || this.address.postalCode
        : this.address.postalCode) || ""
    ); // For USA, just return the 5-character zip code
  }
}
