import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorDetail } from "../../../common/components/ErrorDetail";
import { Error } from "../../../../types/Error";
import {
  SearchResultAddress,
  DEFAULT_SEARCH_RESULT_ADDRESS,
} from "types/SearchResultAddress";
import { SiteAddress } from "models/siteAddress";
import { AddressAutocomplete } from "./AddressAutocomplete";

const BUILDING_TYPES = [
  "Data Center",
  "Restaurant",
  "Office/Hotel/School",
  "Retail",
  "Supermarket",
  "Industrial/Warehouse",
];

export type SiteProps = {
  identifier: string;
  name: string;
  street_addr: string;
  building_type: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

type AddSiteProps = {
  addSite: (
    siteProps: SiteProps,
    closeModal: () => void,
    setModalErrors: (arg: Array<any>) => void
  ) => void;
  onCancel: () => void;
};

export function AddSiteModal({ addSite, onCancel }: AddSiteProps) {
  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState<Error[]>([]);
  const [name, setSiteName] = useState("");
  const [address, setSearchResultAddress] = useState<SearchResultAddress>(
    DEFAULT_SEARCH_RESULT_ADDRESS
  );
  const [identifier, setSiteIdentifier] = useState("");
  const [city, setCity] = useState("");
  const [state, setAddrState] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
    onCancel();
  };

  const onSave = () => {
    setButtonsDisabled(true);
    const siteAddress = new SiteAddress(address);
    addSite(
      {
        name,
        identifier,
        building_type: buildingType,
        street_addr: siteAddress.street_addr,
        city: siteAddress.city || city,
        state: siteAddress.state || state,
        country: siteAddress.country || country,
        zipcode: siteAddress.zipcode || zipcode,
      },
      handleClose,
      (errors) => {
        setErrors(errors);
        setButtonsDisabled(false);
      }
    );
  };

  const setAddress = (address: SearchResultAddress) => {
    const siteAddress = new SiteAddress(address);

    // use the search result if available;
    // otherwise grab from the text input controls
    setCity(siteAddress.city);
    setAddrState(siteAddress.state);
    setCountry(siteAddress.country);
    setZipCode(siteAddress.zipcode);

    setSearchResultAddress(address);
  };
  return (
    <div>
      <Dialog onClose={handleCancel} open={open} fullWidth>
        <DialogTitle style={{ textAlign: "center" }}>
          Add Site Details
        </DialogTitle>
        <DialogContent>
          <form autoComplete="off">
            <label htmlFor="siteName">
              <Box mb={1}>
                <Typography>Site Name</Typography>
              </Box>
              <TextField
                value={name}
                required
                onChange={(event) => setSiteName(event.target.value)}
                id="site-name"
                type="text"
                variant="outlined"
                className="input"
                label="Required"
                fullWidth
                autoFocus
              />
            </label>
            <Box mt={3}>
              <label htmlFor="siteId">
                <Box mb={1}>
                  <Typography>Site ID</Typography>
                </Box>
                <TextField
                  value={identifier}
                  required
                  onChange={(event) => setSiteIdentifier(event.target.value)}
                  type="text"
                  variant="outlined"
                  className="input"
                  label="Required"
                  fullWidth
                />
              </label>
            </Box>
            <Box mt={3}>
              <label htmlFor="siteAddress">
                <Box mb={1}>
                  <Typography>Site Address</Typography>
                </Box>
                <AddressAutocomplete
                  setAddress={setAddress}
                  address={address}
                />
              </label>
            </Box>
            <Box mt={3} display="flex">
              <Box mt={3}>
                <label htmlFor="city">
                  <Box mb={1}>
                    <Typography>City</Typography>
                  </Box>
                  <TextField
                    value={city}
                    required
                    onChange={(event) => setCity(event.target.value)}
                    type="text"
                    variant="outlined"
                    className="input"
                    label="Required"
                    style={{ marginRight: "8px" }}
                  />
                </label>
              </Box>
              <Box mt={3}>
                <label htmlFor="state">
                  <Box mb={1}>
                    <Typography>State</Typography>
                  </Box>
                  <TextField
                    value={state}
                    required
                    onChange={(event) => setAddrState(event.target.value)}
                    type="text"
                    variant="outlined"
                    className="input"
                    label="Required"
                    style={{ marginRight: "16px" }}
                  />
                </label>
              </Box>
              <Box mt={3}>
                <label htmlFor="zip">
                  <Box mb={1}>
                    <Typography>Zip/Postal Code</Typography>
                  </Box>
                  <TextField
                    value={zipcode}
                    required
                    onChange={(event) => setZipCode(event.target.value)}
                    type="text"
                    variant="outlined"
                    className="input"
                    label="Required"
                  />
                </label>
              </Box>
            </Box>
            <Box mt={3}>
              <label htmlFor="country">
                <Box mb={1}>
                  <Typography>Country</Typography>
                </Box>
                <TextField
                  value={country}
                  required
                  onChange={(event) => setCountry(event.target.value)}
                  type="text"
                  variant="outlined"
                  className="input"
                  label="Required"
                  fullWidth
                />
              </label>
            </Box>
            <Box mt={3}>
              <label>
                <Box mb={1}>
                  <Typography>Building Type</Typography>
                </Box>
                <Select
                  value={buildingType}
                  onChange={(event) => setBuildingType(event.target.value)}
                  variant="outlined"
                  className="input"
                  fullWidth
                  displayEmpty
                  placeholder="Select"
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Select";
                    }

                    return selected;
                  }}
                  required
                >
                  {BUILDING_TYPES.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      selected={option === buildingType}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </label>
              {errors?.length > 0 && (
                <div>
                  <ErrorDetail errors={errors} title="Add Site Error" />
                </div>
              )}
            </Box>
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                style={{ marginRight: "24px" }}
                variant="outlined"
                onClick={handleCancel}
                disabled={buttonsDisabled}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => onSave()}
                disabled={
                  !name ||
                  !identifier ||
                  !address ||
                  !city ||
                  !state ||
                  !zipcode ||
                  !country ||
                  !buildingType ||
                  buttonsDisabled
                }
              >
                Save
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
