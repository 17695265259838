import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { AssetRebateRow } from "types/AssetRebateRow";
import { AssetRebate } from "models/assetRebate";

export const useFetchAssetRebates = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingAssetRebates, setLoading] = React.useState(true);
  const [aRebates, setAssetRebates] = React.useState<AssetRebate[]>([]);
  React.useEffect(() => {
    const getAssetRebates = async () => {
      setLoading(true);
      getResource<{ data: AssetRebateRow[] }>(
        `${window.APP_CONFIG.API_HOST}/asset_rebates?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          const rebates = data.map((rebate: AssetRebateRow) => {
            const r = new AssetRebate();
            r.setAssetRebate(rebate);
            return r;
          });
          setAssetRebates(rebates);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving asset rebates. ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getAssetRebates();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loadingAssetRebates,
    aRebates,
  };
};
