import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { MotorLineItemRow } from "types/MotorLineItemRow";

export const useFetchMotorLineItems = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingMlis, setLoading] = React.useState(true);
  const [mlis, setMlis] = React.useState<MotorLineItemRow[]>([]);
  React.useEffect(() => {
    const getMotorLineItems = async () => {
      setLoading(true);
      getResource<{ data: MotorLineItemRow[] }>(
        `${window.APP_CONFIG.API_HOST}/motor_line_items?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          setMlis(data);
        })
        .catch((error) => {
          enqueueSnackbar(`There was an error retrieving motors. ${error}`, {
            variant: "error",
          });
          setLoading(false);
        });
    };
    getMotorLineItems();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loadingMlis,
    mlis,
  };
};
