import React from "react";
import {
  Card,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { getResource, isInValidation, formatSkuStatus } from "utils/helpers";
import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import _ from "lodash";
import { AccessoryLineItemRow } from "types/accessoryLineItemRow";
import { MotorLineItemRow } from "types/MotorLineItemRow";
import * as XLSX from "xlsx";
import {
  SimpleTable,
  StyledTableRow,
} from "modules/common/components/table/SimpleTable";
import { Project } from "models/project";

type LineItem = AccessoryLineItemRow & MotorLineItemRow;
type BOMProps = {
  project: Project;
};

export function BillOfMaterials({ project }: BOMProps) {
  const [rows, setRows] = React.useState<LineItem[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [projectName, setProjectName] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  React.useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      setLoading(true);
      getResource<{ data: LineItem[]; project_name: string }>(
        `${window.APP_CONFIG.API_HOST}/bill_of_materials?project_id=${project.id}`,
        auth
      )
        .then(({ data, project_name }) => {
          if (!unmounted) {
            setLoading(false);
            setRows(data);
            setProjectName(project_name);
          }
        })
        .catch((error) => {
          if (!unmounted) {
            enqueueSnackbar(`There was an error retrieving data. ${error}`, {
              variant: "error",
            });
            setLoading(false);
          }
        });
    };
    getData();
    return () => {
      unmounted = true;
    };
  }, [enqueueSnackbar, auth, project.id]);

  const downloadExcel = () => {
    const workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.json_to_sheet(projectSkus());

    workSheet["!cols"] = [{ wpx: 150 }, { wpx: 500 }];

    XLSX.utils.book_append_sheet(workBook, workSheet, "Project SKUs");
    workSheet = XLSX.utils.json_to_sheet(skusbySite());
    workSheet["!cols"] = [
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 100 },
      { wpx: 500 },
    ];
    workSheet["A2"].s = {
      font: { sz: 13, bold: true },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrap_text: true,
      },
    };
    XLSX.utils.book_append_sheet(workBook, workSheet, "SKUs by Site");
    workSheet = XLSX.utils.json_to_sheet(laborByRate());
    XLSX.utils.book_append_sheet(workBook, workSheet, "Labor");
    workSheet = XLSX.utils.json_to_sheet(motorsWithSkus());
    workSheet["!cols"] = [
      { wpx: 200 },
      { wpx: 100 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 500 },
      { wpx: 75 },
      { wpx: 200 },
    ];
    XLSX.utils.book_append_sheet(workBook, workSheet, "Motors");
    XLSX.writeFile(workBook, `Project_${projectName}_BOM.xlsx`);
  };

  const projectSkus = () => {
    return _.map(_.groupBy(rows, "sku")).map((rows: LineItem[]) => {
      return {
        SKU: rows[0].sku,
        Description: rows[0].description,
        "SKU Status": rows[0].availability,
        Quantity: _.sumBy(rows, "quantity"),
      };
    });
  };

  const skusbySite = () => {
    const groupedItems = _.groupBy(rows, (item) => {
      return [item.sku, item.site_identifier];
    });
    return _.map(groupedItems).map((rows: LineItem[]) => {
      return {
        "Site Name": rows[0].site_name,
        SKU: rows[0].sku,
        "SKU Status": rows[0].availability,
        "Site Id": rows[0].site_identifier,
        Description: rows[0].description,
        Quantity: _.sumBy(rows, "quantity"),
        Price: rows[0].override_price
          ? project.formatMoney(rows[0].override_price)
          : project.formatMoney(rows[0].list_price),
      };
    });
  };

  const laborByRate = () => {
    const laborItems = _.filter(rows, obj => _.has(obj, 'labor_rate_hr'));

    return _.map(_.groupBy(laborItems, "labor_rate_hr")).map((rows: LineItem[]) => {
      return {
        "Labor Rate": project.formatMoney(rows[0].labor_rate_hr),
        "Labor Hours": _.sumBy(rows, (row) => {
          return row.labor_hours_per_unit * row.quantity;
        }),
      };
    });
  };

  const motorsWithSkus = () => {
    const motorLineItems = _.filter(rows, (item) => {
      return item.equipment_motors;
    });
    return _.flatMap(motorLineItems, (motorLineItem: MotorLineItemRow) => {
      return _.map(motorLineItem.equipment_motors, (em) => {
        return {
          "Equipment Name": em.equipment_unit_name,
          "Fan Type": em.fan_type,
          SKU: motorLineItem.sku,
          "Site Name": motorLineItem.site_name,
          "Site Id": motorLineItem.site_identifier,
          Description: motorLineItem.description,
          "SKU Status": motorLineItem.availability,
          Price: motorLineItem.override_price
            ? project.formatMoney(motorLineItem.override_price)
            : project.formatMoney(motorLineItem.list_price),
        };
      });
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Box mt={4}>
      <Box mr={3} display="flex" flexDirection="row-reverse">
        <IconButton onClick={() => downloadExcel()}>
          <i className="fas fa-file-excel fa-lg" style={{ color: "green" }}></i>
        </IconButton>
      </Box>

      <Box mb={1}>
        <Typography variant="h6">Project SKUs</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={["SKU", "Description", "SKU Status", "Quantity"]}
            emptyMessage=""
          >
            {projectSkus().map((item) => (
              <StyledTableRow
                isSkuInValidation={isInValidation(item["SKU Status"])}
                key={item.SKU}
              >
                <TableCell>{item.SKU}</TableCell>
                <TableCell>{item.Description}</TableCell>
                <TableCell>{formatSkuStatus(item["SKU Status"])}</TableCell>
                <TableCell>{item.Quantity}</TableCell>
              </StyledTableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
      <Box mb={1} mt={7}>
        <Typography variant="h6">SKUs by Site</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable
            headers={[
              "Site Name",
              "Site ID",
              "SKU",
              "SKU Status",
              "Description",
              "Price (each)",
              "Quantity",
            ]}
            emptyMessage=""
          >
            {skusbySite().map((item) => (
              <StyledTableRow
                isSkuInValidation={isInValidation(item["SKU Status"])}
                key={item.SKU + item["Site Id"]}
              >
                <TableCell>{item["Site Name"]}</TableCell>
                <TableCell>{item["Site Id"]}</TableCell>
                <TableCell>{item.SKU}</TableCell>
                <TableCell>{formatSkuStatus(item["SKU Status"])}</TableCell>
                <TableCell>{item.Description}</TableCell>
                <TableCell>{item.Price}</TableCell>
                <TableCell>{item.Quantity}</TableCell>
              </StyledTableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
      <Box mb={1} mt={7}>
        <Typography variant="h6">Labor</Typography>
      </Box>
      <Box>
        <Card>
          <SimpleTable headers={["Labor Rate", "Labor Hours"]} emptyMessage="">
            {laborByRate().map((item) => (
              <TableRow key={item["Labor Rate"]}>
                <TableCell>{item["Labor Rate"]}</TableCell>
                <TableCell>{item["Labor Hours"]}</TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        </Card>
      </Box>
    </Box>
  );
}
