import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { ErrorDetail } from "../../../common/components/ErrorDetail";
import { Error } from "../../../../types/Error";
import { Map } from "modules/project-stepper/icons";
import { GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { SatelliteStatus, SiteData } from "models/siteData";
import { useFetchSites } from "modules/hooks/useFetchSites";
import {
  DataTable,
  createColumn,
  DEFAULT_COLUMN_WIDTH,
} from "modules/common/components/table/DataTable";

const BLURB =
  "Select sites to request a Satellite Image Estimate that determines RTU fan motor size, unit tonnage and building schedules. It may take up to 48hrs to process the satellite data. While a Satellite Estimate is PENDING for a project, the site and equipment details will not be editable until all estimates are COMPLETE.";

export type SatelliteEstimateRequestProps = {
  sites: SiteData[];
};

type RequestSatelliteEstimateProps = {
  projectId: string;
  requestSatelliteEstimate: (
    estimateProps: SatelliteEstimateRequestProps,
    closeModal: () => void,
    setModalErrors: (arg: any) => void
  ) => void;
  onCancel: () => void;
};

export function RequestSatelliteEstimateModal({
  projectId,
  requestSatelliteEstimate,
  onCancel,
}: RequestSatelliteEstimateProps) {
  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState<Error[]>([]);
  const handleClose = () => {
    setOpen(false);
  };
  const { loading, allSites } = useFetchSites(projectId);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const onSave = (selectedSites: SiteData[]) => {
    setButtonsDisabled(true);
    requestSatelliteEstimate(
      { sites: selectedSites },
      handleClose,
      (errors) => {
        setErrors(
          errors.message
            ? [
                {
                  source: "",
                  title: errors.message,
                  detail: errors.stack || "",
                  row: "",
                },
              ]
            : errors
        );
        setButtonsDisabled(false);
      }
    );
  };

  const columns: GridColDef[] = [
    createColumn("name", "Site Name", "string", DEFAULT_COLUMN_WIDTH),
    createColumn("identifier", "Site ID", "string", DEFAULT_COLUMN_WIDTH),
    createColumn("streetAddr", "Street Address", "string", 180),
    createColumn("buildingType", "Building Type", "string", 200),
    createColumn("satelliteStatus", "Satellite Estimate Status", "string", 200),
    createColumn("utilityRate", "Utility Rate", "number", 120),
    createColumn("co2eGPerKwh", "CO2e Intensity", "number", 120),
  ];

  const rows = allSites;
  const [hasSetDefaultSelection, setDefaultSelection] = useState(false);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  if (allSites?.length > 0 && !loading && !hasSetDefaultSelection) {
    setDefaultSelection(true);
    const siteIds = allSites
      .filter((site) => site.satelliteStatus === SatelliteStatus.NOT_REQUESTED)
      .map((site) => site.identifier);

    setSelectionModel(siteIds);
  }
  const handleCancel = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <div>
      <Dialog
        onClose={handleCancel}
        open={open}
        fullWidth
        maxWidth="xl"
        scroll="paper"
      >
        <DialogTitle style={{}}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            justifyContent="center"
            textAlign="center"
            mb={2}
            sx={{ fontFamily: "Barlow" }}
          >
            <Map style={{ marginRight: 24, height: "42px" }} />
            Request Satellite Estimate
          </Box>
          <Typography
            color="textPrimary"
            variant="body2"
            textAlign={"left"}
            fontFamily="Barlow"
          >
            {BLURB}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: 422,
            "&.MuiDialogContent-root": {
              boxShadow: loading ? 0 : 4,
              ml: 4,
              mr: 4,
              pl: 0,
              pr: 0,
              pb: 0,
            },
          }}
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <DataTable
                rows={rows}
                columns={columns}
                checkboxSelection
                disableColumnMenu
                autoHeight
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(
                    newSelectionModel.filter((id) =>
                      rows.find(
                        (site) =>
                          site.identifier === id &&
                          (site.satelliteStatus ===
                            SatelliteStatus.NOT_REQUESTED ||
                            site.satelliteStatus ===
                              SatelliteStatus.INVALID_ADDRESS)
                      )
                    )
                  );
                }}
                selectionModel={selectionModel}
              />
            </Box>
          )}
        </DialogContent>

        <Box mt={0} mb={3}>
          {/* Note: Your organization has 100 site credits remaining for Satellite Image Estimates. This request will use 4 credits. */}
          {errors?.length > 0 ? (
            <div>
              <ErrorDetail
                errors={errors}
                title="Request Satellite Estimate Error"
              />
            </div>
          ) : (
            <Typography
              color="textPrimary"
              variant="body2"
              fontFamily="Barlow"
              fontWeight={"fontWeightBold"}
              ml={4}
              mr={3}
              mt={2}
              mb={2}
            >
              Please confirm that you would like to proceed with the selected
              sites?
            </Typography>
          )}

          <Box display="flex" justifyContent="center">
            <Button
              style={{ margin: "24px" }}
              variant="outlined"
              onClick={handleCancel}
              disabled={buttonsDisabled}
            >
              Cancel
            </Button>
            <Button
              style={{ margin: "24px" }}
              variant="contained"
              disabled={selectionModel.length < 1 || buttonsDisabled}
              onClick={() =>
                onSave(
                  rows.filter(
                    (site) =>
                      site.satelliteStatus !== SatelliteStatus.PENDING &&
                      selectionModel.includes(site.identifier)
                  )
                )
              }
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
