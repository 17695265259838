import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  GridCellValue,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Project } from "models/project";
import {
  createColumn,
  DataTable,
  DEFAULT_COLUMN_WIDTH,
  ValueFormatter,
  renderStringWithTooltip,
} from "modules/common/components/table/DataTable";
import { useFetchSiteSummary } from "modules/hooks/useFetchSiteSummary";
import React, { useState } from "react";
import { DetailedSummary } from "types/DetailedSummary";
import { EquipmentDataRow } from "types/EquipmentDataRow";
import { formatNumber } from "utils/helpers";
import { DialogTitle } from "../../../common/components/dialog/DialogTitle";
import { SummaryCards } from "../SummaryCards";
import { SiteReportButton } from "../SiteReportButton";

const formatDetail = (value?: number | string, maxFractionDigits?: number) => {
  if (typeof value === "number") {
    return formatNumber(value, maxFractionDigits);
  }
  return value;
};

type Detail = {
  key: string;
  value?: number | string;
  maxFractionDigits?: number;
};

type DetailsSectionProps = {
  title: string;
  details: Detail[];
};

export const DetailsSection = ({ title, details }: DetailsSectionProps) => {
  return (
    <Box mt={2}>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>
      <List dense={true}>
        {details.map((detail) => (
          <ListItem disableGutters key={detail.key}>
            <Typography
              variant="body2"
              style={{
                color: "secondary",
                lineHeight: 1,
              }}
            >
              {`${detail.key}:`}&nbsp;
            </Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {formatDetail(detail.value, detail.maxFractionDigits)}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

type SiteReportProps = {
  project: Project;
  siteSummary: DetailedSummary;
  onClose: () => void;
};

export function SiteReportModal({
  project,
  siteSummary,
  onClose,
}: SiteReportProps) {
  const [open, setOpen] = useState(true);
  const { loading, site } = useFetchSiteSummary(siteSummary.site_id);

  const formatMoney: ValueFormatter = (
    params: GridValueFormatterParams
  ): GridCellValue => {
    return project.formatMoney(Number(params.value));
  };

  const columns: GridColDef[] = [
    createColumn(
      "equipmentUnitName",
      "RTU",
      "string",
      DEFAULT_COLUMN_WIDTH,
      undefined,
      renderStringWithTooltip
    ),
    createColumn("motorPower", "Motor power", "number", 110),
    createColumn("equipmentTonnage", "Tonnage", "number", DEFAULT_COLUMN_WIDTH),
    createColumn("ttAnnualOperatingHours", "Annual Runhours", "number", 130),
    createColumn("energySavings", "Energy Savings (kWh)", "number", 110),
    createColumn(
      "peakKwReduction",
      "Peak Demand Reduction (kW)",
      "number",
      150
    ),
    createColumn(
      "demandChargeReduction",
      "Peak Demand Savings",
      "number",
      110,
      formatMoney
    ),
    createColumn(
      "energyCostSavings",
      "Energy Cost Savings",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn(
      "priceAfterRebates",
      "Price After Rebates",
      "number",
      DEFAULT_COLUMN_WIDTH,
      formatMoney
    ),
    createColumn(
      "simplePayback",
      "Simple Payback (Years)",
      "number",
      DEFAULT_COLUMN_WIDTH
    ),
  ];

  const rows: EquipmentDataRow[] = site ? site.motorSummaries : [];

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xl"
        scroll="paper"
        PaperProps={{
          style: {
            backgroundColor: "#F1F1F2",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle onClose={handleClose}>
          <Grid
            item
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            textAlign="left"
            ml={3}
            mt={2}
            sx={{ fontFamily: "Barlow" }}
          >
            <Grid item xs={11}>
              {" "}
              {site ? (
                <div>
                  {site.name}
                  <br />
                  {site.address.replaceAll(",", ", ")}
                </div>
              ) : (
                `Loading ${siteSummary.identifier}...`
              )}
            </Grid>
            <Grid item xs={1}>
              <SiteReportButton
                project={project}
                site={site}
                disabled={!site}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            "&.MuiDialogContent-root": {
              boxShadow: "none",
            },
            marginLeft: 2,
          }}
        >
          <SummaryCards
            project={project}
            summary={siteSummary}
            showIcons={false}
            headerVariant="caption"
            valueVariant="h5"
          />
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Grid container spacing={2} mt={1} justifyContent="flex-end">
              <Grid item xs={3.5}>
                <DetailsSection
                  title="Retrofit Summary"
                  details={[
                    {
                      key: "Motor Quantity",
                      value: site?.motorSummaries?.length,
                    },
                    {
                      key: "Baseline Energy Usage (kWh)",
                      value: site?.baseEnergyUsage,
                    },
                    {
                      key: "Turntide Energy Usage (kWh)",
                      value: site?.turntideEnergyUsage,
                    },
                    {
                      key: "Energy Savings (kWh)",
                      value: site
                        ? site.baseEnergyUsage - site.turntideEnergyUsage
                        : 0,
                    },
                    {
                      key: "Peak Demand Reduction (kW)",
                      value: site?.peakKwReduction,
                    },
                    {
                      key: "Total Labor Hours",
                      value: site?.totalLaborHours,
                      maxFractionDigits: 1,
                    },
                  ]}
                />
                <DetailsSection
                  title="Location Summary"
                  details={[
                    {
                      key: "Lat",
                      value: site?.latitude,
                      maxFractionDigits: 7,
                    },
                    {
                      key: "Long",
                      value: site?.longitude,
                      maxFractionDigits: 7,
                    },
                    {
                      key: "Rooftop Area (SQFT)",
                      value: site?.rooftopSqft,
                    },
                    {
                      key: "Climate Zone",
                      value: site?.tmyClimateZone || "",
                    },
                    {
                      key: "Utility Rate ($/kWh)",
                      value: site?.utilityRate,
                      maxFractionDigits: 3,
                    },
                    {
                      key: "Peak Demand Charge ($/kW)",
                      value: site?.peakDemandCharge,
                      maxFractionDigits: 2,
                    },
                    {
                      key: `Grid Carbon Intensity (CO${String.fromCodePoint(
                        0x2082
                      )}e g/kWh)`,
                      value: site?.co2eGPerKwh,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={8.5} textAlign="right">
                {site?.rooftopImageUrl && (
                  <img
                    src={site?.rooftopImageUrl}
                    alt="Annotated Aerial Imagery"
                    style={{ width: "100%" }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }} mt={0}>
                  {rows && (
                    <DataTable
                      rows={rows}
                      columns={columns}
                      headerHeight={80}
                      autoHeight
                      disableColumnMenu
                      columnBuffer={10}
                      initialState={{
                        sorting: {
                          sortModel: [
                            { field: "equipmentUnitName", sort: "asc" },
                          ],
                        },
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
