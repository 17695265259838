import * as React from "react";
import { IconButton } from "@mui/material";

type PDFButtonProps = {
  url: string | null;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const PDFButton = ({ url, disabled, onClick }: PDFButtonProps) => (
  <IconButton disabled={disabled} onClick={onClick}>
    <a href={url || undefined} target="_blank" rel="noopener noreferrer">
      <i
        className="fas fa-file-pdf fa-lg"
        style={{
          color: disabled ? "grey" : "red",
          paddingTop: "4px",
        }}
      ></i>
    </a>
  </IconButton>
);
