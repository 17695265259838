import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const StyledTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      /* Chrome, Safari, Edge, Opera */
      "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
      /* Firefox */
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  })
)(TextField);
