import { AssetRebateRow } from "types/AssetRebateRow";

export class AssetRebate {
  id = "";
  siteName = "";
  siteIdentifier = "";
  quantity = 0;
  tonnage = 0;
  perTonRebate = 0;
  perUnitRebate = 0;
  miscRebate = 0;
  percentOfMaxPrice = 0;

  setAssetRebate = (rebate: AssetRebateRow) => {
    this.id = rebate.id;
    this.siteName = rebate.site_name;
    this.siteIdentifier = rebate.site_identifier;
    this.quantity = rebate.quantity;
    this.tonnage = rebate.tonnage;
    this.perTonRebate = rebate.per_ton_rebate;
    this.perUnitRebate = rebate.per_unit_rebate;
    this.miscRebate = rebate.misc_rebate;
    this.percentOfMaxPrice = rebate.percent_of_max_price;
  };

  totalRebate = () => {
    return (
      this.perTonRebate * this.tonnage +
      this.perUnitRebate * this.quantity +
      this.miscRebate
    );
  };
}
