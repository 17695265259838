import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Project } from "models/project";
import { formatNumber, keysToSnake } from "utils/helpers";
import { MotorSummary, SiteSummary } from "models/siteSummary";
import {
  kpiLabelSize,
  styles,
  TableHeaderProps,
  LogoHeader,
  TableRowLine,
  MOTOR_QTY_COLUMN_WIDTH,
  detailValueSize,
  SiteKpiSection,
} from "./PDFReportUtil";

export type SiteReportProps = {
  project: Project;
  site?: SiteSummary;
};

const siteStyles = StyleSheet.create({
  inputSection: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  inputRow: {
    marginTop: 3,
  },
  inputValue: {
    fontSize: kpiLabelSize,
    fontWeight: 700,
    color: "#000000",
  },
});

const TableHeader = ({ siteIdStyle }: TableHeaderProps) => (
  <View style={styles.detailedSummaryHeaderRow} debug={false}>
    <Text style={siteIdStyle} debug={false}>
      RTU
    </Text>
    <Text style={styles.motorQuantityHeaderCell}>MOTOR POWER</Text>
    <Text style={styles.detailedSummaryHeaderCell}>TONNAGE</Text>
    <Text style={styles.detailedSummaryHeaderCell}>ANNUAL RUNHOURS</Text>
    <Text style={styles.detailedSummaryHeaderCell}>ENERGY SAVINGS (kWh)</Text>
    <Text style={styles.detailedSummaryHeaderCell}>
      PEAK DEMAND REDUCTION (kW)
    </Text>
    <Text style={styles.detailedSummaryHeaderCell}>PEAK DEMAND SAVINGS</Text>
    <Text style={styles.detailedSummaryHeaderCell}>ENERGY COST SAVINGS</Text>
    <Text style={styles.detailedSummaryHeaderCell}>PRICE AFTER REBATES</Text>
    <Text style={styles.detailedSummaryHeaderCell}>SIMPLE PAYBACK (YEARS)</Text>
  </View>
);

// Create Document Component
export function SitePDF({ project, site }: SiteReportProps) {
  if (!site) {
    return <Document />;
  }

  return (
    <Document
      title={site.name}
      author={project.owner}
      subject={`ROI Calculator Motor Retrofit Summary for ${site.name}`}
      creator={project.organizationId}
    >
      <Page size="LETTER" style={styles.page}>
        <LogoHeader project={project} />

        <Text
          style={styles.title}
        >{`${site.name} Motor Retrofit Summary`}</Text>
        <Text
          style={[styles.subtitle, { textAlign: "center" }]}
        >{`${site.address.replaceAll(",", ", ")}`}</Text>
        <SiteKpiSection project={project} summary={{ ...keysToSnake(site) }} />

        <View style={[{ flexDirection: "row" }]}>
          <View
            style={[
              {
                flexDirection: site?.rooftopImageUrl ? "column" : "row",
                marginLeft: 65,
              },
            ]}
          >
            <View style={[siteStyles.inputSection]}>
              <Text style={styles.subtitle}>RETROFIT SUMMARY</Text>
              <View style={[styles.table, { marginLeft: 5 }]}>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Motor Quantity: </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.motorQuantity)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>
                    {"Baseline Energy Usage (kWh): "}
                  </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.baseEnergyUsage)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>
                    {"Turntide Energy Usage (kWh): "}
                  </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.turntideEnergyUsage)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Energy Savings (kWh): </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(
                      site.baseEnergyUsage - site.turntideEnergyUsage
                    )}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>
                    {"Peak Demand Reduction (kW): "}
                  </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.peakKwReduction, 2)}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[
                styles.table,
                siteStyles.inputSection,
                { marginLeft: site?.rooftopImageUrl ? 20 : 45 },
              ]}
            >
              <Text style={styles.subtitle}>LOCATION SUMMARY</Text>
              <View style={[styles.table, { marginLeft: 5 }]}>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Lat: </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.latitude)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Lng: </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.longitude)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Rooftop Area (SQFT): </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.rooftopSqft || 0)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Climate Zone: </Text>
                  <Text style={siteStyles.inputValue}>
                    {site.tmyClimateZone || ""}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>Utility Rate ($/kWh): </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.utilityRate || 0)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>
                    Peak Demand Charge ($/kW):{" "}
                  </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.peakDemandCharge || 0)}
                  </Text>
                </View>
                <View style={[styles.tr, siteStyles.inputRow]}>
                  <Text style={styles.kpiLabel}>
                    {`Grid Carbon Intensity (CO2e g/kWh): `}
                  </Text>
                  <Text style={siteStyles.inputValue}>
                    {formatNumber(site.co2eGPerKwh || 0)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {site?.rooftopImageUrl && (
            <Image
              src={site.rooftopImageUrl}
              style={{
                marginLeft: 40,
                marginRight: 40,
                width: 260,
              }}
            />
          )}
        </View>

        <View style={styles.detailedSummarySection}>
          <Text style={styles.subtitle}>MOTOR SUMMARY</Text>
          <View style={[styles.table, styles.detailedSummaryTable]}>
            <TableHeader siteIdStyle={styles.siteIdHeaderCell} />
            <View
              style={[styles.table, styles.detailedSummaryTable]}
              debug={false}
              fixed
              render={({ pageNumber }) =>
                pageNumber > 1 && (
                  <TableHeader siteIdStyle={styles.siteIdHeaderCellFixed} />
                )
              }
            />
            {site.motorSummaries.map((motor) => {
              // automatic camel vs snake conversion in the model confuses typescript here
              // TODO: somehow explicitly type-convert MotorSummary in the model
              const row = motor as unknown as MotorSummary;
              return (
                <View
                  key={row.id}
                  wrap={false}
                  style={[
                    styles.tr,
                    styles.detailedSummaryRow,
                    { fontSize: detailValueSize },
                    { flexDirection: "column" },
                  ]}
                >
                  <TableRowLine />
                  <View style={[{ flexDirection: "row" }]}>
                    <Text style={[styles.td, styles.siteIdHeaderCell]}>
                      {row.equipmentUnitName}
                    </Text>
                    <Text
                      style={[
                        styles.td,
                        styles.detailedSummaryCell,
                        { flexGrow: MOTOR_QTY_COLUMN_WIDTH },
                      ]}
                    >
                      {formatNumber(row.motorPower)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {formatNumber(row.equipmentTonnage)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {formatNumber(row.ttAnnualOperatingHours)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {formatNumber(row.energySavings)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {formatNumber(row.peakKwReduction)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {project.formatMoney(row.demandChargeReduction)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {project.formatMoney(row.energyCostSavings)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {project.formatMoney(row.priceAfterRebates)}
                    </Text>
                    <Text style={[styles.td, styles.detailedSummaryCell]}>
                      {formatNumber(row.simplePayback)} years
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
}
