import React, { useState } from "react";
import { Box, Button, Card, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { ProjectProps } from "types/ProjectProps";
import { CustomColumnMenuComponent } from "modules/common/components/table/CustomColumnMenuComponent";
import { tableStyles } from "modules/common/components/table/tableStyles";
import { AddUserDialog } from "./AddUserDialog";
import { postRequest } from "utils/helpers";
import { useAuth } from "modules/common/auth";
import { useSnackbar } from "notistack";
import { useFetchMemberships } from "modules/hooks/useFetchMemberships";
import { Membership } from "types/Membership";
import { TableHeader } from "modules/common/components/table/TableHeader";
import { Organization } from "types/Organization";
import { uniqBy } from "lodash";
export function Members() {
  const [displayAddUserDialog, setDisplayAddUserDialog] = useState(false);
  const classes = tableStyles();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingMemberships, membershipsData } = useFetchMemberships();
  const membershipsList =
    membershipsData && !loadingMemberships ? membershipsData.memberships : [];

  const currentUserOrganizations = () => {
    if (!membershipsData) {
      return [];
    }
    // If current user is super admin, return all organizations. Otherwise return
    // organizations in which user is an admin.
    const res = membershipsData.is_super_admin
      ? membershipsData.memberships
      : membershipsData.current_user_memberships;
    return uniqBy(
      res.map((m: Membership) => {
        return { id: m.org_id, name: m.org_name } as Organization;
      }),
      "id"
    ).sort((a, b) => (a.name.localeCompare(b.name)));
  };
  const [memberships,] = useState<Membership[]>([]);

  const columns = [
    {
      field: "email",
      width: 700,
      renderHeader: () => <TableHeader headerName="User" />,
      headerAlign: "left" as "left",
      headerName: "Name",
    },
    {
      field: "org_name",
      width: 450,
      renderHeader: () => <TableHeader headerName="Organization" />,
      headerAlign: "left" as "left",
      headerName: "Organization",
    },
    {
      field: "role",
      width: 350,
      renderHeader: () => <TableHeader headerName="Role" />,
      headerAlign: "left" as "left",
      headerName: "Role",
    }
  ];
  const addUser = (email: string, organizationId: string) => {
    const body = {
      email: email,
      organization_id: organizationId,
    };
    postRequest<{ data: { project: ProjectProps } }>(
      "POST",
      `${window.APP_CONFIG.API_HOST}/invites`,
      auth,
      JSON.stringify(body)
    )
      .then(({ data }) => {
        enqueueSnackbar(`Invite sent to ${email}.`, {
          variant: "success",
        });
        setDisplayAddUserDialog(false);
      })
      .catch((error) => {
        enqueueSnackbar(`User invite failed: ${error}`, {
          variant: "error",
        });
      });
  };
  return (
    <Box mt={5} pl={8} pr={8} className={`projects ${classes.table}`}>
      <Box display="flex" pb={4}>
        <Box p={1} flexGrow={1}>
          <Typography variant="h6">Users</Typography>
        </Box>
        <Box>
          <Button
            disableRipple={true}
            color="primary"
            variant="contained"
            onClick={() => setDisplayAddUserDialog(true)}
          >
            Add User
          </Button>
        </Box>
      </Box>
      <Box>
        <Card>
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={memberships.length > 0 ? memberships : membershipsList}
              columns={columns}
              pageSize={50}
              sortingOrder={["asc", "desc"]}
              components={{
                ColumnMenu: CustomColumnMenuComponent,
              }}
            />
          </div>
        </Card>
      </Box>
      {displayAddUserDialog && (
        <AddUserDialog
          open={displayAddUserDialog}
          onClose={() => setDisplayAddUserDialog(false)}
          addUser={addUser}
          organizations={currentUserOrganizations()}
        />
      )}
    </Box>
  );
}
