import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    fontFamily: [
      "Barlow",
      "Roboto",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiRadio: {
      root: {
        color: "#006BA6",
      },
      colorSecondary: {
        "&$checked": {
          color: "#006BA6",
        },
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "transparent",
        color: "black",
      },
      root: {
        margin: "auto",
      },
    },
    MuiTab: {
      root: {
        border: "solid 1px black",
        color: "#006BA6",
        "&:hover": {
          backgroundColor: "#6B6C6F",
          color: "#F5F5F5",
        },
        "&.Mui-selected": {
          backgroundColor: "#6B6C6F",
          color: "#F5F5F5",
          "&:hover": {
            backgroundColor: "#6B6C6F",
            color: "#F5F5F5",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#006BA6",
    },
  },
});
