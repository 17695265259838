import React, { useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { AccessLevel } from "./ShareProjectDialog";
import { Theme } from "@material-ui/core/styles";

type DetailProps = {
  user: string;
  onPrevClick: () => void;
  onShare: (permissionLevel: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
      color: "white",
    },
    container: {
      marginTop: theme.spacing(5),
    },
  })
);

export function ShareDetails({ user, onPrevClick, onShare }: DetailProps) {
  const classes = useStyles();
  const [accessLevel, setAccessLevel] = useState(AccessLevel.READ_ONLY);
  return (
    <Box mb={3} ml={1} mr={1}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography color="textSecondary" variant="overline">
            User
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="textSecondary" variant="overline">
            Access Level
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{user}</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAccessLevel(event.target.value as AccessLevel)
            }
            value={accessLevel}
            InputProps={{ disableUnderline: true }}
          >
            {Object.values(AccessLevel).map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          className={classes.container}
        >
          <Button variant="outlined" onClick={onPrevClick}>
            Prev
          </Button>
          <Button
            onClick={() => onShare(accessLevel)}
            classes={{
              root: classes.button,
            }}
            variant="contained"
          >
            Share
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
