import React, { ReactNode } from "react";
import { Card, Stack, Tooltip } from "@mui/material";
import {
  GridCellValue,
  GridColDef,
  GridValueFormatterParams,
  DataGrid,
  GridRowClassNameParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { TableHeader } from "modules/common/components/table/TableHeader";
import { tableStyles5 } from "modules/common/components/table/tableStyles5";
import { useTheme } from "@mui/material/styles";

export const DEFAULT_COLUMN_WIDTH = 125;
const SORT_BUTTON_WIDTH = 55;
const HEADER_ALIGN = "left";

export type ValueFormatter = (
  params: GridValueFormatterParams
) => GridCellValue;

export const renderStringWithGivenTooltip = (
  params: GridRenderCellParams,
  tooltip: string
): ReactNode => {
  return (
    <Tooltip title={tooltip}>
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        className="MuiDataGrid-cellContent"
      >
        {params.formattedValue}
      </span>
    </Tooltip>
  );
};

export const renderStringWithTooltip = (
  params: GridRenderCellParams
): ReactNode => {
  const value = params.row[params.field] || "";
  return renderStringWithGivenTooltip(params, value);
};

export const renderClickToEditTooltip = (
  params: GridRenderCellParams
): React.ReactNode => {
  return renderStringWithGivenTooltip(params, "Click to Edit");
};

// Render a number with a % suffix.
// Expects a percent (like 60 for 60%) rather than a fraction (whereas Excel would expect 0.6 for 60%)
export const percentValueFormatter = (
  params: GridValueFormatterParams<number>
) => {
  if (params.value == null) {
    return "";
  }

  const valueFormatted = Number(params.value).toLocaleString();
  return `${valueFormatted} %`;
};

export const createColumn = (
  field: string,
  headerName: string,
  type: string,
  width = DEFAULT_COLUMN_WIDTH,
  valueFormatter?: ValueFormatter,
  renderCell?: (params: GridRenderCellParams) => ReactNode
): GridColDef => {
  const colDef: GridColDef = {
    field,
    headerName,
    type,
    headerAlign: HEADER_ALIGN,
    renderHeader: () => (
      <TableHeader
        headerName={headerName}
        maxWidth={`${width - SORT_BUTTON_WIDTH}px`} // Avoid re-flow when the sort arrow icon becomes visible
      />
    ),
    width,
  };
  if (valueFormatter) {
    colDef.valueFormatter = valueFormatter;
  }
  if (renderCell) {
    colDef.renderCell = renderCell;
  }
  return colDef;
};

export const DataTable = (props: React.ComponentProps<typeof DataGrid>) => {
  const theme = useTheme();
  const classes = tableStyles5(theme);
  return (
    <Card sx={classes.table}>
      <DataGrid
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              mt={"96px"}
            >
              No rows.
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returned no result.
            </Stack>
          ),
        }}
        getRowId={(row) => row.identifier || row.id}
        getRowClassName={(params: GridRowClassNameParams) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        sx={{
          fontFamily: "Barlow",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            mr: 0,
            pr: 0,
            textOverflow: "clip",
            lineHeight: 1,
            whiteSpace: "normal",
          },
          ".MuiDataGrid-iconButtonContainer": {
            ml: 0,
            pl: 0,
          },
          ".MuiBox-root": {
            mr: 0,
            pr: 0,
          },
          cursor: props.onCellClick ? "pointer" : "default",
        }}
        {...props}
      />
    </Card>
  );
};

export default DataTable;
