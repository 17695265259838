import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import * as levenshtein from "damerau-levenshtein";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import {
  SearchResultAddress,
  DEFAULT_SEARCH_RESULT_ADDRESS,
} from "types/SearchResultAddress";
import { useSearchAddress } from "modules/hooks/useSearchAddress";

const DEBOUNCE_TIME = 300;

interface LevenshteinResponse {
  steps: number;
  relative: number;
  similarity: number;
}

type AddressAutocompleteProps = {
  address: SearchResultAddress;
  setAddress: (addressResult: SearchResultAddress) => void;
};

export function AddressAutocomplete({
  address,
  setAddress,
}: AddressAutocompleteProps) {
  const [debouncedSiteAddress, setDebouncedSiteAddress] = useState("");
  const setSiteAddressLine = useCallback(
    debounce(
      (siteAddress) => setDebouncedSiteAddress(siteAddress),
      DEBOUNCE_TIME,
      { leading: false, trailing: true }
    ),
    []
  );
  const { addresses, setAddresses } = useSearchAddress(
    debouncedSiteAddress,
    address
  );

  return (
    <Autocomplete
      autoComplete
      className="input"
      filterOptions={(x: any) => x}
      filterSelectedOptions
      fullWidth
      getOptionLabel={(option: SearchResultAddress) =>
        typeof option === "string" ? option : option.freeformAddress || ""
      }
      id="address-search-autocomplete"
      includeInputInList
      isOptionEqualToValue={(
        option: SearchResultAddress,
        value: SearchResultAddress
      ) => {
        return option.freeformAddress === value.freeformAddress;
      }}
      options={addresses}
      value={address}
      onChange={(_event: any, newValue: SearchResultAddress | null) => {
        return setAddress(newValue || DEFAULT_SEARCH_RESULT_ADDRESS);
      }}
      onInputChange={(event: any, newInputValue: string, reason: string) => {
        if (reason === "input") {
          setSiteAddressLine(newInputValue);
        } else {
          // When tabbing out or otherwise losing focus,
          // if the user hasn't picked an address yet,
          // capture recent typing
          const inputAddress: string =
            event?.target.defaultValue || newInputValue || "";
          const inputTest = inputAddress.toLocaleLowerCase();

          // Try to find a matching API-returned result.
          // Use Levenshtein distance to match approximations
          // e.g typing "1 main st." should pick up the first returned real address "1 Main Street, Kennebunk, ME 04043"
          const firstMatch = addresses.find((a) => {
            if (a.freeformAddress && a.isApiResult) {
              const matchTest = a.freeformAddress
                .toLocaleLowerCase()
                .slice(0, inputTest.length);
              const lev: LevenshteinResponse = levenshtein.default(
                inputTest,
                matchTest
              );
              return lev.similarity > 0.5;
            }
            return false;
          });
          if (firstMatch) {
            setAddress(firstMatch);
          } else {
            const freeAddress = {
              freeformAddress: inputAddress,
              isApiResult: false,
            };
            setAddresses([freeAddress, ...addresses]);
            setAddress(freeAddress);
          }
        }
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          required
          type="text"
          variant="outlined"
          className="input"
          label="Required"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option.freeformAddress}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
