import React from "react";
import { getResource } from "utils/helpers";
import { useSnackbar } from "notistack";
import { useAuth } from "modules/common/auth";
import { MotorRebateRow } from "types/MotorRebateRow";
import { MotorRebate } from "models/motorRebate";

export const useFetchMotorRebates = (projectId: string | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [loadingMotorRebates, setLoading] = React.useState(true);
  const [mRebates, setMotorRebates] = React.useState<MotorRebate[]>([]);
  React.useEffect(() => {
    const getMotorRebates = async () => {
      setLoading(true);
      getResource<{ data: MotorRebateRow[] }>(
        `${window.APP_CONFIG.API_HOST}/motor_rebates?project_id=${projectId}`,
        auth
      )
        .then(({ data }) => {
          setLoading(false);
          const rebates = data.map((rebate: MotorRebateRow) => {
            const r = new MotorRebate();
            r.setMotorRebate(rebate);
            return r;
          });
          setMotorRebates(rebates);
        })
        .catch((error) => {
          enqueueSnackbar(
            `There was an error retrieving motor rebates. ${error}`,
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    };
    getMotorRebates();
  }, [enqueueSnackbar, projectId, auth]);
  return {
    loadingMotorRebates,
    mRebates,
  };
};
